import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import RegularButton from "components/CustomButtons/RegularButton.js";
import adminActions from "../../services/admin";

import CustomInput from "components/CustomInput/CustomInput2.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import People from "@material-ui/icons/People";
import {SHOW_APP_ALERT} from "../../action-types";

export default function CreateAdminUser(props) {
  const dispatch = useDispatch();
  const classes = useState([]);
  // eslint-disable-next-line react/prop-types

  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordLabelText, setPasswordLabelText] = useState("Temporary Password");
  
  var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

  const validateAndSetPassword = (e) =>{
    if (strongRegex.test(e.target.value)){
      setPasswordLabelText('Temporary Password')
      setPasswordError(false);
      setPassword(e.target.value);
    }
    else{
      setPasswordError(true);
      setPasswordLabelText('Passwords must contain 8-16 characters, 1 special character, 1 number, 1 uppercase and 1 lowercase letter.')
    }

  }

  const submit = async () => {

    if (passwordError === false){
      adminActions
      .createUser({
        email: email,
        display_name: displayName,
        username: userName,
        password: password
      })
      .then(result => {
        let status = result.status;
        if (status===200){
          let config = {
            type: 'success',
            title: "Success",
            message: "User Create Success",
            onSuccess: () => {props.history.push({pathname: "admin-users-list",params: ''})}
          };
          dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        }
        return result.data.resp.result;
      })
      .catch(err => {
        console.error(err.response);
        let config = {
          type: 'error',
          title: "Error",
          message: err.response.data.err
        };
        dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
      });
    }
    else{
      let config = {
        type: 'error',
        title: "Error",
        message: "Please enter valid password."
      };
      dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
    }
  };

  useEffect(() => {
    // Update the document title using the browser API
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <h4 className={classes.cardTitle}>User Details</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={6} sm={6} md={4}>
                <CustomInput
                    labelText="Username"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                            <People />
                          </InputAdornment>
                      ),
                      floatingLabelFixed: true,
                      value: userName
                    }}
                    onChange={event => setUserName(event.target.value)}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={4}>
                <CustomInput
                    labelText="Email"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      floatingLabelFixed: true,
                      value: email
                    }}
                    onChange={event => setEmail(event.target.value)}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={4}>
                <CustomInput
                    labelText="Display Name"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      floatingLabelFixed: true,
                      value: displayName
                    }}
                    onChange={event => setDisplayName(event.target.value)}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={4}>
                <CustomInput
                    labelText={passwordLabelText}
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={passwordError}
                    inputProps={{
                      floatingLabelFixed: true
                    }}
                    onChange={e => validateAndSetPassword(e)}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{textAlign: "right"}}>
                <RegularButton
                    color="success"
                    onClick={() => {
                      submit();
                    }}
                >
                  Create
                </RegularButton>
                <RegularButton
                    onClick={() => {
                      props.history.goBack();
                    }}
                >Cancel</RegularButton>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
