import {combineReducers} from "redux";
import authReducer from "./auth";
import commonReducer from "./common";
import storeReducer from "./commitToStore";
import demoReducer from "./demp";

const appReducer =  combineReducers({
  auth: authReducer,
  common: commonReducer,
  store: storeReducer,
  demo: demoReducer

});

const rootReducer = (state, action) =>{
  if (action.type==='RESET_APP'){
    state= undefined;
  }
  return appReducer(state,action);
}

export default rootReducer;

