import React from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from '@material-ui/icons/Person';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ExitToApp from "@material-ui/icons/ExitToApp";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ListIcon from '@material-ui/icons/List';
import CreateIcon from '@material-ui/icons/Create';
import InfoIcon from '@material-ui/icons/Info';
import AdminUserList from "./views/AdminUser/AdminUserList";
import TemplateTableComponent from "./components/templateTable/templateTableComponent";
import TemplateOverviewComponent from "./components/templateOverview/templateOverviewComponent";
import TemplateComponent from "./components/TemplateComponent/templateComponent";
import FinalUploadShadesComponent from "./components/UploadShades/uploadShades";
import TutorialView from "./components/Tutorials/tutorialView";
import AdminUser from "./views/AdminUser/AdminUser";
import CreateAdminUser from "./views/AdminUser/CreateAdminUser";
import AppUserList from "./views/AppUser/AppUserList";
import AppUser from "./views/AppUser/AppUser";
import LoginPage from "./views/Auth/Login";
import Dashboard from "./views/Dashboard/Dashboard";
import PasswordReset from "./views/Auth/PasswordReset";
import TemplateForm from "./views/Templates/TemplateForm";
import TemplateList from "./views/Templates/TemplateList";
import {
  AttachMoney, GroupAdd,
  ImportantDevices,
  PhoneIphone,
  PhonelinkSetup, Print,
  Search,
  Sms,
  SystemUpdate,
  Storefront
} from "@material-ui/icons";
import AppUserManager from "./views/AppUser/AppUserManager";
import PlanForm from "./views/Plans/PlanForm";
import PlanList from "./views/Plans/PlanList";
import TemplateColumnData from "./views/Templates/TemplateColumnData";
import TemplateView from "./views/Templates/TemplateView";
import AppInfoComponent from "./views/SystemAppInfo/AppInfoComponent";
import AppIntegrations from "./views/SystemAppIntegrations/AppIntegrations";
import ChatGroupsForm from "./views/Chat/ChatGroupsForm";
import ChatGroupList from "./views/Chat/ChatGroupList";
import ChatGroupMembers from "./views/Chat/ChatGroupMembers";
import PrinterGrid from "./views/Printer/PrinterGrid";
import {ProductList} from "./views/Product/Cmp/ProductList";
import ProductForm from "./views/Product/Cmp/ProductForm";
import Product from "./views/Product";
import Info from "./views/Info";

const dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin"
  },
  {
    collapse: true,
    state: "string",
    rtlName: "Admin Users",
    icon: PersonIcon,
    name: "Admin Users",
    views: [
      {
        path: "/admin-users-list",
        name: "Users List",
        icon: PersonIcon,
        component: AdminUserList,
        layout: "/admin"
      },
      {
        path: "/edit/:id",
        name: "Edit User",
        icon: ViewAgendaIcon,
        component: AdminUser,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/view/admin-user",
        name: "Audit Trails",
        icon: ViewAgendaIcon,
        component: AdminUser,
        layout: "/admin"
      },
      {
        path: "/admin-create",
        name: "Create Admin User",
        icon: PersonAddIcon,
        component: CreateAdminUser,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    state: "appUserState",
    rtlName: "App users",
    icon: PhoneIphone,
    name: "App Users",
    views: [
      {
        path: "/app/users-search",
        name: "Search",
        icon: Search,
        component: AppUserManager,
        layout: "/admin"
      },
      {
        path: "/app/users-list",
        name: "User List",
        icon: ListIcon,
        component: AppUserList,
        layout: "/admin"
      }
    ]

  },
  {
    path: "/app/view/:id",
    name: "View Users",
    icon: SupervisorAccountIcon,
    component: AppUser,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/app/edit/:id",
    name: "View Users",
    icon: SupervisorAccountIcon,
    component: AppUser,
    layout: "/admin",
    hidden: true
  },
  {
    collapse: true,
    state: "templateState",
    rtlName: "Templates",
    icon: ViewModuleIcon,
    name: "Templates",
    views: [
      {
        path: "/template-form",
        name: "Create Template",
        icon: CreateIcon,
        component: TemplateForm,
        layout: "/admin"
      },
      {
        path: "/templates-list",
        name: "Template List",
        icon: ListIcon,
        component: TemplateList,
        layout: "/admin"
      },
      {
        path: "/create-template",
        name: "Create Template Old",
        icon: CreateIcon,
        component: TemplateComponent,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/list-templates",
        name: "Template List Old",
        icon: ListIcon,
        component: TemplateTableComponent,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/templates-upload",
        name: "Template File Uploader",
        component: TemplateColumnData,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/templates-view",
        name: "Template View",
        component: TemplateView,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/upload",
        icon: ViewModuleIcon,
        component: FinalUploadShadesComponent,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/edit-template",
        icon: ViewAgendaIcon,
        component: TemplateComponent,
        layout: "/admin",
        hidden: true
      }
    ]
  },
  {
    path: "/tutorial",
    name: "Tutorials",
    icon: HelpOutlineIcon,
    component: TutorialView,
    layout: "/admin"
  },
  {
    collapse: true,
    state: "planState",
    rtlName: "Plans",
    icon: AttachMoney,
    name: "Subscription Plans",
    views: [
      {
        path: "/plans-edit/:id",
        name: "Plan Update",
        icon: CreateIcon,
        component: PlanForm,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/plans-create",
        name: "Plan Create",
        icon: CreateIcon,
        component: PlanForm,
        layout: "/admin"
      },
      {
        path: "/plans-list",
        name: "Plan List",
        icon: ListIcon,
        component: PlanList,
        layout: "/admin"
      }
    ]
  },
  {
    path: "/product",
    name: "Products",
    icon: Storefront,
    component: Product,
    layout: "/admin",
    hidden: false
  },
  {
    path: "/info",
    name: "Info",
    icon: InfoIcon,
    component: Info,
    layout: "/admin",
    hidden: false
  },
  {
    collapse: true,
    state: "systemState",
    rtlName: "System",
    icon: SystemUpdate,
    name: "System",
    views: [
      {
        path: "/system-app-info",
        name: "App Info",
        icon: ImportantDevices,
        component: AppInfoComponent,
        layout: "/admin",
      },
      {
        path: "/system-app-printer",
        name: "Printers",
        icon: Print,
        component: PrinterGrid,
        layout: "/admin",
      },
      {
        path: "/system-app-integrations",
        name: "App Integration",
        icon: PhonelinkSetup,
        component: AppIntegrations,
        layout: "/admin",
      },
      {
        path: "/system-chat-integrations",
        name: "Chat",
        icon: Sms,
        component: ChatGroupList,
        layout: "/admin",
      },
      {
        path: "/chat-groups-form",
        name: "Create group",
        icon: GroupAdd,
        component: ChatGroupsForm,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/chat-groups-members/:group_id",
        name: "Edit group",
        icon: ListIcon,
        component: ChatGroupMembers,
        layout: "/admin",
        hidden: true
      }
    ]
  },
  {
    path: "/overview",
    name: "Overview",
    icon: DashboardIcon,
    component: TemplateOverviewComponent,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/login/",
    name: "Logout",
    icon: ExitToApp,
    component: LoginPage,
    layout: "/auth"
  },
  {
    path: "/passwordReset",
    name: "Password Reset",
    icon: LockOpenIcon,
    component: PasswordReset,
    layout: "/auth"
  },
];
export default dashRoutes;
