import React, { useEffect} from "react";
import GridItem from "components/Grid/GridItem.js";
import TextField from '@material-ui/core/TextField';
import './CalendarInput.css'

export default function CalendarInput(props) {
  const { startDateError, endDateError, updateStartDate, updateEndDate, rightInputMarginRight, startValue, endValue} = props;
 
  useEffect(() => {
    //Write to function to execute on page-load.
  }, []);

  return (
      <div className="calendarContainer">
        <GridItem xs={12} sm={4}>
              <TextField
              id="startDate"
              label={startDateError === true ? "Select Valid Start Date": "Start Date"}
              type="date"
              value={startValue}
              className="calendarInput"
              InputLabelProps={{
                shrink: true,
                error: startDateError
              }}
              onChange={e =>{
                updateStartDate(e.target.value);
              }}
            />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <TextField
                id="endDate"
                label={endDateError === true ? "Select Valid End Date": "End Date"}
                type="date"
                value={endValue}
                className="calendarInput"
                style={rightInputMarginRight? {marginRight:rightInputMarginRight}: null}
                InputLabelProps={{
                  shrink: true,
                  error: endDateError
                }}
                onChange={e =>{
                  updateEndDate(e.target.value);
            }}
            />
        </GridItem>
    </div>
  );
}
