import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { purple } from '@material-ui/core/colors';
import {
  withStyles,
  CircularProgress,
  Grid,
  DialogTitle
} from '@material-ui/core';

const styles = theme => ({
  progress: {
    color: purple[900],
    '&:hover': {
      color: purple[900]
    },
    padding: 10,
    marginBottom: 15
  },
  container: {
    textAlign: 'center',
    overflow: 'hidden'
  }
});

function Spinner(props) {
  const { classes, fetching} = props;


  return (
    <div>
      <Dialog
        open={fetching}
        aria-labelledby="spinner-dialog-title"
        aria-describedby="spinner-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <Grid className={classes.container}>
          <DialogTitle id="spinner-dialog-title"> Loading...</DialogTitle>
          <CircularProgress
            className={classes.progress}
            size={60}
            thickness={4.6}
          />
        </Grid>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  // return {
  //   fetching: state.api.fetching
  // };
};

export default connect(mapStateToProps)(withStyles(styles)(Spinner));
