import axios from "axios";
import {APP_URL} from "../config/settings";

let instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});
instance.interceptors.request.use(req => {
        req.headers['Authorization'] = localStorage.getItem('idToken');
        return req;
    }, error => {
        return Promise.reject(error);
    }
);
//on successful response
instance.interceptors.response.use(
    undefined, function axiosRetryInterceptor(err) {

        if (err.response && (err.response.status === 403 || err.response.status === 401)) {
            const url =  APP_URL + (window.location.port ? ":"+ window.location.port : '') + '/#/auth/login';
            console.log(url)
            window.location.replace(url);
            console.log(url)
        }
        else if(err.response && err.response.status === 400){
            return err.response
        }
        else {
            return err;
        }
    }
);
export default instance;
