import dsdInstance from "./dsdInstance";
import {API_URL} from "../config/api";

export default class ChatService {
    static createGroup = async data => {
        try {
            const res = await dsdInstance.post(API_URL.CHAT_GROUP_INTEGRATIONS, data);
            switch (res.status) {
                case 200:
                    return {status:  true}
                case 400:
                    return {status: false, errors: [res.data.err]}
                default:
                    return {status: false, errors: ["Something went wrong!"]}
            }
        }
        catch (e) {
            console.error(e);
            return {status: false, errors: ["Something went wrong!"]}
        }
    }
    static getAllGroups = async params => {
        try {
            const {type, limit, page} = params;
            const res = await dsdInstance.get(API_URL.CHAT_GROUP_INTEGRATIONS + "?type=" + type + "&limit=" + limit + "&page=" + page);
            return res.data.data.result;
        }
        catch (e) {
            console.error(e);
            return {status: false, errors: ["Something went wrong!"]}
        }
    }
    static getGroupMember = async id => {
        try {
            const res = await dsdInstance.get(API_URL.CHAT_GROUP_INTEGRATIONS + "/user/" + id);
            return res.data.data.result.user;
        }
        catch (e) {
            console.error(e);
            return {status: false, errors: ["Something went wrong!"]}
        }
    }
    static deleteMember = async (group_id, user_id) => {
        const url = API_URL.CHAT_GROUP_INTEGRATIONS +"/"+ group_id + "/user/" + user_id;
        try {
            const res = await dsdInstance.delete(url);
            return res.data.data.result;
        }
        catch (e) {
            console.error(e);
            return {status: false, errors: ["Something went wrong!"]}
        }
    }
    static addMember = async (group_id, params) => {
        const url = API_URL.CHAT_GROUP_INTEGRATIONS +"/"+ group_id + "/user/";
        try {
            const res = await dsdInstance.put(url, params);
            switch (res.status) {
                case 200:
                    return {status:  true, data: res.data.data.result}
                case 400:
                    return {status: false, errors: [res.data.err]}
                default:
                    return {status: false, errors: ["Something went wrong!"]}
            }
        }
        catch (e) {
            console.error(e);
            return {status: false, errors: ["Something went wrong!"]}
        }
    }
}