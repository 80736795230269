export default function useActionButtonProps() {
    return {
        addButtonProps: {
            color: "rose",
            round: true,
            style: {float: "right"}
        },
        changeButtonProps: {
            color: "rose",
            round: true,
            style: {float: "right"}

        },
        removeButtonProps: {
            color: "danger",
            round: true,
            style: {float: "right"}
        }
    }

}