import React, {useEffect, useState} from 'react';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputErrors from "../../../components/CustomInput/InputErrors";
import {useInputProps} from "../../../components/CustomInput/InputPropsHook";
import TagsInput from "react-tagsinput";
import ImageUploadV1 from "../../../components/CustomUpload/ImageUploadV1";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RegularButton from "../../../components/CustomButtons/RegularButton";
import commonStyle from "../../../common/styles/common";
import AppIntegrationService from "../../../services/AppIntegrationService";
import {SHOW_APP_ALERT} from "../../../action-types";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(() => ({
        ...commonStyle,
        tagLabel: {
            paddingLeft: "0px",
            fontSize: "12px",
            display: "block"
        },
        tagHr: {
            borderTop: "1.5px solid #d2d2d2;",
            marginTop: "0px",
            paddingBottom: "0px"
        },
    }
));
export default function IntegrationFom(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const name = useInputProps({"type": "text", rules: ["required"]})
    const url = useInputProps({"type": "text", rules: ["required"]})
    const [params, setTagsParams] = useState([]);
    const [newFile, setNewFile] = useState({});
    const [s3Url, setS3Url] = useState('');

    useEffect(() => {
        if (!props.open) {
            resetForm();
        }
    }, [props.open]);
    useEffect(() => {
        if (props.forEdit) {
            const {FileURL, Name, URL, Id, Params} = props.forEdit
            if (FileURL) {
                setS3Url(FileURL);
            }
            if (Name) {
                name.setValue(Name);
            }
            if (URL) {
                url.setValue(URL);
            }
            if (Params) {
                setTagsParams(Params);
            }

        }
    }, [props.forEdit])

    const handleTags = regularTags => {
        setTagsParams(regularTags);
    };
    const validForm = () => {

        if (!newFile && !s3Url) {
            return false;
        }
        if (name.props.value === "") {
            return false;
        }
        if (url.props.value === "") {
            return false
        }

        return true;
    }
    const submit = async () => {
        try {
            const param = {
                name: name.props.value,
                url: url.props.value,
                params: params
            }
            if (newFile) {
                param['file_name'] = newFile["name"];
            }
            let res;
            if (props.forEdit) {
                param["id"] = props.forEdit.Id;
                res = await AppIntegrationService.update(param);
            } else {
                res = await AppIntegrationService.create(param);
            }
            if (res.hasOwnProperty('URL')) {
                const s3Res = await s3Upload(res.URL);
                if (s3Res) {
                    let config = {
                        type: "success",
                        title: "Success",
                        message: "Integration configuration saved successfully",
                    };
                    dispatch({type: SHOW_APP_ALERT, alertConfig: config});
                    resetForm();
                    props.reFresh();
                }
            } else {
                let config = {
                    type: "success",
                    title: "Success",
                    message: "Integration configuration saved successfully",
                };
                dispatch({type: SHOW_APP_ALERT, alertConfig: config});
                resetForm();
                props.reFresh();
            }

        } catch (e) {
            console.error(e);
        }

    }
    const s3Upload = async s3Url => {
        const data = new FormData();
        data.append('file', newFile);
        return await fetch(s3Url, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': newFile.type,
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: newFile
        });
    }
    const resetForm = () => {
        name.setValue("");
        url.setValue("");
        setTagsParams([]);
        setNewFile(null);
        props.setOpenForm(false);
    }

    return <GridContainer>
        <GridItem md={12}>

            <ImageUploadV1
                reset={!props.open}
                imagePreviewUrl={s3Url}
                setNewFile={(file) => setNewFile(file)}
                addButtonProps={{
                    color: "rose",
                    round: true,
                    style: {float: "right"}
                }}
                changeButtonProps={{
                    color: "rose",
                    round: true,
                    style: {float: "right"}

                }}
                removeButtonProps={{
                    color: "danger",
                    round: true,
                    style: {float: "right"}
                }}
            />
        </GridItem>
        <GridItem md={12}>
            <label className={classes.tagLabel}>Params</label>
            <TagsInput
                value={params}
                onChange={handleTags}
                tagProps={{className: "react-tagsinput-tag info"}}
            />
            <hr className={classes.tagHr}/>
        </GridItem>
        <GridItem md={12}>
            <CustomInput
                error={!name.validity.isValid}
                labelText="Name...*"
                id="idVersion"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{...name.props}}
            />
            <InputErrors errors={name.validity.errors}/>

        </GridItem>
        <GridItem md={12}>
            <CustomInput
                error={!url.validity.isValid}
                labelText="URL...*"
                id="idVersion"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{...url.props}}
            />
            <InputErrors errors={url.validity.errors}/>

        </GridItem>
        <GridItem xs={12} sm={12} md={12} className={classes.contentRight}>
            <RegularButton
                disabled={!validForm()}
                color="success"
                onClick={() => {
                    submit();
                }}
            >
                Save
            </RegularButton>
        </GridItem>
    </GridContainer>
}