import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import {Route, Switch, Redirect, HashRouter} from "react-router-dom";
import createSagaMiddleware from "redux-saga";
import {createStore, applyMiddleware} from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {loadState, saveState} from './localStorage';
import * as serviceWorker from './serviceWorker';


import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import {Provider} from "react-redux";
import reducer from "./reducers";
import root from "./sagas";
import AppAlert from "./views/Components/AppAlert/AppAlert";

const persistConfig = {
  key: 'root',
  storage,
}
const persistedState = loadState();
const persistedReducer = persistReducer(persistConfig, reducer)

const hist = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, persistedState, applyMiddleware(sagaMiddleware));
let peristor=persistStore(store);
sagaMiddleware.run(root);
const idToken = localStorage.getItem('idToken');

//To persist only data, pass an object with the necessary data I want to save.
store.subscribe(() =>{
  saveState({
    store: { 
      action: store.getState().store.action,
      age: store.getState().store.age,
      allDetails: store.getState().store.allDetails,
      allTemplates: store.getState().store.allTemplates,
      allTypes: store.getState().store.allTypes,
      form: store.getState().store.form,
      janHaitoTemplates: store.getState().store.janHaitoTemplates,
      selectedDetailsID: store.getState().store.selectedDetailsID,
      isImage: store.getState().store.isImage,
      columnData: store.getState().store.columnData,
      smileDonatorTemplates: store.getState().store.smileDonatorTemplates,
      templateId: store.getState().store.templateId,
      templateName: store.getState().store.templateName,
      templateType: store.getState().store.templateType,
      ImageCount: store.getState().store.ImageCount,
      uploadedFileDetails: store.getState().store.uploadedFileDetails,
      previewImage: store.getState().store.previewImage,
      janHaitoDetails: store.getState().store.janHaitoDetails,
      smileDonatorDetails: store.getState().store.smileDonatorDetails,
      templateStatus: store.getState().store.templateStatus,
      detail: store.getState().store.detail,
      id: store.getState().store.id,
      uploadedFile: store.getState().store.uploadedFile,
      file_name: store.getState().store.file_name,
      fileIndex: store.getState().store.fileIndex,
      fileURL: store.getState().store.fileURL,
      file: store.getState().store.file,
      image: store.getState().store.image,
      tutorialModules: store.getState().store.tutorialModules,
      tutorialInputs: store.getState().store.tutorialInputs
    }
  
  })
})

ReactDOM.render(
<Provider store={store} >
  <HashRouter history={hist}>
    <Switch>
      <Route path="/auth" component={AuthLayout} />
      <Route path="/admin" component={AdminLayout} />
      <Redirect from="/" to={idToken ? "/admin/dashboard":"/auth/login"}/>
    </Switch>
  </HashRouter>
  <AppAlert/>
</Provider>,
  document.getElementById("root")
);
serviceWorker.register();