import * as React from 'react';
import useInfoStore from "../useInfoStore";
import {ACTIVE_STATUS, TABLE_HEADER_CONFIG,ALERT_MESSAGES,DATA_TYPES} from "../config";
import {Chip, TableRow} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Moment from "react-moment";
import {Image} from "./Image";
import IconButton from "@material-ui/core/IconButton";
import {Delete, Edit} from "@material-ui/icons";
import {SHOW_APP_ALERT} from "../../../action-types";
import {useDispatch} from "react-redux";
import {InfoService} from "../../../services/InfoService";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
type Props = {

};

export default ()=> {
    const dispatch = useDispatch();

    const {info, changeEditable, changeFormOpen, loadInfo} = useInfoStore();

    const editHandler = row => {
        changeEditable(row);
        changeFormOpen(true);
    }
    const cellSwitch = (th, row) => {
        switch (th.type) {
            case DATA_TYPES.STRING:
                return row[th.Key];
            case DATA_TYPES.DATE_TIME:
                return <Moment format={"YYYY-MM-DD HH:MM:SS"}>{row[th.Key]}</Moment>
            case DATA_TYPES.S3:
                return  <>
                    <List>
                        {row.URLs.map((data) => {
                            return <Image   key={data.device} data={data}/>
                        })}
                    </List>
                </>
            case DATA_TYPES.DEVICE:
                return  <>
                    <List>
                        {row.URLs.map((data) => {
                            return <ListItem key={data.device}>
                                <ListItemText primary={data.device}/>
                            </ListItem>
                        })}
                    </List>
                </>
            case DATA_TYPES.STATUS:
                return <Chip variant="outlined" size="small" label={ACTIVE_STATUS[row[th.Key]]}/>
            case DATA_TYPES.ACTIONS:
                return <>
                    <IconButton size={"small"} onClick={() => {editHandler(row)}}><Edit/></IconButton>
                    <IconButton size={"small"} onClick={() => deleteConfirmation(row)}><Delete/></IconButton>
                </>
            case DATA_TYPES.HTML:
                return <div dangerouslySetInnerHTML={{__html:row[th.Key]}}/>
            case DATA_TYPES.URLs:
                return <>
                    <List>
                        {row.URLs.map(({url, device}) => {
                            return <ListItem key={device}>
                                     <ListItemText primary={url}/>
                                    </ListItem>
                        })}
                    </List>
                </>
            default:
                return row[th.Key];
        }
    }

    const loadRaws = () => {
        if (info) {
            return info.map(temp_info => {
                return <TableRow key={temp_info.Id}>
                    {
                        TABLE_HEADER_CONFIG['DEFAULT'].map(th => <TableCell key={th.Key + temp_info.Id}>{cellSwitch(th, temp_info)}</TableCell>)
                    }
                </TableRow>
            })
        } else {
            return <TableRow><TableCell colSpan={TABLE_HEADER_CONFIG['DEFAULT'].length}></TableCell></TableRow>;
        }
    }
    const deleteRow = async id => {
        await InfoService.delete(id)
        loadInfo();
        dispatch({ type: SHOW_APP_ALERT, alertConfig: ALERT_MESSAGES.DELETE_SUCCESS });
    }
    const deleteConfirmation = row => {
        let config = {
            type: "confirmation",
            message: 'Do you want to delete the ' + row.Name + ' ?',
            onConfirm: () => deleteRow(row.Id)
        };
        dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
    }
    return <TableBody>
        {loadRaws()}
    </TableBody>
}