import dsdInstance from "./dsdInstance";
import {API_URL} from "../config/api";

export class PrinterService {
    static getAll = async () => {
        try {
            const res = await dsdInstance.get(API_URL.PRINTER);
            return res.data.data.result;
        }
        catch (e) {
            console.error(e);
            return [];
        }
    }
    static update = async data => {
        try {
            const res = await dsdInstance.put(API_URL.PRINTER, data)
            return res.data.data.result;

        }
        catch (e) {
            console.error('Invalid Response format', e)
        }
    }
    static create = async data => {
        try {
            const res = await dsdInstance.post(API_URL.PRINTER, data)
            return res.data.data.result;

        }
        catch (e) {
            console.error('Invalid Response format', e)
        }
    }
    static delete = async id => {
        try {
            const res = await dsdInstance.delete(API_URL.PRINTER + '/' + id);
            return res.data.data.result;
        }
        catch (e) {
            console.error(e);
        }
    }
}