import React, {useEffect, useState} from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import adminActions from "../../services/dashboardServices";
import UpdateIcon from '@material-ui/icons/Update';
import {COMMIT_SERVER_SETTINGS, SHOW_APP_ALERT} from "../../action-types";
import {useDispatch} from "react-redux";
import finalSettingsComponent from "components/Settings/settingsComponent";

// @material-ui/icons
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import RegularButton from "components/CustomButtons/RegularButton.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";


import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const us_flag = require("assets/img/flags/US.png");
const de_flag = require("assets/img/flags/DE.png");
const au_flag = require("assets/img/flags/AU.png");
const gb_flag = require("assets/img/flags/GB.png");
const ro_flag = require("assets/img/flags/RO.png");
const br_flag = require("assets/img/flags/BR.png");

const saleData =[
  {
    flagImage: us_flag,
    country: "US",
    sales: "2920",
    salePercent: "53.23%"
  },
  {
    flagImage: de_flag,
    country: "DE",
    sales: "2920",
    salePercent: "23.23%"
  },
  {
    flagImage: au_flag,
    country: "AU",
    sales: "2920",
    salePercent: "13.23%"
  },
  {
    flagImage: gb_flag,
    country: "GB",
    sales: "2920",
    salePercent: "7%"
  },
  {
    flagImage: ro_flag,
    country: "RO",
    sales: "2920",
    salePercent: "3%"
  },
  {
    flagImage: br_flag,
    country: "BR",
    sales: "550",
    salePercent: "4%"
  }
];
 
const useStyles = makeStyles(styles);

export default function Dashboard(props) {

  const [AICases, setAICases] = useState(""); 
  const dispatch = useDispatch();
  const classes = useStyles(useStyles);

  const mapMapData =() =>{
    let mapData = {}
    for (let i=0;i<=saleData.length-1;i++){
      mapData[saleData[i].country] = parseInt(saleData[i].salePercent);
    }
    return mapData;
  }

  const mapSaleData = () =>{
    let masterArray = [];
    for (let i=0; i<=saleData.length-1; i++){
      let array = [
        <img src={saleData[i].flagImage} alt="country_flag" />, 
        saleData[i].country, 
        saleData[i].sales,
        saleData[i].salePercent
      ];
      masterArray.push(array); 
    }
    return masterArray;
  
  }

  const activeRoute = routeName => {
    // window.location.href = process.env.REACT_APP_BASE_URL + routeName;
    props.history.push({
      pathname: routeName,
      params: ""
    });
  };

  const getAISmileCases = async () =>{
    let result = await adminActions.getAICases();
    if (result && result.hasOwnProperty('data') && result.data.hasOwnProperty('data') && result.data.data.hasOwnProperty('result')) {
      setAICases(result.data.data.result)
    }
  }

  useEffect(() => {
  getAISmileCases();

  //Populate server-delivered settings
  let serverSettings = ["News","DSDSettings2","DSDSettings3"];
  let viewsArray = [];
  let payloadObjectandViews = {
    collapse: true,
    hidden: true,
    rtlName: "DSD-Settings",
    state:"DSDSettingsState",
    name: "DSD-Settings",
    icon: SettingsIcon,
    layout: "/admin",
    views: viewsArray
  };
  for (let i=0;i<=serverSettings.length-1;i++) {
    viewsArray.push({
      path: "/dsd-"+serverSettings[i],
      name: serverSettings[i],
      //align menu items in icon
      icon: "     ",
      layout: "/admin",
      component: finalSettingsComponent,
      id: "randomID"
    });
  }
  dispatch({ type: COMMIT_SERVER_SETTINGS, payload: payloadObjectandViews});
   }, []);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <h5 className={classes.cardTitle}>AI Case Count</h5>
              <h3 className={classes.cardTitle}>
                {AICases ? AICases : "..."}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              <UpdateIcon />
                <span style={{color:"#9c27b0"}}  onClick={e => e.preventDefault()}>
                  Last Updated 6 Hours Ago
                </span>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Language />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Global Sales by Top Locations
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={5}>
                <Table
                    tableData={mapSaleData()}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <VectorMap
                    map={"world_mill"}
                    backgroundColor="transparent"
                    zoomOnScroll={false}
                    containerStyle={{
                      width: "100%",
                      height: "280px"
                    }}
                    containerClassName="map"
                    regionStyle={{
                      initial: {
                        fill: "#e4e4e4",
                        "fill-opacity": 0.9,
                        stroke: "none",
                        "stroke-width": 0,
                        "stroke-opacity": 0
                      }
                    }}
                    series={{
                      regions: [
                        {
                          values: mapMapData(),
                          scale: ["#AAAAAA", "#444444"],
                          normalizeFunction: "polynomial"
                        }
                      ]
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader color="info" className={classes.cardHeaderHover}>
              <ChartistGraph
                className="ct-chart-white-colors"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title="Refresh"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <RegularButton simple color="info" justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </RegularButton>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Change Date"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <RegularButton color="transparent" simple justIcon>
                    <Edit className={classes.underChartIcons} />
                  </RegularButton>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>3D Designs</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} />
                </span>{" "}
                12 Completed with Facial Scan
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader color="warning" className={classes.cardHeaderHover}>
              <ChartistGraph
                className="ct-chart-white-colors"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title="Refresh"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <RegularButton simple color="info" justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </RegularButton>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Change Date"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <RegularButton color="transparent" simple justIcon>
                    <Edit className={classes.underChartIcons} />
                  </RegularButton>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>Smile Simulations</h4>
              <p className={classes.cardCategory}>Saved Smile Simulations throughout the year</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> 4 documents for the last 24 hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader color="danger" className={classes.cardHeaderHover}>
              <ChartistGraph
                className="ct-chart-white-colors"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title="Refresh"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <RegularButton simple color="info" justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </RegularButton>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Change Date"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <RegularButton color="transparent" simple justIcon>
                    <Edit className={classes.underChartIcons} />
                  </RegularButton>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>Patient Documents</h4>
              <p className={classes.cardCategory}>Saved documents throughout the year</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> 10 documents for the last 24 hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
