import { connect } from "react-redux";
import React, { useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import RegularButton from "components/CustomButtons/RegularButton.js";
import PropTypes from "prop-types";
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  COMMIT_AVAILABLE_TUTORIAL_LANGUAGES,
  COMMIT_ALL_TUTORIAL_MODULES, SHOW_APP_ALERT
} from "../../action-types";
import adminActions from "../../services/tutorial";
import { makeStyles } from '@material-ui/core/styles';
import "./tutorialComponent.css"
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TutorialInputsComponent from "./tutorialInputsComponent";
import Spinner from '../Initials/spinner.js'

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
   overflow: 'auto',
   overflowX: 'hidden',
   height: 400
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }

}));

function TutorialComponent(props) {
  let dispatch = useDispatch();
  const classes = useStyles();
  var uniqid = require('uniqid');
  const [inputsFromServer, setInputsFromServer] = useState();
  const [tutorialCode, setTutorialCode] = useState("Select Section");
  const [tutorialName, setTutorialName] = useState("Select Section");
  const [displayNameForAlert, setDisplayNameForAlert] = useState("");
  const [screenServerData, setScreenServerData] = useState(); 
  const [update, setUpdate] = useState(false);
  const [selectedID, setSelectedID] = useState(""); 
  let [open, setOpen] = useState(false);

  const getToken = () => {
    let token = localStorage.getItem("idToken");
    return token;
  };

  const handleOpen = username => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const activeRoute = routeName => {
    props.history.push({
      pathname: routeName,
      params: ""
    });
  };
 
  const formatScreensData = (responseData, inputData, id) =>{
    let screensArray = [];
    let finalScreensArray= [];
    
    //Find and remove selected ID from displayed screens.
    if (id){
      if (typeof id == typeof ""){
        for (let i=0;i<=responseData.length-1;i++){
          if (responseData[i].Id == id){
            responseData.splice(i,1);
          }
        }
      }
      else {
        responseData.push(id);
      }
    }

    for (let i=0;i<=responseData.length-1;i++){
      screensArray.push(
          <GridItem xs={12}>
          <TableRow xs={12} sm={12} md={12} id={responseData[i].Id} className="moduleRow" hover={true}>
          <TableCell xs={12} sm={12} md={12} classes={{body: "cellStyle"}}
              onClick={(e) => {
                setTutorialNameAndCode(responseData[i], inputData);
                setSelectedID(responseData[i].Id);
              }}>{responseData[i].Name}
          </TableCell>
            <TableCell xs={12} sm={12} md={12}>
              <DeleteIcon 
              onClick={(e) => {
              handleOpen();
              setDisplayNameForAlert(responseData[i].Name);
              setTutorialName("Select Section");
              setSelectedID(responseData[i].Id);
            }}  />
          </TableCell>
          </TableRow>
          </GridItem>
       );
    }
    finalScreensArray.push(
      <GridContainer direction="row" style={{width: "100%"}} alignItems="center"> 
          {screensArray}
      </GridContainer>
    );
    setScreenServerData(finalScreensArray);
  }

  const deleteTutorial = async (id, responseData, inputData) =>{

          try {
            let result = await adminActions.deleteTutorial(
              id
            ).then(result =>{
              handleClose();
              formatScreensData(responseData, inputData, id);
              let config = {
                type: 'success',
                title: "Success",
                message: "You deleted a module!"
              };
               dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
            })
         } 
          catch (err) {
            let config = {
              type: 'error',
              title: "Error",
              message: err.message
            };
             dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
             activeRoute("/auth/login");
          }
  }

  const setTutorialNameAndCode = (module, inputs) =>{
    let index = 0;
    if (module == "Create"){
      setTutorialName(module);
      setSelectedID("");
    }
    else{
      setTutorialName(module.Name);
      setTutorialCode(module.Code);
    }
  }

  const loadAllTutorials = async () => {
    let placeholderTutorialData;
  try {
    let result = await adminActions.getAllTutorials();
   placeholderTutorialData = result.data.data.result.data;
   dispatch({ type: COMMIT_ALL_TUTORIAL_MODULES, payload: result.data.data.result.data });
  } catch (err) {
    console.error(err);
  }
  try {
    const result = await adminActions.getAllTutorialLanguages().then(result =>{
      dispatch({ type: COMMIT_AVAILABLE_TUTORIAL_LANGUAGES, payload: result.data.data.result });
      formatScreensData(placeholderTutorialData, result.data.data.result);
      setInputsFromServer(result.data.data.result);
    });
  } catch (err) {
    console.error(err);
  }
}

  useEffect(() => {
    loadAllTutorials();

  }, []);

  return (
    <div>
    <GridContainer>
      <GridItem xs={4} sm={4} md={4}>
        <GridItem xs={12} sm={12} md={12} mb={10}>
            <RegularButton
              type="button"
              color="primary"
              onClick={() => {
                setTutorialNameAndCode("Create",inputsFromServer);
              }}>Create Module &nbsp;<AddBoxIcon />
              </RegularButton>
            </GridItem>
          <Card>
            <CardHeader>
              <h4 className={classes.cardTitle}>
                  {tutorialName}
              </h4>
            </CardHeader>
            <CardBody>
              <GridItem xs={12} sm={12} md={12} mb={10}>
                <span>&nbsp;</span>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <TableContainer>
                  <TableBody className={classes.tableBody}>
                    <Box className={classes.root} width="100%">
                  {screenServerData ? screenServerData: <Spinner fetching={true}/>}
                  </Box>
                  </TableBody>
                </TableContainer>
              </GridItem>      
            </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={8} sm={8} md={8}>
      <GridItem xs={12} sm={12} md={12} mb={10}>
      <RegularButton style={{visibility:"hidden"}}></RegularButton>
      <span>&nbsp;</span>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} mb={10}>
      <span>&nbsp;</span>
      </GridItem>
        <TutorialInputsComponent 
        key={uniqid()} 
        moduleId = {selectedID} 
        moduleName={tutorialName} 
        inputs={inputsFromServer} 
        moduleCode={tutorialCode} 
        formatScreensData={formatScreensData} 
        />
      </GridItem>
    </GridContainer>
          <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h3 id="transition-modal-title">Alert</h3>
              <p id="transition-modal-description">
              Do you want to delete {displayNameForAlert}?
              </p>
              <RegularButton onClick={() => deleteTutorial(selectedID, props.tutorialModules, props.tutorialInputs)} color={"danger"}>
                yes
              </RegularButton>{" "}
              <RegularButton onClick={() => handleClose()}>no</RegularButton>
            </div>
          </Fade>
        </Modal>
        </div>
  );
}
 
const mapStateToProps = state => {
  return {
    tutorialInputs: state.store.tutorialInputs,
    tutorialModules: state.store.tutorialModules,
    allDetails: state.store.allDetails,
  };
};

const wrapperFunction = connect(mapStateToProps);
const finalTutorialComponent = wrapperFunction(TutorialComponent);

TutorialComponent.propTypes = {
  history: PropTypes.array,
  push: PropTypes.func,
};

export default finalTutorialComponent;
