import React, {useEffect, useState} from 'react';
import CardHeader from "../../../components/Card/CardHeader";
import {CardActions, CardContent} from "@material-ui/core";
import {STLViewer} from "react-stl-obj-viewer";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import Button from "@material-ui/core/Button";
import {CloudDownload, CloudUpload, Delete, EditAttributes, EditRounded} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Card from "../../../components/Card/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import {red} from "@material-ui/core/colors";
import {useDispatch} from "react-redux";
import {SHOW_APP_ALERT} from "../../../action-types";
import AppIntegrationService from "../../../services/AppIntegrationService";
const useStyles = makeStyles((theme) => ({
    ...styles,
    root: {
        // maxWidth: 345,
    },
    '&:hover': {
        color: "rgb(128 128 128)"
    },
    stlMedia: {
        // padding: "20px 20px 20px 20px",
        margin: "auto",
        display: "block",
        maxHeight: "100%",
        maxWidth: "100%",
    },
    media: {
        padding: "20px 20px 20px 20px",
        margin: "auto",
        display: "block",
        maxHeight: "100%",
        maxWidth: "100%",
    },
    mediaParent: {
        height: "200px",
        width: "100%",
        // backgroundColor: "#EAEAEA",
        position: "relative"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    cardContent: {
        textAlign: "center"
    },
    cardHeader: {
        textAlign: "center"
    },
    cardActions: {
        textAlign: "right",
    },
    cardActionsButtons: {
        marginLeft: 'auto',
    },
    progressBar: {width: "100%", display: "inline-block"}
}));



export default function IntegrationCard(props) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const {Id, Name, FileURL, URL} = props.item;

    const deleteIntegration = async () => {
        await AppIntegrationService.delete(Id);
        let config = {
            type: "success",
            message: 'Configuration deleted successfully',
        };
        dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        props.loadIntegrations();
    }
    const deleteConfirmation = () => {
        let config = {
            type: "confirmation",
            message: 'Do you want to delete this configuration?',
            onConfirm: deleteIntegration
        };
        dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
    }
    return <Card className={classes.root}>
        <CardHeader>
            <h4 className={classes.cardHeader}>{Name}</h4>
        </CardHeader>
        <CardContent className={classes.cardContent}>

            <div className={classes.mediaParent}>
                <img src={FileURL} className={classes.media} loading="lazy" alt={'...'}/>

            </div>
        </CardContent>
        <CardActions disableSpacing>
            <div className={classes.cardActionsButtons}>
                <IconButton color={"default"} onClick={() => {props.setForEdit({...props.item})}}>
                    <EditRounded/>
                </IconButton>
                <IconButton onClick={() => {
                    deleteConfirmation()
                }}>
                    <Delete/>
                </IconButton>

                {/*<a href={props.item.URL} target="_blank" style={{color: "rgb(128 128 128)"}}>*/}
                {/*    <CloudDownload />*/}
                {/*</a>*/}
            </div>
        </CardActions>
    </Card>
}