import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";
import CustomInput from "../../components/CustomInput/CustomInput";
import {useInputProps} from "../../components/CustomInput/InputPropsHook";
import admin from "../../services/admin";
import {SHOW_APP_ALERT} from "../../action-types";
import {useDispatch} from "react-redux";

export default function AdminUserReset({resetUser, setResetUser}) {
    const dispatch = useDispatch();
    const newPassword = useInputProps({type: "text", rules: ["required"]});

    const handleClose = () => {
        setResetUser("");
        newPassword.setValue("");
    }
    const submit = async () => {
        const r = await admin.changePassword({username: resetUser, password: newPassword.props.value});
        if (r) {
            let config = {
                type: "success",
                message: "Successfully changed password",
            };
            dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        }
        else {
            let config = {
                type: "danger",
                message: "Something went wrong",
            };
            dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        }
        handleClose();
    }
    return (
        <Dialog disableBackdropClick={true} open={resetUser.length > 0} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Reset password for {resetUser}</DialogTitle>
            <DialogContent>
                <CustomInput
                    error={!newPassword.validity.isValid}
                    labelText="New Password...*"
                    id="newPassword"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{...newPassword.props}}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={submit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>

    );
}
AdminUserReset.prototype = {
    resetUser: PropTypes.string,
    setResetUser: PropTypes.func
}