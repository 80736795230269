import React, {useEffect, useState} from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import RegularButton from "components/CustomButtons/RegularButton.js";
import {useDispatch, useSelector} from "react-redux";
import FormHelperText from '@material-ui/core/FormHelperText';
import "./PasswordReset.css"
import adminActions from "../../services/admin";
import {RESET_APP, SHOW_APP_ALERT} from "../../action-types"
import VisibilityIcon from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(styles);

export default function PasswordReset(props) {

  const dispatch = useDispatch();
  const resetReducer = useSelector(state => state.auth);
  const classes = useStyles();
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmedPassword] = useState();
  const [oldPasswordVisibility, setOldPasswordVisibility] = useState("password");
  const [newPasswordVisibility, setNewPasswordVisibility] = useState("password");
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState("password");
  const [errorMessage, setErrorMessage] = useState();

  const getToken = () => {
    let token = localStorage.getItem("idToken");
    return token;
  };

  const activeRoute = routeName => {
    // window.location.href = process.env.REACT_APP_BASE_URL + routeName;
    props.history.push({
      pathname: routeName,
      params: ""
    });
  };

  const handleChange = (e, label) =>{
    if (label === "oldPassword"){
      setErrorMessage('');
      setOldPassword(e.target.value);
    }
    else if (label ==="newPassword"){
      setErrorMessage('');
      setNewPassword(e.target.value);
    }
    else if (label === "confirmPassword"){
      setErrorMessage('');
      setConfirmedPassword(e.target.value);
    }
  }

  useEffect(() => {
    if (!(localStorage.getItem('auth-session') && !localStorage.getItem('username'))) {
      props.history.push({
        path: '/auth/login',
        params: ''
      })
    }
  }, []);

  useEffect(() => {

  }, [resetReducer]);

  const apiObject = {
    username: localStorage.getItem('username'),
    previous_password: oldPassword,
    new_password: newPassword,
    refresh_token: localStorage.getItem('refreshToken')
  };

  var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    const confirm = async () => {
      if (newPassword != confirmPassword){
        setErrorMessage("New and Confirmed Passwords Do Not Match");
        return;
      }
      if (!strongRegex.test(confirmPassword)){
        setErrorMessage("Passwords must contain 8-16 characters, 1 special character, 1 number, 1 uppercase and 1 lowercase letter.");
        return;
      }

      if (localStorage.getItem("idToken")){
        try {
          await adminActions.resetPassword(
            apiObject
          );
          let config = {
            type: 'success',
            title: "Success",
            message: "Your password has been succesfully reset."
          };
           dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
           activeRoute("/admin/dashboard");
        } catch (err) {
          let config = {
            type: 'error',
            title: "Error",
            message: err.response.data.err
          };
           dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        }
      }
      else{
          const payload = {
            new_password: newPassword,
            username: localStorage.getItem('username'),
            session: localStorage.getItem('auth-session')
          }
          try {
            let result = await adminActions.newPassword(
              payload
            ).then(result =>{
              let config = {
                type: 'success',
                title: "Success",
                message: "Your password has been succesfully reset."
              };
               dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
               dispatch({type: RESET_APP});
               activeRoute("/auth/login");
               localStorage.removeItem('auth-session');
            });
          } catch (err) {
            let config = {
              type: 'error',
              title: "Error",
              message: err.message
            };
             dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
          }
      }
    };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={6}>
          <Card className={classes.cardSignup}>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <h4 className={classes.cardTitle}>RESET YOUR PASSWORD</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                   <span>&nbsp;</span>
                </GridItem>
                {getToken() ? 
                <GridItem xs={12} sm={12} md={12}>
                <InputLabel fullWidth htmlFor="standard-adornment-password">Enter Current Password</InputLabel>
                <Input
                    id="standard-full-width"
                    type={oldPasswordVisibility}
                    fullWidth
                    onChange={e => handleChange(e,"oldPassword")}
                    endAdornment={
                      <InputAdornment position="end">
                        <VisibilityIcon
                        className="visibility"
                          onClick={e => {
                            if (oldPasswordVisibility==="password"){
                              setOldPasswordVisibility("username");
                            }
                            else{
                              setOldPasswordVisibility("password");
                            }
                            }} 
                        />
                      </InputAdornment>}
                  /></GridItem> : null}

                <GridItem xs={12} sm={12} md={12}>
                   <span>&nbsp;</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} >
                <InputLabel fullWidth htmlFor="standard-adornment-password">Enter New Password</InputLabel>
                <Input
                    id="standard-full-width"
                    type={newPasswordVisibility}
                    fullWidth
                    onChange={e => handleChange(e,"newPassword")}
                    endAdornment={
                      <InputAdornment position="end">
                        <VisibilityIcon
                          className="visibility"
                          onClick={e => {
                            if (newPasswordVisibility==="password"){
                              setNewPasswordVisibility("username");
                            }
                            else{
                              setNewPasswordVisibility("password");
                            }
                            }}
                        />
                      </InputAdornment>}
                  />
                  </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                   <span>&nbsp;</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                <InputLabel fullWidth htmlFor="standard-adornment-password">Confirm New Password</InputLabel>
                <Input
                    id="standard-full-width"
                    type={confirmPasswordVisibility}
                    fullWidth
                    onChange={e => handleChange(e,"confirmPassword")}
                    endAdornment={
                      <InputAdornment position="end">
                        <VisibilityIcon
                          className="visibility"
                          onClick={e => {
                            if (confirmPasswordVisibility==="password"){
                              setConfirmPasswordVisibility("username");
                            }
                            else{
                              setConfirmPasswordVisibility("password");
                            }
                            }}
                        />
                      </InputAdornment>}
                  /><FormHelperText id="confirm-component-error-text" style={{color: "red"}}>{errorMessage}</FormHelperText>
                  </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                   <span>&nbsp;</span>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <div className={classes.center} style={{paddingTop: "10px"}}>
                    <RegularButton color="success" onClick={confirm}>
                      Reset
                    </RegularButton>
                  </div>
                </GridItem>
                 <GridItem xs={6} sm={6} md={6}>
                  <div className={classes.center} style={{paddingTop: "10px"}}>
                    <RegularButton
                    onClick={e => {
                      if (!localStorage.getItem('idToken')){
                      dispatch({type: RESET_APP});
                      props.history.goBack();
                      }
                      else{
                        props.history.goBack();
                      }}}>
                      {localStorage.getItem('idToken')? "Go Back" : "Return to Login"}
                    </RegularButton>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}
