import {LOGIN_NOT_OK, LOGIN_OK, PASSWORD_RESET_NOT_OK, PASSWORD_RESET_OK} from "../common/action-types";

export default (state = { fetching: false }, action) => {
  switch (action.type) {
    case LOGIN_NOT_OK:
      return {
        ...state,
        failed: true,
        loginError: action.payload
      }
    case LOGIN_OK:
      return {
        ...state,
        ...action.payload.resp,
        signedUp: true
      };
    case PASSWORD_RESET_OK:
      return {
        ...state,
        ...action.payload,
        resetPassword: true
      };
    case PASSWORD_RESET_NOT_OK:
      return {
        ...state,
        resetError: action.payload,
        resetPasswordNotOk: true
      };
    default:
      return state;
  }
};
