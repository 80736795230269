
export const CHAT_GROUP_HEADERS = [
    {name: 'Group name', key: 'name', align: 'left'},
    {name: 'Created at', key: 'created_at', align: 'left'},
    {name: 'Updated at', key: 'updated_at', align: 'left'},
    {name: 'No. of users', key: 'user_count', align: 'left'},
    {name: 'Actions', key: 'action', align: 'right'}
];

export const CHAT_GROUP_MEMBER_HEADERS = [
    {name: 'Name', key: 'full_name', align: 'left'},
    {name: 'Email', key: 'email', align: 'left'},
    {name: 'Actions', key: 'action', align: 'right'}
];