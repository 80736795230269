import React, {useEffect} from 'react';
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import useInfoStore from "./useInfoStore"
import {List} from "./Cmp/List";
import InfoProvider from "./InfoProvider";
import NewForm from "./Cmp/NewForm";

export default function Info() {

    const CmpSwitch = () => {
        const {formOpen, loadConfig} = useInfoStore();
        useEffect(() => {
            loadConfig();
        }, [])
        return !formOpen ? <List/> : <NewForm/>
    }
    return <InfoProvider>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <CmpSwitch/>
            </GridItem>
        </GridContainer>
    </InfoProvider>
}