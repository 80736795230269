import React, {useEffect, useState} from 'react';
import Timeline from "../../../components/Timeline/Timeline";
import subscriptionService from "../../../services/subscriptionService";
import HistoryIcon from "@material-ui/icons/History";
import {AssistantPhoto, Shop, ShopTwo} from "@material-ui/icons";
import Moment from "react-moment";
import AssignNewPlan from "./AssignNewPlan";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";
import stylesSweet from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import OneTimeHistoryBody from "../OneTimeHistoryBody";

const config = {
    'badgeColor': {
        0: 'info',
        1: 'success',
        'new': 'warning' // new
    },
    'badgeIcon': {
        0: HistoryIcon,
        1: AssistantPhoto,
        'new': ShopTwo
    }
}
const useStyles = makeStyles({
    ...styles,
    ...stylesSweet,
    datePickerInput: {
        "textAlign": "center",
        "width": "91px",
        "color": "#fff",
        "fontWeight": "bold",
        "borderRadius": "13px",
        "borderColor": "#9c27b0",
        "borderStyle": "none",
        "backgroundColor": "#9c27b0",
        "padding": "3px 0px 3px 0px",
        "cursor": "pointer"
    },
    floatLeft: {float: "left"},
    floatRight: {float: "right"},
    designCmp: {
        paddingBottom: "10px"
    }
});

export default function UserPlans(props) {
    const classes = useStyles();
    const {selected} = props;
    const [subscribedItems, setSubscribedItems] = useState([]);
    const [PlanList, setPlanList] = useState([]);
    const [endDate, setEndDate] = useState()
    const [alert, setAlert] = useState(null);
    useEffect(() => {
        loadPlans();
    }, []);
    useEffect(() => {
        setEndDate("");
        loadHistory();
    }, [selected])

    const loadHistory = async () => {
        const response = await subscriptionService.getHistory(selected.Username, "");
        setSubscribedItems(response.data.data.result);
    }
    const loadPlans = async () => {
        const response = await subscriptionService.getPlans();
        setPlanList(response.data.data.result.data);
    }

    const setCurrentDate = async (e, si) => {
        setEndDate(e);
        const r = await subscriptionService.editAssign(selected.Username, si.Id, e.format('YYYY-MM-DD'))
        if (r.status === 200) {
            successAlert();
        }
    }

    const cutDate = (date ="") => {
        let x = date.split("T")
        if (x.length >1) {
            return x[0];
        }

    }
    const update = async (param) => {
        const r = await subscriptionService.put(selected.Username, param)
        if (r.status === 200) {
            successAlert();
        }
    }
    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success"
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Plan updated successfully!
            </SweetAlert>
        );
    };
    const loadTineStories = items => {
        let newItems = [
            {
                inverted: true,
                badgeColor: config.badgeColor['new'],
                badgeIcon: config.badgeIcon['new'],
                title: "Assign a Recurring Plan",
                titleColor: config.badgeColor['new'],
                body: (''),
                footer: (
                    <AssignNewPlan PlanList={PlanList} loadHistory={loadHistory} user={selected}/>
                )
            }
        ];


        items.forEach(si => {

            newItems.push({
                inverted: true,
                badgeColor: config.badgeColor[si.Status],
                badgeIcon: config.badgeIcon[si.Status],
                title: si.PlanName,
                titleColor: config.badgeColor[si.Status],
                body: (
                    <div>
                        <hr/>
                        <p className={classes.floatLeft}>Duration : From&nbsp; <b><Moment
                            format={"YYYY-MM-DD"}>{si.StartDate}</Moment></b> to&nbsp;</p>
                        <p className={classes.floatLeft}>{
                            si.Status
                            ?
                                <Datetime
                                    closeOnSelect={true}
                                onChange={(e) => {setCurrentDate(e, si)}}
                                inputProps={{className: classes.datePickerInput}}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                value={endDate ? endDate : cutDate(si.EndDate)}
                            />
                            : <b><Moment format={"YYYY-MM-DD"}>{si.EndDate}</Moment></b>}
                        </p>
                        {
                            si.hasOwnProperty("Type") && si.Type === "DESIGN4ME"
                                ? <div className={classes.designCmp}><OneTimeHistoryBody si={si} update={update}/></div>
                                : ""
                        }
                        <p className={classes.floatRight}>Created At : <b><Moment
                            format={"YYYY-MM-DD"}>{si.CreatedAt}</Moment></b></p>
                    </div>
                ),
            })
        });
        return newItems;
    }

    return <span>
        {alert}
        <Timeline simple stories={loadTineStories(subscribedItems)}/>
    </span>

}