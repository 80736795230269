import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FileUploader from "components/CustomUpload/FileUploader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(3, 1, 1),
  },
  imageList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

export default function Gallery({
  images,
  id,
  device,
  deleteImage,
  setUploadFiles,
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ImageList rowHeight={180} className={classes.imageList}>
        <ImageListItem key="Subheader" cols={2} style={{ height: "auto" }}>
          <ListSubheader component="div">
            {device.toUpperCase()} Images
            <FileUploader setFiles={setUploadFiles}></FileUploader>
          </ListSubheader>
        </ImageListItem>
        {images.map((item) => {
          return (
            <ImageListItem key={item.image}>
              <img src={item.SignedURL} alt={item.image} key={item.image} />
              <ImageListItemBar
                title={item.image}
                actionIcon={
                  <IconButton
                    aria-label={`Delete ${item.image}`}
                    className={classes.icon}
                    onClick={() => {
                      deleteImage({
                        info_id: id,
                        device: device,
                        file_name: item.image,
                      });
                    }}
                  >
                    <DeleteIcon></DeleteIcon>
                  </IconButton>
                }
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </div>
  );
}

