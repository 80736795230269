import React, {useEffect, useState} from 'react';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import InputErrors from "../../components/CustomInput/InputErrors";
import {useInputProps} from "../../components/CustomInput/InputPropsHook";
import ImageUploadV1 from "../../components/CustomUpload/ImageUploadV1";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RegularButton from "../../components/CustomButtons/RegularButton";
import commonStyle from "../../common/styles/common";
import AppIntegrationService from "../../services/AppIntegrationService";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {S3FileService} from "../../services/S3FileService";
import {SHOW_APP_ALERT} from "../../action-types";
import useActionButtonProps from "../../components/CustomUpload/useActionButtonProps";
import {FormControlLabel, FormLabel, Switch} from "@material-ui/core";
import Printer from "./Printer";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";
import stylesRadio from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import {PrinterService} from "../../services/PrinterService";

const useStyles = makeStyles(() => ({
        ...commonStyle,
        ...buttonStyle,
        ...stylesRadio,
        tagLabel: {
            paddingLeft: "0px",
            fontSize: "12px",
            display: "block"
        },
        tagHr: {
            borderTop: "1.5px solid #d2d2d2;",
            marginTop: "0px",
            paddingBottom: "0px"
        },
    }
));
const PrinterForm = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const uploadActionButton = useActionButtonProps();
    const Name = useInputProps({"type": "text", rules: ["required"]})
    const CommandWin = useInputProps({"type": "text"})
    const CommandMac = useInputProps({"type": "text"})
    const ArgsMac = useInputProps({"type": "text"})
    const ArgsWin = useInputProps({"type": "text"})
    const MultiSupport = useInputProps({type: "radio"});
    const WinSupport = useInputProps({type: "radio"});
    const MacSupport = useInputProps({type: "radio"});
    const Status = useInputProps({type: "radio", value: true});

    const [newFileLogo, setNewFileLogo] = useState({});
    const [newFileImage, setNewFileImage] = useState({});
    const [s3UrlLogo, setS3UrlLogo] = useState('');
    const [s3UrlImage, setS3UrlImage] = useState('');

    useEffect(() => {
        if (!props.open) {
            console.log("reset");
            resetForm();
        }
    }, [props.open]);
    
    useEffect(() => {
        if (props.forEdit) {
            console.log(props.forEdit)
            setS3UrlLogo(props.forEdit.LogoFile);
            setS3UrlImage(props.forEdit.ImageFile);
            Name.setValue(props.forEdit.Name);
            CommandWin.setValue(props.forEdit.CommandWin);
            CommandMac.setValue(props.forEdit.CommandMac);
            ArgsMac.setValue(props.forEdit.ArgsMac);
            ArgsWin.setValue(props.forEdit.ArgsWin);
            MultiSupport.setValue(props.forEdit.MultiSupport);
            Status.setValue(props.forEdit.Status);
            WinSupport.setValue(props.forEdit.WinSupport);
            MacSupport.setValue(props.forEdit.MacSupport);
        }
    }, [props.forEdit])

    const validForm = () => {

        if (!newFileImage && !s3UrlImage) {
            return false;
        }
        if (Name.props.value === "") {
            return false;
        }
        return true;
    }
    const submit = async () => {
        try {
            const param = {
                name: Name.props.value||"",
                multi_support: MultiSupport.props.value ? 1 : 0,
                command_win : CommandWin.props.value || "",
                command_mac : CommandMac.props.value || "",
                args_win : ArgsWin.props.value || "",
                args_mac : ArgsMac.props.value || "",
                status : Status.props.value ? 1 : 0,
                mac_support : MacSupport.props.value ? 1 : 0,
                win_support : WinSupport.props.value ? 1 : 0
            }
            if (newFileImage) {
                param['image_file'] = newFileImage["name"];
            }
            if (newFileLogo) {
                param['logo_file'] = newFileLogo["name"];
            }

            let res;
            if (props.forEdit) {
                param["id"] = props.forEdit.Id;
                res = await PrinterService.update(param);
            } else {
                res = await PrinterService.create(param);
            }

            if (res.hasOwnProperty('ImageURL')) {
                await S3FileService.upload(res.ImageURL, newFileImage);
            }
            if (res.hasOwnProperty('LogoURL')) {
                await S3FileService.upload(res.LogoURL, newFileLogo);
            }
            let config = {
                type: "success",
                title: "Success",
                message: "Printer configuration saved successfully",
            };
            dispatch({type: SHOW_APP_ALERT, alertConfig: config});
            resetForm();
            props.reFresh();

        } catch (e) {
            console.error(e);
        }

    }

    const resetForm = () => {
        setNewFileImage(null);
        props.setOpenForm(false);
        setS3UrlLogo(null);
        setS3UrlImage(null);
        Name.setValue("");
        CommandWin.setValue("");
        CommandMac.setValue("");
        ArgsMac.setValue("");
        ArgsWin.setValue("");
        MultiSupport.setValue(false);
        Status.setValue(true);
        MacSupport.setValue(false);
        WinSupport.setValue(false);
    }

    return <GridContainer>
        <GridItem md={6}>
            <FormLabel >Logo</FormLabel>
            <ImageUploadV1
                reset={!props.open}
                imagePreviewUrl={s3UrlLogo}
                setNewFile={(file) => setNewFileLogo(file)}
                { ...uploadActionButton }
            />
        </GridItem>
        <GridItem md={6}>
            <FormLabel >Image</FormLabel>
            <ImageUploadV1
                reset={!props.open}
                imagePreviewUrl={s3UrlImage}
                setNewFile={(file) => setNewFileImage(file)}
                { ...uploadActionButton }
            />
        </GridItem>
        <GridItem md={12}>
            <CustomInput
                error={!Name.validity.isValid}
                labelText="Name...*"
                id="idVersion"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{...Name.props}}
            />
            <InputErrors errors={Name.validity.errors}/>

        </GridItem>
        <GridItem md={12}>
            <CustomInput
                error={!CommandMac.validity.isValid}
                labelText="Command(MAC)"
                id="commandMac"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{...CommandMac.props}}
            />
            <InputErrors errors={CommandMac.validity.errors}/>

        </GridItem>
        <GridItem md={12}>
            <CustomInput
                error={!ArgsMac.validity.isValid}
                labelText="Argument(MAC)"
                id="argumentMAC"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{...ArgsMac.props}}
            />
            <InputErrors errors={ArgsMac.validity.errors}/>
        </GridItem>
        <GridItem md={12}>
            <CustomInput
                error={!CommandWin.validity.isValid}
                labelText="Command(Window)"
                id="commandWin"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{...CommandWin.props}}
            />
            <InputErrors errors={CommandWin.validity.errors}/>

        </GridItem>
        <GridItem md={12}>
            <CustomInput
                error={!ArgsMac.validity.isValid}
                labelText="Argument(Windows)"
                id="argumentWin"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{...ArgsWin.props}}
            />
            <InputErrors errors={ArgsWin.validity.errors}/>
        </GridItem>
        <GridItem sm={6}>
            <FormControlLabel
                control={
                    <Switch
                        checked={MultiSupport.props.value}
                        onChange={MultiSupport.props.onChange}
                        value="checkedMultiSupport"
                        classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                        }}
                        color={"primary"}
                    />
                }
                classes={{
                    label: classes.label
                }}
                label="Multiple support"
                labelPlacement="end"
            />
        </GridItem>
        <GridItem sm={6}>
            <FormControlLabel
                control={
                    <Switch
                        checked={Status.props.value}
                        onChange={Status.props.onChange}
                        value="checkedStatus"
                        classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                        }}
                        color={"primary"}
                    />
                }
                classes={{
                    label: classes.label
                }}
                label="Status"
                labelPlacement="end"
            />
        </GridItem>
        <GridItem sm={6}>
            <FormControlLabel
                control={
                    <Switch
                        checked={MacSupport.props.value}
                        onChange={MacSupport.props.onChange}
                        value="checkedMacSupport"
                        classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                        }}
                        color={"primary"}
                    />
                }
                classes={{
                    label: classes.label
                }}
                label="Mac Support"
                labelPlacement="end"
            />
        </GridItem>
        <GridItem sm={6}>
            <FormControlLabel
                control={
                    <Switch
                        checked={WinSupport.props.value}
                        onChange={WinSupport.props.onChange}
                        value="checkedWinSupport"
                        classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                        }}
                        color={"primary"}
                    />
                }
                classes={{
                    label: classes.label
                }}
                label="Win Support"
                labelPlacement="end"
            />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} className={classes.contentRight}>
            <RegularButton
                disabled={!validForm()}
                color="success"
                onClick={() => {
                    submit();
                }}
            >
                Save
            </RegularButton>
        </GridItem>
    </GridContainer>
}
PrinterForm.prototype = {
    open: PropTypes.bool,
    forEdit: PropTypes.objectOf(Printer),
    setOpenForm: PropTypes.func,
    reFresh: PropTypes.func
}
export default PrinterForm;