import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/RegularButton.js";
import userActions from "../../services/userService";
import CustomInput from "components/CustomInput/CustomInput2.js";
import { makeStyles } from "@material-ui/core/styles";
import {SHOW_APP_ALERT} from "../../action-types";

const useStyles = makeStyles({  input: {
    padding: 0,
    margin: 0,
  }});

export default function EditAppUser(props) {
  const dispatch = useDispatch();
  const classes = useState([]);
  const classesUI = useStyles();
  // eslint-disable-next-line react/prop-types
  const { id, disabled, goBack } = props;
  let [userData, setUserData] = useState({});

  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [name, setName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [clinic, setClinic] = useState("");
  const [clinicType, setClinicType] = useState("");
  const [country, setCountry] = useState("");
  const [user_id, setUserId] = useState("");
  const [language, setLanguage] = useState("");

  const getUser = async () => {
    try {
      let result = await userActions.fetchUser(id);

      userData = result.data.data.result;

      return result.data.data.result;
    } catch (err) {
      console.error(err);
    }
  };

  const submit = async () => {
    try {
      let result = await userActions.updateUser({
        user_id: user_id,
        username: userName,
        name: name,
        email: email,
        family_name: familyName,
        language: language,
        country: country,
        clinic: clinic,
        clinic_type: clinicType,
        dial_code: dialCode,
        mobile_number: mobileNumber
      });

      let response = result.data.data.result;

      let config = {
        type: "success",
        title: "Success",
        message: response,
        onConfirm: () => {goBack()}
      };
      dispatch({ type: SHOW_APP_ALERT, alertConfig: config });

      return result.data.data.result;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // Update the document title using the browser API
    async function fetchData() {
      // You can await here
      try {
        const response = await getUser();
        if (response) {
          setUserData(response);
          setEmail(response.Email);
          setUserName(response.UserId);
          setName(response.Name);
          setFamilyName(response.FamilyName);
          setDialCode(response.DialCode);
          setMobileNumber(response.MobileNumber);
          setClinic(response.Clinic);
          setClinicType(response.ClinicType);
          setCountry(response.Country);
          setLanguage(response.Language);
          setUserId(response.UserId);
        } else {
          let config = {
            type: "error",
            title: "Error",
            message: "something went wrong.please try again"
          };
          dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        }
      } catch (err) {
        console.error(err);
      }
    }

    fetchData();
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <h4 className={classes.cardTitle}>
              User ID <small> - {user_id}</small>
            </h4>
          </CardHeader>
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                className={classesUI.input}
                labelText="Name"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  floatingLabelFixed: true,
                  value: name,
                  defaultValue: " ",
                  disabled: true
                }}
                onChange={event => setName(event.target.value)}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Email"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  floatingLabelFixed: true,
                  value: email,
                  defaultValue: " ",
                  disabled: disabled
                }}
                onChange={event => setEmail(event.target.value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Family Name"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  floatingLabelFixed: true,
                  value: familyName,
                  defaultValue: " ",
                  disabled: disabled
                }}
                onChange={event => setFamilyName(event.target.value)}
              />
            </GridItem>
                <GridItem xs={12}>
              <CustomInput
                labelText="Country Code"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  floatingLabelFixed: true,
                  value: dialCode,
                  defaultValue: " ",
                  disabled: disabled
                }}
                onChange={event => setDialCode(event.target.value)}
              />
              <CustomInput
                labelText="Phone Number"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  floatingLabelFixed: true,
                  value: mobileNumber,
                  defaultValue: " ",
                  disabled: disabled
                }}
                onChange={event => setMobileNumber(event.target.value)}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Clinic"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  floatingLabelFixed: true,
                  value: clinic,
                  defaultValue: " ",
                  disabled: disabled
                }}
                onChange={event => setClinic(event.target.value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Clinic Type"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  floatingLabelFixed: true,
                  value: clinicType,
                  defaultValue: " ",
                  disabled: disabled
                }}
                onChange={event => setClinicType(event.target.value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Country"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  floatingLabelFixed: true,
                  value: country,
                  defaultValue: " ",
                  disabled: disabled
                }}
                onChange={event => setCountry(event.target.value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Language"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  floatingLabelFixed: true,
                  value: language,
                  defaultValue: " ",
                  disabled: disabled
                }}
                
                onChange={event => setLanguage(event.target.value)}
              />
            </GridItem>
              {disabled === false ?  
                <GridItem xs={12} sm={12} md={12}>
                <Button
                  color="warning"
                  onClick={() => {
                    submit();
                  }}
                >
                  Update
                </Button>
                <Button
                  onClick={() => {
                    goBack();
                  }}
                >
                  Cancel
                </Button>
             </GridItem>: null}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
