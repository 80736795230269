import * as React from "react";
import PropTypes from "prop-types";
import RegularButton from "../CustomButtons/RegularButton";

export default function AppListTabFilter({
  tabData,
  currentTab,
  changeCurrentTab,
}) {
  return tabData.map((td) => {
    console.log(currentTab);
    return (
      <RegularButton
        color={currentTab.key === td.key ? "info" : "tumblr"}
        key={td.key}
        size={"sm"}
        onClick={() => {
          changeCurrentTab(td);
        }}
      >
        {td.value}
      </RegularButton>
    );
  });
}
AppListTabFilter.propTypes = {
  tabData: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  //   currentTab: PropTypes.objectOf(
  //     PropTypes.shape({ key: PropTypes.object, value: PropTypes.string })
  //   ).isRequired,
  changeCurrentTab: PropTypes.func.isRequired,
};
