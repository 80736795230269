import React, { useEffect, useState } from "react";
import { useDispatch} from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// import Button from "@material-ui/core/Button";
import adminActions from "../../services/admin";

import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

import Spinner from '../../components/Initials/spinner.js'
import "./adminUser.css";

// core components
import {SHOW_APP_ALERT} from "../../action-types";
import TableCell from "@material-ui/core/TableCell";
import {HEADERS, STATUS_CONFIG} from "./AdminUserConfig";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Moment from "react-moment";
import Badge from "../../components/Badge/Badge";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import {Restore} from "@material-ui/icons";
import AdminUserReset from "./AdminUserReset";
import {cardTitle, grayColor} from "../../assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  table: {
    minWidth: 500,
  },
  cardTitle: {
    ...cardTitle,
    color: grayColor[0],
    float: "left"
  }
}));

export default function AdminUserList(props) {
  const dispatch = useDispatch();
  // const classes = useState([]);
  const classes = useStyles();

  let [tableData, setTableData] = useState([]);
  const [spinner, setSpinner] = useState("");
  const [resetUser, setResetUser] = useState("");

  useEffect(() => {
    loadUsers().then();
  }, []);

  const activeRoute = routeName => {
    props.history.push({
      pathname: routeName,
      params: ''
    });
  };
  const getUsers = async () => {
    setSpinner(<Spinner fetching={true}/>)
    try {
      const result = await adminActions.fetchUsers();
      setSpinner('');
      return result;
    } catch (err) {
      console.error(err);
    }
  };
  const deleteUser = async (deleteUsername) => {
    try {
      await adminActions.deleteUser( {
        username: deleteUsername
      });
      loadUsers().then();
    } catch (err) {
      console.error(err);
    }
  };
  const deleteConfirmation = id => {
    let config = {
      type: "confirmation",
      message: 'Do you want to delete this user?',
      onConfirm: () => {deleteUser(id).then()}
    };
    dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
  }
  const loadUsers = async () => {
    try {
      const response = await getUsers();
      setTableData(response.map(r => {
        return {
          Username: r.Username,
          sub: r.Attributes[0].Value,
          email: r.Attributes[1].Value,
          UserCreateDate: <Moment format={"YYYY/DD/MM"}>{r.UserCreateDate}</Moment>,
          UserStatus: <Badge color={STATUS_CONFIG[r.UserStatus]['color']}><span style={{fontSize: "8px"}}>{STATUS_CONFIG[r.UserStatus]['text']}</span></Badge>,
          action: <div>
            <IconButton onClick={() => {deleteConfirmation(r.Username)}}><DeleteIcon/></IconButton>
            <IconButton onClick={() =>   activeRoute("edit/" + r["Username"])}><Edit/></IconButton>
            <IconButton onClick={ () => setResetUser(r.Username)}><Restore/></IconButton>
          </div>,
        }
      }))
    } catch (e) {
      console.error(e);
    }
  }
  const loadTableHeaders = () => {

    return HEADERS.map((h, i) => {
      return <TableCell align={h.align} key={i}>{h.name}</TableCell>
    });
  }
  const loadRows = () => {

    return tableData.map((item, index) => {
      return <TableRow key={index}>
        {
          HEADERS.map((h, i) => {

            return <TableCell align={h.align} key={index + i}>
              {item[h.key]}
            </TableCell>
          })
        }
      </TableRow>
    })
  }

  return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <h4 className={classes.cardTitle}>
                Admin Users<small> - list</small>
              </h4>
            </CardHeader>
            <CardBody>
              {spinner}
              <GridItem xs={12} sm={12} md={12}>
                <TableContainer>
                  <Table className={classes.table} component={Paper}>
                    <TableHead>
                      <TableRow>
                        {loadTableHeaders()}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {
                        loadRows()
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </GridItem>
            </CardBody>
          </Card>
          <AdminUserReset resetUser={resetUser} setResetUser={setResetUser} />
        </GridItem>
      </GridContainer>
  );
}
