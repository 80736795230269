// @flow
import * as React from 'react';
import useInfoStore from "../useInfoStore";
import {TABLE_HEADER_CONFIG} from "../config";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
export function TableHeader() {
    return (
        <TableHead>
            <TableRow>
                {
                    TABLE_HEADER_CONFIG['DEFAULT'].map(h => {
                        return <TableCell key={h.Key} align={h.Align} variant={"head"}>{h.Name}</TableCell>;
                    })
                }
            </TableRow>
        </TableHead>

    );
};