import { put, take, fork} from "redux-saga/effects";

import Http from "../utils/Http";

import {
  GET_DEMO,
  GET_DEMO_NOT_OK,
  GET_DEMO_OK,
  SAVE_DEMO,
  SAVE_DEMO_NOT_OK,
  SAVE_DEMO_OK, SHOW_APP_ALERT,
} from "../action-types";

// function* takeFirst(pattern, saga, ...args) {
//   const task = yield fork(function*() {
//     let firstTask = true;
//     while (true) {
//       const action = yield take(pattern);
//       if (firstTask) {
//         firstTask = false;
//         yield call(saga, ...args.concat(action));
//         firstTask = true;
//       }
//     }
//   });
//   return task;
// }

const takeLast = (pattern, saga, ...args) =>
  fork(function*() {
    // let lastTask;
      const action = yield take(pattern);
      // if (lastTask) {
      //   yield cancel(lastTask); // cancel is no-op if the task has already terminated
      // }
      yield fork(saga, ...args.concat(action));

  });

const getDemo = function*() {
  console.log("api-call: Get demo file");
  const url = 'https://api2-dev.dsd-staging.xyz/user/users/123/json';
  const response = yield Http.get(url);
  console.log(response);
  if (!response.error) {
    console.log(response);
    yield put({ type: GET_DEMO_OK, payload: response});
  } else {
    const config = {
      open: true,
      title: "Error",
      message: response.info
    };
    yield put({ type: GET_DEMO_NOT_OK, alertConfig: config });
  }
};

const addDemo = function*({payload}) {
  console.log("api-call: add demo");
  const url = 'https://api2-dev.dsd-staging.xyz/user/users/123/json';
  const response = yield Http.postData2(url, {page_id: 'demo1', data: payload});
  if (response.status === 200) {
    const config = {
      type: "success",
      title: "success",
      message: "Demo page created"
    };
    yield put({ type: SHOW_APP_ALERT, alertConfig: config });
    yield put({ type: SAVE_DEMO_OK, payload: response});
  } else {
    const config = {
      open: true,
      title: "Error",
      message: response.info
    };
    yield put({ type: SAVE_DEMO_NOT_OK, alertConfig: config });
  }
};


export function* getDemoSaga() {
  yield takeLast(GET_DEMO, getDemo);
}

export function* addDemoSaga() {
  yield takeLast(SAVE_DEMO, addDemo);
}


