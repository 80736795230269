import React, {useEffect, useState} from 'react';
import {IconButton, TextField} from "@material-ui/core";
import {Done, Close, Edit} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    floatLeft: {float: "left"},
    floatRight: {float: "right"}
})
export default function SmallEditNumber({id, value, label, update, paramKey}) {
    const classes = useStyles();
    const [isEdit, setIsEdit] = useState(false)
    const [newValue, setNewValue] = useState(value)

    const close = () => {
        setIsEdit(false);
        setNewValue(value);
    }

    const startEdit = () => {
        setIsEdit(true);
    }

    const updateValues = () => {

        let param = {id};
        param[paramKey] = parseInt(newValue);
        update(param);
        setIsEdit(false);

    }

    const handleChange = (e) => {
        setNewValue(e.target.value);
    }
    return (
        <span>
            {
                isEdit
                    ? <span>
                    <TextField
                        id="outlined-helperText"
                        label={label}
                        defaultValue={newValue}
                        value={newValue}
                        onChange={handleChange}
                        variant="outlined"
                        size={"small"}
                    />
                      <div className={classes.floatRight}>
                          <IconButton size={"small"} onClick={close}><Close fontSize={"inherit"}/></IconButton>
                          <IconButton size={"small"} onClick={updateValues}><Done fontSize={"inherit"}/></IconButton>
                      </div>
                    </span>
                    :
                    <span> {label} :&nbsp; <b>{newValue}</b>&nbsp;
                        <IconButton size={"small"} onClick={startEdit}><Edit fontSize={"small"}/></IconButton>
                    </span>
            }

        </span>
    )
}