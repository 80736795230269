export const API_CALL = "API_CALL";
export const FETCH_START = "FETCH_START";
export const FETCH_END = "FETCH_END";
export const FETCHING = "FETCHING";
export const FETCH_START_OK = "FETCH_START_OK";
export const FETCH_END_OK = "FETCH_END_OK";

export const COMMIT_ALL_DETAILS = "COMMIT_ALL_DETAILS";
export const COMMIT_JAN_HAITO_DETAILS = "COMMIT_JAN_HAITO_DETAILS";
export const COMMIT_SMILE_DONATOR_DETAILS = "COMMIT_SMILE_DONATOR_DETAILS";
export const COMMIT_DETAIL = "COMMIT_DETAIL";

export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const RESET_APP = "RESET_APP";
export const LOGGED_IN = "LOGGED_IN";

export const ALLOW_NAVIGATION = "ALLOW_NAVIGATION";
export const ALLOW_NAVIGATION_OK = "ALLOW_NAVIGATION_OK";
export const ALLOW_NAVIGATION_NOT_OK = "ALLOW_NAVIGATION_NOT_OK";

export const SHOW_APP_ALERT = "SHOW_APP_ALERT";
export const SHOW_APP_ALERT_OK = "SHOW_APP_ALERT_OK";

export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_OK = "SIGN_UP_OK";
export const SIGN_UP_NOT_OK = "SIGN_UP_NOT_OK";

export const RESET_FILE_INDEX = "RESET_FILE_INDEX";
export const IMAGE_COUNT = "IMAGE_COUNT";
export const COMMIT_JAN_HAITO_TEMPLATES = "COMMIT_JAN_HAITO_TEMPLATES";
export const COMMIT_SMILE_DONATOR_TEMPLATES = "COMMIT_SMILE_DONATOR_TEMPLATES";
export const COMMIT_TYPES = "COMMIT_TYPES";

export const COMMIT_TEMPLATE = "COMMIT_TEMPLATE";
export const COMMIT_ALL_TEMPLATES = "COMMIT_ALL_TEMPLATES";
export const COMMIT_TEMPLATE_NAME_TO_STORE = "COMMIT_TEMPLATE_NAME_TO_STORE";
export const COMMIT_TEMPLATE_STATUS_TO_STORE =
  "COMMIT_TEMPLATE_STATUS_TO_STORE";
export const COMMIT_TEMPLATE_TYPE_TO_STORE = "COMMIT_TEMPLATE_TYPE_TO_STORE";

export const COMMIT_FILE = "COMMIT_FILE";
export const PREVIEW_IMAGE = "PREVIEW_IMAGE";
export const COMMIT_ACTION = "COMMIT_ACTION";

export const COMMIT_SELECTED_DETAILS_ID_TO_STORE =
  "COMMIT_SELECTED_DETAILS_ID_TO_STORE";

export const COMMIT_AVAILABLE_TUTORIAL_LANGUAGES = "COMMIT_AVAILABLE_TUTORIAL_LANGUAGES";
export const COMMIT_ALL_TUTORIAL_MODULES = "COMMIT_ALL_TUTORIAL_MODULES";


export const COMMIT_UPLOAD_FILE_TO_STORE = "COMMIT_UPLOAD_FILE_TO_STORE";
export const COMMIT_FILE_DETAILS_TO_STORE = "COMMIT_FILE_DETAILS_TO_STORE";
export const COMMIT_FILE_URL_TO_STORE = "COMMIT_FILE_URL_TO_STORE";
export const COMMIT_IMAGE_TO_STORE = "COMMIT_IMAGE_TO_STORE";

export const COMMIT_SERVER_SETTINGS = "COMMIT_SERVER_SETTINGS";
export const COMMIT_ALL_MENU_ITEMS = "COMMIT_ALL_MENU_ITEMS";

export const SAVE_DEMO = "SAVE_DEMO";
export const SAVE_DEMO_OK = "SAVE_DEMO_OK";
export const SAVE_DEMO_NOT_OK = "SAVE_DEMO_NOT_OK";
export const GET_DEMO = "GET_DEMO";
export const GET_DEMO_OK = "GET_DEMO_OK";
export const GET_DEMO_NOT_OK = "GET_DEMO_NOT_OK";
