import axios from "axios";

let dsdInstanceNoAuth = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

//on successful response
dsdInstanceNoAuth.interceptors.response.use(
    undefined, function axiosRetryInterceptor(err) {
        console.log(err);
        console.log(err.response.status);
        if (err.response.status === 403 || err.response.status === 401) {
            return window.location.href = '/#/auth/login';
        }
    }
);
export default dsdInstanceNoAuth;
