import {all} from "redux-saga/effects";
import {loginSaga, passwordResetSaga} from "./auth";
import {allowNavigationSaga, showAppAlertSaga} from "./common";
import {addDemoSaga, getDemoSaga} from "./demo";

export default function* root() {

  yield all([
    loginSaga(),
    passwordResetSaga(),
    allowNavigationSaga(),
    addDemoSaga(),
    getDemoSaga(),
    showAppAlertSaga()
  ])
}
