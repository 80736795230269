
export const GRID_CONF = {
    LAB: {l1: 9, l2: 4, l2Des:8},
    DESIGN4ME: {l1: 12, l2: 3, l2Des:6}
};

export const GRID_CONF_NEW = {
    LAB: {l1: 8, l2: 4, l2Des:12},
    DESIGN4ME: {l1: 12, l2: 3, l2Des:6}
};

export const TAB_DATA = [
    {key: "DESIGN4ME", value: "DESIGN4ME"},
    {key: "LAB", value: "LAB"}
]

export const ACTIVE_STATUS = {
    1: "Active",
    0: "Inactive"
}
export const PRODUCT_CREDITS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const PRODUCT_DATA_TYPES = {
    S3: "S3",
    DATE_TIME: "DATE_TIME",
    STRING: "STRING",
    CURRENCY: "CURRENCY",
    NUMBER: "NUMBER",
    STATUS: "STATUS",
    HTML: "HTML",
    ACTIONS: "ACTIONS",
}
export const TABLE_HEADER_CONFIG = {
    DESIGN4ME: [
        { Name: "Name", Align: "left" , Key: "Name", type: PRODUCT_DATA_TYPES.STRING},
        { Name: "Credits", Align: "left" , Key: "Credits", type: PRODUCT_DATA_TYPES.NUMBER},
        { Name: "Updated at", Align: "left" , Key: "UpdatedAt", type: PRODUCT_DATA_TYPES.DATE_TIME},
        { Name: "Status", Align: "left" , Key: "Status", type: PRODUCT_DATA_TYPES.STATUS},
        { Name: "Actions", Align: "left" , Key: "Action", type: PRODUCT_DATA_TYPES.ACTIONS},
    ],
    LAB: [
        { Name: "Image", Align: "left" , Key: "FileName", type: PRODUCT_DATA_TYPES.S3},
        { Name: "Name", Align: "left" , Key: "Name", type: PRODUCT_DATA_TYPES.STRING},
        { Name: "Description", Align: "left" , Key: "Description", type: PRODUCT_DATA_TYPES.HTML},
        { Name: "Currency", Align: "left" , Key: "Currency", type: PRODUCT_DATA_TYPES.STRING},
        { Name: "Price", Align: "left" , Key: "Price", type: PRODUCT_DATA_TYPES.CURRENCY},
        { Name: "UpdatedAt", Align: "left" , Key: "UpdatedAt", type: PRODUCT_DATA_TYPES.DATE_TIME},
        { Name: "Status", Align: "left" , Key: "Status", type: PRODUCT_DATA_TYPES.STATUS},
        { Name: "Actions", Align: "left" , Key: "Action", type: PRODUCT_DATA_TYPES.ACTIONS},

    ]
}
