import {
    ALLOW_NAVIGATION_NOT_OK,
    ALLOW_NAVIGATION_OK,
    SHOW_APP_ALERT_OK
} from "../action-types";

export default (state = {allowNavigation: false}, action) => {
    switch (action.type) {
        case SHOW_APP_ALERT_OK:
            const ac = action.alertConfig;
            return {
                ...state,
                alert: ac
            };
        case ALLOW_NAVIGATION_OK:
            return {
                ...state,
                ...action.payload
            };
        case ALLOW_NAVIGATION_NOT_OK:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};
