import dsdInstance from "./dsdInstance";
import { API_URL } from "../config/api";

export class InfoService {
  static getConfig = async () => {
    try {
      const res = await dsdInstance.get(API_URL.PRODUCT_CONFIG);
      return res.data.data.result;
    } catch (e) {
      console.error(e);
      return [];
    }
  };
  static list = async () => {
    try {
      const res = await dsdInstance.get(API_URL.INFO);
      return res.data.data.result;
    } catch (e) {
      console.error(e);
      return [];
    }
  };
  static create = async (params) => {
    try {
      const res = await dsdInstance.post(API_URL.INFO, params);
      return this.responseHandler(res);
    } catch (e) {
      console.error(e);
      return [];
    }
  };
  static update = async (params, Id) => {
    try {
      const res = await dsdInstance.put(API_URL.INFO + "/" + Id, params);
      return this.responseHandler(res);
    } catch (e) {
      console.error(e);
      return [];
    }
  };
  static delete = async (Id) => {
    try {
      const res = await dsdInstance.delete(API_URL.INFO + "/" + Id);
      return this.responseHandler(res);
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  static saveImg = async (params) => {
    try {
      const res = await dsdInstance.post(API_URL.INFO_IMAGE, params);
      return this.responseHandler(res);
    } catch (e) {
      console.error(e);
      return [];
    }
  };
  static deleteImg = async (params) => {
    try {
      const res = await dsdInstance.delete(API_URL.INFO_IMAGE, params);
      return this.responseHandler(res);
    } catch (e) {
      console.error(e);
      return [];
    }
  };
  static responseHandler = (res) => {
    console.log(res);
    if (res.status === 200) {
      return { status: true, data: res.data.data.result };
    } else {
      return { status: false, data: res.data.err };
    }
  };
}
