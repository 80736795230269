import React from 'react';
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {VerifiedUserTwoTone} from "@material-ui/icons";
import {
    cardTitle,
    warningColor,
} from "../../../assets/jss/material-dashboard-pro-react";
import RegularButton from "../../../components/CustomButtons/RegularButton";
import user from "../../../services/userService";
import {useDispatch} from "react-redux";
import {SHOW_APP_ALERT} from "../../../action-types";
const useStyles = makeStyles({
    cardTitle,
    cardTitleWhite: {
        ...cardTitle,
        color: "#FFFFFF",
        marginTop: "0"
    },
    cardCategoryWhite: {
        margin: "0",
        color: "rgba(255, 255, 255, 0.8)",
        fontSize: ".875rem"
    },
    cardDescription: {
        color: "#999999",
        marginTop: "10px"
    },
    cardCategory: {
        color: "#999999",
        marginTop: "10px"
    },
    icon: {
        color: "#333333",
        margin: "10px auto 0",
        width: "130px",
        height: "130px",
        border: "1px solid #E5E5E5",
        borderRadius: "50%",
        lineHeight: "174px",
        "& svg": {
            width: "55px",
            height: "55px"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            width: "55px",
            fontSize: "55px"
        }
    },
    iconVerify: {
        color: warningColor[2]
    },
    marginTop30: {
        marginTop: "30px"
    },
    testimonialIcon: {
        marginTop: "30px",
        "& svg": {
            width: "40px",
            height: "40px"
        }
    },
    cardTestimonialDescription: {
        fontStyle: "italic",
        color: "#999999"
    },
    img: {
        width: "auto",
        height: "auto",
        verticalAlign: "middle",
        border: "0"
    },
    avatar: {
        // borderRadius: '10px',
        // border: "solid 7px #eee",
        color: "gray"
    }});
export default function ConfirmUser(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {selected} = props;
    const confirmUser = async () => {
        const res = await user.confirmUsers(selected.email);
        if (res) {
            let config = {
                type: "success",
                message: "User confirmation success!",
                buttons: [
                    {
                        text: "Ok"
                    }
                ]
            };
            dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
            props.setSelected({...selected, UserStatus: "CONFIRMED"})
        }
    }
return <Card pricing style={{minHeight: '308px'}}>
    <CardBody pricing>
        <h3 className={classes.cardCategory}>Confirm User</h3>
        <div className={classes.icon}>
            <VerifiedUserTwoTone className={classes.iconVerify} />
        </div>
        <p className={classes.cardDescription}>
            This user is in unconfirmed state do you wish to confirm?
        </p>
        <RegularButton
            color="success"
            onClick={() => {
                confirmUser();
            }}
        >
            Yes, confirm
        </RegularButton>
    </CardBody>
</Card>
}