import React, {useEffect, useState} from 'react';
import Timeline from "../../../components/Timeline/Timeline";
import subscriptionService from "../../../services/subscriptionService";
import HistoryIcon from "@material-ui/icons/History";
import {AssistantPhoto, Edit, CloudDone} from "@material-ui/icons";
import AssignNewPlan from "./AssignNewPlan";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import stylesSweet from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import DriveHistoryBody from "../DriveHistoryBody";

const config = {
    'badgeColor': {
        2: 'info',
        1: 'success',
        'new': 'success',//'danger' // new,
        'error': 'error'
    },
    'badgeIcon': {
        0: HistoryIcon,
        1: AssistantPhoto,
        'new': CloudDone
    },
    'title' : {
        0: 'EXPIRED',
        1: 'ACTIVE',
        2: 'ALLOCATED'
    }
}
const useStyles = makeStyles({
    ...styles,
    ...stylesSweet,
    floatLeft: {float: "left"},
    floatRight: {float: "right"}
});

export default function UserDrivePlan(props) {
    const classes = useStyles();
    const {selected} = props;
    const [subscribedItems, setSubscribedItems] = useState([]);
    const [PlanList, setPlanList] = useState([]);
    const [endDate, setEndDate] = useState()
    const [alert, setAlert] = useState(null);
    useEffect(() => {
        loadPlans();
    }, []);
    useEffect(() => {
        setEndDate("");
        loadHistory();
    }, [selected])

    const loadHistory = async () => {
        const response = await subscriptionService.getDriveHistory(selected.Username, 'type=DRIVE');

        setSubscribedItems(response.data.data.result);
    }
    const loadPlans = async () => {
        const response = await subscriptionService.getPlans("&type=DRIVE");
        setPlanList(response.data.data.result.data);
    }

    const update = async (param) => {
        const r = await subscriptionService.updateDrive(selected.Username, {storage: param.storage * 1000000000})
        if (r.status === 200) {
            successAlert();
        }
    }

    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title="Success"
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Plan updated successfully!
            </SweetAlert>
        );
    };
    const loadTineStories = items => {
        let newItems = [
            {
                inverted: true,
                badgeColor: config.badgeColor['new'],
                badgeIcon: config.badgeIcon['new'],
                title: "Assign a Drive Plan",
                titleColor: config.badgeColor['new'],
                body: (''),
                footer: (
                    <AssignNewPlan PlanList={PlanList} loadHistory={loadHistory} user={selected} />
                )
            }
        ];


        items.forEach(si => {

            newItems.push({
                inverted: true,
                badgeColor: config.badgeColor[si.Status],
                badgeIcon: config.badgeIcon[si.Status],
                title: config.title[si.Status],
                titleColor: config.badgeColor[si.Status],
                body: (<div>
                    <hr/>
                    <DriveHistoryBody si={si} update={update}/>
                </div>),
            })
        });
        return newItems;
    }

    return <span>
        {alert}
        <Timeline simple stories={loadTineStories(subscribedItems)}/>
    </span>

}