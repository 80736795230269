import {useContext} from "react";
import {InfoContext} from "./InfoProvider";

export default function useInfoStore() {

    const {
        editable, changeEditable,
        formOpen, changeFormOpen,
        info, loadInfo,
        config, loadConfig
    } = useContext(InfoContext);

    return {
        editable, changeEditable,
        formOpen, changeFormOpen,
        info, loadInfo,
        config, loadConfig
    };
}