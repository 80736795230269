import React from 'react';
import {FormControlLabel, Radio} from "@material-ui/core";
import {FiberManualRecord} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import customCheckboxRadioSwitch from "../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";

const useStyles = makeStyles(
    {
        ...customCheckboxRadioSwitch,
        margin: '2px',
        infoText: {
            fontWeight: "300",
            margin: "10px 0 30px",
            textAlign: "center"
        },
        inputAdornmentIcon: {
            color: "#555"
        },
        inputAdornment: {
            position: "relative"
        }
    });
const searchOption = [
    {name: 'Email', key: 'email'},
    {name: 'Phone number', key: 'phone_number'},
    {name: 'First Name', key: 'name'},
    {name: 'Last Name', key: 'family_name'},
    {name: 'User Id', key: 'user_id'},
]
export default function SearchOptions(props) {
    const classes = useStyles();
    return (
        <GridContainer>
            {
                searchOption.map((so , i)=> {
                    return <GridItem md={12} key={i}><FormControlLabel
                        control={
                            <Radio
                                onChange={() => {
                                    props.onChange(so.key);
                                }}
                                checked={so.key === props.selected}
                                aria-label={so.name}
                                icon={
                                    <FiberManualRecord
                                        className={classes.radioUnchecked}
                                    />
                                }
                                checkedIcon={
                                    <FiberManualRecord
                                        className={classes.radioChecked}
                                    />
                                }
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot
                                }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label={so.name}
                    /></GridItem>
                })
            }
        </GridContainer>
    )
}