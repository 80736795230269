import * as React from "react";
import RegularButton from "../../../components/CustomButtons/RegularButton";
import { ArrowBack, Save } from "@material-ui/icons";
import useInfoStore from "../useInfoStore";
import CardHeader from "../../../components/Card/CardHeader";
import { Card, Divider, InputLabel, MenuItem, Select } from "@material-ui/core";
import CardBody from "../../../components/Card/CardBody";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import { useInputProps } from "../../../components/CustomInput/InputPropsHook";
import {
  ACTIVE_STATUS,
  GRID_CONF,
  GRID_CONF_NEW,
  PRODUCT_CREDITS,
  TAB_DATA,
} from "../config";
import { useEffect, useState } from "react";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputErrors from "../../../components/CustomInput/InputErrors";
import { InfoService } from "../../../services/InfoService";
import { SHOW_APP_ALERT } from "../../../action-types";
import { useDispatch } from "react-redux";
import ImageUploader from "../../../components/CustomUpload/ImageUploader";

const useStyles = makeStyles(styles);

export default function NewForm() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    editable,
    changeEditable,
    loadInfo,
    changeFormOpen,
    config,
  } = useInfoStore();
  const [newFileMac, setNewFileMac] = useState({});
  const [newFileIphone, setNewFileIphone] = useState({});
  const [newFileIpad, setNewFileIpad] = useState({});

  const [mac_images, setMacImages] = useState([]);
  const [ipad_images, setIPadImages] = useState([]);
  const [iphone_images, setIphoneImages] = useState([]);

  const [id, setId] = useState(null);

  const name = useInputProps({ type: "text", rules: ["required"] });
  const code = useInputProps({ type: "text", rules: ["required"] });
  const iphoneUrl = useInputProps({ type: "text", rules: ["required"] });
  const ipadUrl = useInputProps({ type: "text", rules: ["required"] });
  const macUrl = useInputProps({ type: "text", rules: ["required"] });
  const status = useInputProps({ type: "text", value: 1 });

  useEffect(() => {
    if (editable) {
      const { Name, URLs, FileName, Status, Id, Code } = editable;
      name.setValue(Name);
      ipadUrl.setValue(URLs.find((url) => url.device == "ipad").url);
      macUrl.setValue(URLs.find((url) => url.device == "mac").url);
      iphoneUrl.setValue(URLs.find((url) => url.device == "iphone").url);
      code.setValue(Code);
      status.setValue(Status);

      const mac_images = URLs.find((url) => url.device == "mac");
      if (mac_images.images) {
        setMacImages(mac_images.images);
      }
      const ipad_images = URLs.find((url) => url.device == "ipad");
      if (ipad_images.images) {
        setIPadImages(ipad_images.images);
      }
      const iphone_images = URLs.find((url) => url.device == "iphone");
      if (iphone_images.images) {
        setIphoneImages(iphone_images.images);
      }
      setId(Id);
    }
  }, [editable]);

  const backHandler = () => {
    changeEditable(null);
    changeFormOpen(false);
  };

  const statusSelectItems = () => {
    return Object.keys(ACTIVE_STATUS).map((key) => (
      <MenuItem
        key={key}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelectedMultiple,
        }}
        value={key}
      >
        {ACTIVE_STATUS[key]}
      </MenuItem>
    ));
  };

  const s3Upload = async (s3Url, device) => {
    let file;
    if (device == "iphone") {
      file = newFileIphone;
    }
    if (device == "ipad") {
      file = newFileIpad;
    }
    if (device == "mac") {
      file = newFileMac;
    }
    const data = new FormData();
    data.append("file", file);
    return await fetch(s3Url, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": file.type,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: file,
    });
  };

  const findRow = (row, device) => {
    return row.URLs.find((url) => url.device == device);
  };

  const submit = async () => {
    let params = {
      name: name.props.value,
      urls: [
        {
          url: ipadUrl.props.value,
          device: "ipad",
          file_name: newFileIpad ? newFileIpad["name"] : "",
        },
        {
          url: iphoneUrl.props.value,
          device: "iphone",
          file_name: newFileIphone ? newFileIphone["name"] : "",
        },
        {
          url: macUrl.props.value,
          device: "mac",
          file_name: newFileMac ? newFileMac["name"] : "",
        },
      ],
      status: status.props.value * 1,
      code: code.props.value,
    };

    let hasError = false;
    if (!editable) {
      params.urls.forEach((url) => {
        // if (!url.url && !url.file_name) {
        //   config = {
        //     type: "error",
        //     title: "Error",
        //     message: "Please select url or image for " + url.device + " device",
        //   };
        //   dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        //   hasError = true;
        // }
      });
    }

    if (hasError) {
      return;
    }

    let config = {
      type: "success",
      title: "Success",
      message: "Info saved successfully",
    };
    let res = {};
    if (editable) {
      res = await InfoService.update(params, editable.Id);
    } else {
      res = await InfoService.create(params);
    }
    if (!res["status"]) {
      config = {
        type: "error",
        title: "Error",
        message: res["data"],
      };
      dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
      return;
    }

    // for (const url of res.data.urls) {
    //   if (url.SignedURL) {
    //     await s3Upload(url.SignedURL, url.device);
    //   }
    // }
    dispatch({ type: SHOW_APP_ALERT, alertConfig: config });

    // if (res['data'].hasOwnProperty('SignedURL')) {
    //     const s3Res = await s3Upload(res.data.SignedURL);
    //     if (s3Res) {
    //         dispatch({type: SHOW_APP_ALERT, alertConfig: config});
    //     }
    // } else {
    //     dispatch({type: SHOW_APP_ALERT, alertConfig: config});
    // }
    loadInfo();
    changeEditable(null);
    changeFormOpen(false);
  };

  return (
    <GridContainer>
      <GridItem md={12} style={{ marginBottom: "18px" }}>
        <RegularButton color="tumblr" size={"sm"} onClick={backHandler}>
          <ArrowBack /> Back
        </RegularButton>
        <RegularButton
          style={{ float: "right" }}
          color="success"
          size={"sm"}
          onClick={submit}
        >
          <Save /> Save
        </RegularButton>
      </GridItem>
      <GridItem xs={4} sm={4} md={4}>
        {id ? (
          <>
            <ImageUploader id={id} device="mac" images={mac_images} />
            <ImageUploader id={id} device="iphone" images={iphone_images} />
            <ImageUploader id={id} device="ipad" images={ipad_images} />
          </>
        ) : (
          <></>
        )}
      </GridItem>

      <GridItem sm={8}>
        <Card style={{ marginTop: "30px" }}>
          <CardHeader>
            <h4 className={classes.cardTitle}>
              <b>Information</b>
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    error={!name.validity.isValid}
                    labelText="Name...*"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{ ...name.props }}
                  />
                  <InputErrors errors={name.validity.errors} />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    error={!code.validity.isValid}
                    labelText="Code...*"
                    id="code"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{ ...code.props }}
                  />
                  <InputErrors errors={name.validity.errors} />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    error={!macUrl.validity.isValid}
                    labelText="Mac URl...*"
                    id="macUrl"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{ ...macUrl.props }}
                  />
                  <InputErrors errors={macUrl.validity.errors} />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    error={!iphoneUrl.validity.isValid}
                    labelText="iPhone URl...*"
                    id="iphoneUrl"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{ ...iphoneUrl.props }}
                  />
                  <InputErrors errors={iphoneUrl.validity.errors} />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    error={!ipadUrl.validity.isValid}
                    labelText="iPad URl...*"
                    id="ipadUrl"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{ ...ipadUrl.props }}
                  />
                  <InputErrors errors={ipadUrl.validity.errors} />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="productStatus"
                      className={classes.selectLabel}
                    >
                      Status
                    </InputLabel>
                    <Select
                      // disabled = {editable ? "disabled" : ""}
                      value={status.props.value}
                      onChange={status.props.onChange}
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      inputProps={{
                        name: "productType",
                        id: "productType",
                      }}
                    >
                      {statusSelectItems()}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
