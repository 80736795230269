import React, {useEffect, useState} from 'react';
import GridContainer from "../../components/Grid/GridContainer";
import {useSelector} from "react-redux";
import templateService from "../../services/templateService";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import NavPills from "../../components/NavPills/NavPills";
import CardBody from "../../components/Card/CardBody";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {cardTitle} from "../../assets/jss/material-dashboard-pro-react";
import TabContent from "./cmps/TabContent";
const styles = {
    cardTitle,
    pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center"
    },
    cardCategory: {
        margin: "0",
        color: "#999999"
    }
};
const useStyles = makeStyles(styles);

export default function TemplateView() {
    const classes = useStyles();

    const store = useSelector(state => state.store);
    const [tabArray, setTabArray] = useState([]);

    useEffect(() => {
        if (store && store.hasOwnProperty('templateId') && store.templateId) {
            loadData(store.templateId);
        }
    }, [store]);

    const loadData = async templateId => {
       const fileDataArray = await templateService.getAllFileShadeUrls({template_id: templateId});
       const columnData = await templateService.getAllDetails();

       if (!columnData.hasOwnProperty('data')) {
           return;
       }

       let ar = {};
       fileDataArray.forEach( fileData => {
            if(ar.hasOwnProperty(fileData["DetailId"]) && fileData["IsDeleted"] === 0) {
                ar[fileData["DetailId"]]["data"].push(fileData);
            }
            else if (fileData["IsDeleted"] === 0){
                const cd = columnData.data.find(d => {
                    return d.Id === fileData["DetailId"]
                })

                ar[fileData["DetailId"]] = {
                    tabName : fileData["DetailName"],
                    data: [fileData],
                    isImage: cd.hasOwnProperty('IsImage') ? cd.IsImage === 1 : true
                }
            }
        })
        setTabArray(ar);
    }
    const loadTabs = () => {
        if (tabArray) {
            const ta = Object.keys(tabArray).map(key => {
                return {
                    tabButton: tabArray[key].tabName,
                    tabContent: <TabContent {...tabArray[key]} />,
                }
            });
            return ta;
        }
    }
    return <GridContainer>
        <GridItem md={12}>
            <Card>
                <CardHeader>
                    <h4 className={classes.cardTitle}>
                        {store.templateName} Template Overview
                    </h4>
                </CardHeader>
                <CardBody>
                    <NavPills
                        color="info"
                        tabs={loadTabs()}
                    />
                </CardBody>
            </Card>
        </GridItem>
    </GridContainer>
}