import PropTypes from "prop-types";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
    mediaParent: {
        marginTop: "5px",
        height: "100px",
        width: "100%",
        backgroundColor: "#EAEAEA",
        position: "relative",
        borderRadius: "5px"
    },
    media: {
        padding: "20px",
        margin: "auto",
        display: "block",
        maxHeight: "100%",
        maxWidth: "100%",
    }
}));

const PrinterImage = (props) =>{    
    const classes = useStyles();
    return  <div className={classes.mediaParent}>
        <img src={props.src} className={classes.media} loading="lazy" alt={'...'}/>
    </div>
}
PrinterImage.prototype = {
    src: PropTypes.string
}

export default PrinterImage;
