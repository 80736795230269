import {Dialog, DialogContent, DialogTitle, IconButton, Slide} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import modalStyle from "../../../assets/jss/material-dashboard-pro-react/modalStyle";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const DialogForm = props => {
    const classes = useStyles();
    const onCloseHandler = () => {
        props.setOpen(false);
    }
    return <Dialog
        classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal
        }}
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onCloseHandler}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
    >
        <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
        >
            <IconButton
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={onCloseHandler}
            >
                <CloseIcon/>
            </IconButton>
            <h4 className={classes.modalTitle}>{props.title}</h4>
        </DialogTitle>
        <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
        >
            {props.children}
        </DialogContent>
    </Dialog>
}

DialogForm.prototype = {
    title: PropTypes.string,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default DialogForm;