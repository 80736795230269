import dsdInstance from "./dsdInstance";

//Can put token function in here and use it instead of repeating it in every component.

const postTutorial = (data) => {
  return dsdInstance.post("/tutorial/create", data);
};

const editTutorial = (data) => {
  return dsdInstance.post("/tutorial/edit", data);
};

const getAllTutorials = () => {
  return dsdInstance.get("/tutorial/getAll");
};

const getTutorial = data => {
  return dsdInstance.get("/tutorial/get/" + data);
};

const getAllTutorialLanguages = () => {
  return dsdInstance.get("/tutorial/getLanguages");
};

const deleteTutorial = data => {
  return dsdInstance.delete("/tutorial/delete/" + data);
};


export default {
  postTutorial: postTutorial,
  editTutorial: editTutorial,
  getAllTutorials: getAllTutorials,
  getAllTutorialLanguages: getAllTutorialLanguages,
  getTutorial: getTutorial,
  deleteTutorial: deleteTutorial
};
