import * as React from 'react';
import {Fab, Zoom} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {cyan, green, lightBlue} from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
        position: 'relative',
        minHeight: 200,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    green: {
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        },
    },
    blue: {
        color: theme.palette.common.white,
        backgroundColor: lightBlue[500],
        '&:hover': {
            backgroundColor: lightBlue[600],
        },
    },
    cyan: {
        color: theme.palette.common.white,
        backgroundColor: cyan[500],
        '&:hover': {
            backgroundColor: cyan[600],
        },
    }
}));
export function AppFloatActionByn({color, onClick}) {
    const classes = useStyles();

    return (
        <Zoom
            in={true}
            style={{ transitionDelay: '500ms' }}
            unmountOnExit
            timeout={{enter: 500, exit: 500}}
        >
                <Fab aria-label={"add"} className={classes[color]} size={"small"} onClick={onClick}>
                    <Add/>
            </Fab>
        </Zoom>
    );
};