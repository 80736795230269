import {useContext} from "react";
import {ProductContext} from "./ProductProvider";

export default function useProductStore() {

    const {
        editable, changeEditable,
        formOpen, changeFormOpen,
        tab, changeTab,
        products, loadProducts,
        config, loadConfig
    } = useContext(ProductContext);

    return {
        editable, changeEditable,
        formOpen, changeFormOpen,
        tab, changeTab,
        products, loadProducts,
        config, loadConfig
    };
}