import React, {useEffect, useState} from 'react';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import {makeStyles} from "@material-ui/core/styles";
import {cardTitle} from "../../assets/jss/material-dashboard-pro-react";
import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";
import {useInputProps} from "../../components/CustomInput/InputPropsHook";
import CustomInput from "../../components/CustomInput/CustomInput";
import InputErrors from "../../components/CustomInput/InputErrors";
import {FormControlLabel, InputLabel, MenuItem, Select, Switch} from "@material-ui/core";
import RegularButton from "../../components/CustomButtons/RegularButton";
import CardIcon from "../../components/Card/CardIcon";
import {Add} from "@material-ui/icons";
import plansService from "../../services/plansService";
import {useParams, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {SHOW_APP_ALERT} from "../../action-types";
import FormControl from "@material-ui/core/FormControl";
import {KEY, PLANE_TYPES, PRICE_PLANS} from "./PlanConfig";

const useStyles = makeStyles((theme) => ({
    cardTitle,
    ...styles,
    ...buttonStyle,
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

export default function PlanForm() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const {id} = useParams();
    const [isEdit, setIsEdit] = useState(false);
    useEffect(() => {
        if (id) {
            setIsEdit(true)
            loadFromApi(id);
        }
    }, [id])

    const formInputs = {
        planType: useInputProps({type: "select", rules: ["required"]}),
        name: useInputProps({type: "text", rules: ["required"]}),
        primary_cost: useInputProps({type: "number", rules: ["required"], value: 0}),
        secondary_cost: useInputProps({type: "number", rules: ["required"], value: 0}),
        days: useInputProps({type: "number", rules: ["required"]}),
        description: useInputProps({type: "text"}),
        stripe_id: useInputProps({type: "text"}),
        status: useInputProps({type: "radio", rules: ["required"], value: true}),
        is_trial: useInputProps({type: "radio", rules: ["required"], value: false}),
        designs: useInputProps({type: "number", rules: ["required"], value: ""}),
        pricePlan: useInputProps({type: "select", rules: ["required"], value: ""}),
        orders: useInputProps({type: "number", rules: ["required"], value: ""}),
        storage: useInputProps({type: "number", rules: ["required"], value: ""})
    }

    const loadFromApi = async id => {
        const r = await plansService.get(id);
        const initData = r[0];
        formInputs.planType.setValue(KEY.TYPE_PRIMARY);

        if (initData.hasOwnProperty("Recurring")) {
                formInputs.pricePlan.setValue(initData.Recurring);
        }
        else if (initData.hasOwnProperty("OneTime")) {
                formInputs.pricePlan.setValue("onetime");
        }

        formInputs.planType.setValue(initData.hasOwnProperty("Type") ? initData.Type : KEY.TYPE_PRIMARY);
        formInputs.name.setValue(initData.hasOwnProperty("Title") ? initData.Title : "");
        formInputs.primary_cost.setValue(initData.hasOwnProperty("PrimaryCost") ? initData.PrimaryCost : "");
        formInputs.secondary_cost.setValue(initData.hasOwnProperty("SecondaryCost") ? initData.SecondaryCost : "");
        formInputs.days.setValue(initData.hasOwnProperty("Days") ? initData.Days : "");
        formInputs.description.setValue(initData.hasOwnProperty("Description") ? initData.Description : "");
        formInputs.stripe_id.setValue(initData.hasOwnProperty("StripeId") ? initData.StripeId : "");
        formInputs.status.setValue(initData.hasOwnProperty("Status") ? initData.Status === 1 : "");
        formInputs.is_trial.setValue(initData.hasOwnProperty("IsTrial") ? initData.IsTrial === 1 : "");
        formInputs.designs.setValue(initData.hasOwnProperty("Designs") ? initData.Designs : "");
        formInputs.orders.setValue(initData.hasOwnProperty("DCatOrders") ? parseInt(initData.DCatOrders) : "0")
        formInputs.storage.setValue(initData.hasOwnProperty("Storage") ? parseInt(initData.Storage/1000000000) : "0")

    }

    const submit = async () => {
        let valid = true;
        let param = {};
        Object.keys(formInputs).map(key => {
            if ((formInputs.planType.props.value === KEY.TYPE_DESIGN4ME && key != "storage" && key != "orders") || 
                (formInputs.planType.props.value == KEY.TYPE_PRIMARY && key !== "designs" && key != "pricePlan" && key != "storage" && key != "orders") ||
                (formInputs.planType.props.value == KEY.TYPE_DCAT && key !== "designs" && key != "pricePlan" && key != "storage") ||
                (formInputs.planType.props.value == KEY.TYPE_DRIVE && key !== "designs" && key != "pricePlan" && key != "orders")) {
                const v = formInputs[key].doValidate();
                if (valid) {
                    valid = v;
                }
                else {
                    console.error(key)
                }
            }
            const value = formInputs[key]["props"]["value"];
            switch (key) {
                case "designs":
                case "primary_cost":
                case "secondary_cost":
                case "days":
                    param[key] = parseInt(value);
                    break;
                case "status":
                case "is_trial":
                    param[key] = value ? 1 : 0;
                    break;
                case "pricePlan":
                    if (value === "onetime") {
                        param["one_time"] = 1;
                    }
                    else {
                        param["one_time"] = 0;
                        param["recurring"] = value;
                    }
                case "planType":
                    if (value === KEY.TYPE_DESIGN4ME) {
                        param["type"] = KEY.TYPE_DESIGN4ME
                    } else if (value === KEY.TYPE_DCAT) {
                        param["type"] = KEY.TYPE_DCAT
                    } else if (value === KEY.TYPE_DRIVE) {
                        param["type"] = KEY.TYPE_DRIVE
                    }
                case "orders":
                    param["dcat_orders"] =value? parseInt(value): 0;
                    break;
                case "storage":
                    param["storage"] =value? parseInt(value) * 1000000000 :0;
                    break;
                default:
                    param[key] = value

            }
        });
        if (valid) {

            if (param.type !== KEY.TYPE_DESIGN4ME) {
                delete param.recurring;
                delete param.one_time;
                delete param.designs;
            }
            if (param.type == KEY.TYPE_DCAT) {
                delete param.recurring;
                delete param.one_time;
                delete param.designs; 
                delete param.storage;
            }
            if (param.type == KEY.TYPE_DRIVE) {
                delete param.recurring;
                delete param.one_time;
                delete param.designs; 
                delete param.dcat_orders;
            }
            delete param.pricePlan;
            if (id) {
                param.id = id;
                const r = await plansService.putPlans(param)
                if (r) {
                    dispatch({
                        type: SHOW_APP_ALERT, alertConfig: {
                            type: "success",
                            message: "Plan updated successfully!",
                            onConfirm: successOk
                        }
                    })
                }

            } else {
                const r = await plansService.postPlans(param)
                if (r) {
                    dispatch({
                        type: SHOW_APP_ALERT, alertConfig: {
                            type: "success",
                            message: "Plan created successfully!",
                            onConfirm: successOk
                        }
                    })
                }
            }

        }
    }

    const successOk = () => {
        history.push({pathname: "/admin/plans-list"});
    }

    const loadPricePlanSelectItems = () => {
        return PRICE_PLANS.map(pt => {
            return (
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple
                    }}
                    value={pt.key}
                >
                    {pt.display}
                </MenuItem>
            )
        })
    }
    const loadPlanSelectItems = () => {
        return PLANE_TYPES.map(pt => {
            return (
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple
                    }}
                    value={pt.key}
                >
                    {pt.display}
                </MenuItem>
            )
        })
    }
    const design4meInputs = () => {
        return <div>

        </div>
    }
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="info">
                            <Add/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Plan Form</h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="templateType"
                                        className={classes.selectLabel}
                                    >
                                        Plan type
                                    </InputLabel>
                                    <Select
                                        value={formInputs.planType.props.value}
                                        onChange={formInputs.planType.props.onChange}
                                        MenuProps={{className: classes.selectMenu}}
                                        classes={{select: classes.select}}
                                        inputProps={{
                                            name: "planType",
                                            id: "planType"
                                        }}
                                        disabled={isEdit}
                                    >
                                        {loadPlanSelectItems()}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                    error={!formInputs.name.validity.isValid}
                                    labelText="NAME...*"
                                    id="mName"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{...formInputs.name.props}}
                                />
                                <InputErrors errors={formInputs.name.validity.errors}/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                    error={!formInputs.description.validity.isValid}
                                    labelText="DESCRIPTION"
                                    id="mDescription"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{...formInputs.description.props}}
                                />
                                <InputErrors errors={formInputs.description.validity.errors}/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                    error={!formInputs.primary_cost.validity.isValid}
                                    labelText="PRIMARY COST...*"
                                    id="mPrimaryCost"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{...formInputs.primary_cost.props}}
                                />
                                <InputErrors errors={formInputs.primary_cost.validity.errors}/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                    error={!formInputs.secondary_cost.validity.isValid}
                                    labelText="SECONDARY COST cost...*"
                                    id="mPrimaryCost"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{...formInputs.secondary_cost.props}}
                                />
                                <InputErrors errors={formInputs.secondary_cost.validity.errors}/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                    error={!formInputs.days.validity.isValid}
                                    labelText="DAYS...*"
                                    id="mPrimaryCost"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{...formInputs.days.props}}
                                />
                                <InputErrors errors={formInputs.days.validity.errors}/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                    error={!formInputs.stripe_id.validity.isValid}
                                    labelText="STRIPE ID"
                                    id="mPrimaryCost"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{...formInputs.stripe_id.props}}
                                />
                                <InputErrors errors={formInputs.stripe_id.validity.errors}/>
                            </GridItem>
                            {
                                formInputs.planType.props.value === KEY.TYPE_DESIGN4ME ?
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            error={!formInputs.designs.validity.isValid}
                                            labelText="NO. of DESIGNS...*"
                                            id="noOfDesigns"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{...formInputs.designs.props}}
                                        />
                                        <InputErrors errors={formInputs.designs.validity.errors}/>
                                    </GridItem> : ""
                            }
                            {
                                formInputs.planType.props.value === KEY.TYPE_DESIGN4ME ?
                                    <GridItem xs={12} sm={12} md={4}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="pricePlan"
                                                className={classes.selectLabel}
                                            >
                                                Price Plan
                                            </InputLabel>
                                            <Select
                                                value={formInputs.pricePlan.props.value}
                                                onChange={formInputs.pricePlan.props.onChange}
                                                MenuProps={{className: classes.selectMenu}}
                                                classes={{select: classes.select}}
                                                inputProps={{
                                                    name: "pricePlan",
                                                    id: "pricePlan"
                                                }}
                                            >
                                                {loadPricePlanSelectItems()}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    : ""
                            }
                            {
                                formInputs.planType.props.value === KEY.TYPE_DCAT ?
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            error={!formInputs.orders.validity.isValid}
                                            labelText="NO. of ORDERS...*"
                                            id="noOfOrders"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{...formInputs.orders.props}}
                                        />
                                        <InputErrors errors={formInputs.orders.validity.errors}/>
                                    </GridItem> : ""
                            }
                            {
                                formInputs.planType.props.value === KEY.TYPE_DRIVE ?
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            error={!formInputs.storage.validity.isValid}
                                            labelText="STORAGE (GBs)...*"
                                            id="storage"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{...formInputs.storage.props}}
                                        />
                                        <InputErrors errors={formInputs.storage.validity.errors}/>
                                    </GridItem> : ""
                            }
                            <GridItem xs={12} sm={12} md={2}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formInputs.status.props.value}
                                            onChange={formInputs.status.props.onChange}
                                            value="checkedA"
                                            classes={{
                                                switchBase: classes.switchBase,
                                                checked: classes.switchChecked,
                                                thumb: classes.switchIcon,
                                                track: classes.switchBar
                                            }}
                                            color={"primary"}
                                        />
                                    }
                                    classes={{
                                        label: classes.label
                                    }}
                                    label="Status"
                                    labelPlacement="top"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={2}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formInputs.is_trial.props.value}
                                            onChange={formInputs.is_trial.props.onChange}
                                            value="checkedA"
                                            classes={{
                                                switchBase: classes.switchBase,
                                                checked: classes.switchChecked,
                                                thumb: classes.switchIcon,
                                                track: classes.switchBar
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label
                                    }}
                                    label="IS TRIAL"
                                    labelPlacement="top"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <RegularButton
                                    className={classes.floatRight}
                                    color="success"
                                    onClick={() => {
                                        submit();
                                    }}
                                >
                                    Save
                                </RegularButton>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}