import dsdInstance from "./dsdInstance";
import axios from "axios";
const extName = require('ext-name');

const saveFileData = async (data, onUploadProgress) => {
    return await dsdInstance.post("/template/saveFileData", data, {onUploadProgress});
};

const getFromS3 = async url => {
    return await axios.get(url);
}

const putToS3 = async (url, data, onUploadProgress) =>{
    let headerType;
    let type = extName(data.name);
    if (type.length === 0){
        headerType='model/ply'
    }
    else if (type[0].ext === "obj"){
        headerType = 'model/obj'
    }
    else{
        headerType=type[0].mime;
    }
    return  await axios.put(url, data, {headers: {'Content-Type': headerType}, onUploadProgress});
}

const getUploadURL = async data => {
    const r = await dsdInstance.post("/template/getUploadURL", data);
    if (
        r.hasOwnProperty('data') &&
        r.data.hasOwnProperty('data') &&
        r.data.data.hasOwnProperty('result') &&
        r.data.data.result
    ) {
        return r.data.data.result;
    }
    else {
        console.error('/template/getUploadURL not returning expected result');
        return '';
    }
};

const putFileDetail = async data =>{
    const r = await dsdInstance.put("/template/editFileData/", data);
    if (
        r.status === 200
    ) {
        return {type: 'success', message: data.is_deleted === 1 ? 'File Deleted Successfully!' : 'File Updated Successfully!'};
    }
    else {
        console.error('/template/getAllFileShades not returning expected result');
        return {type: 'error', message: 'Something went wrong'};
    }
};

const getAllFileShades = async data => {
    const r = await dsdInstance.post("/template/getAllFileShades", data);
    if (
        r.hasOwnProperty('data') &&
        r.data.hasOwnProperty('data') &&
        r.data.data.hasOwnProperty('result') &&
        r.data.data.result
    ) {
        return r.data.data.result;
    }
    else {
        console.error('/template/getAllFileShades not returning expected result');
        return [];
    }
};
const getAllDetails = async () => {
    const r = await dsdInstance.get("/template/getAllDetails");
    if (
        r.hasOwnProperty('data') &&
        r.data.hasOwnProperty('data') &&
        r.data.data.hasOwnProperty('result') &&
        r.data.data.result
    ) {
        return r.data.data.result;
    }
    else {
        console.error('/template/getAllDetails not returning expected result');
        return [];
    }
};
const getAllFileShadeUrls = async data => {
    const r = await dsdInstance.post("/template/getAllFileShadeUrls", data);
    try {
        return r.data.data.result;
    }
    catch (e) {
        console.error(e);
        return [];
    }
};
export default {
    getAllFileShades: getAllFileShades,
    getAllDetails: getAllDetails,
    putFileDetail: putFileDetail,
    getUploadURL: getUploadURL,
    putToS3: putToS3,
    getFromS3: getFromS3,
    saveFileData: saveFileData,
    getAllFileShadeUrls: getAllFileShadeUrls,
}