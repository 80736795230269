import { Icon, IconButton, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export default function FileUploader({ setFiles }) {
  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles) {
      setFiles(acceptedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Icon>add_circle</Icon>
    </div>
  );
}
