import React, { useEffect, useState, componentDidMount } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import PersonIcon from '@material-ui/icons/Person';
// core components
import defaultImage from "assets/img/image_placeholder.jpg";
import { connect } from "react-redux";
import { useDispatch} from "react-redux";
import adminActions from "../../services/admin";
import { LazyLoadImage } from "react-lazy-load-image-component";


function ImageForTable(props) {
  const dispatch = useDispatch();
  const { templateId} = props;
  const [source, setSource] = useState(defaultImage);

  const getToken = () => {
    let token = localStorage.getItem("idToken");
    return token;
  };

  const changeAlmostToFinal = async almostResult => {
    let finalResult = "data:image/png;base64," + almostResult;
    return finalResult;
  };

  const setImageFromID = async (templateIdFromProp) => {

    let result = await adminActions
    .getImageSignedForTable(templateIdFromProp).then(result =>{
        return result;
    }).then(result =>{
        if (result.data.data.result == "no image found"){
          return
        }
        setSource(result.data.data.result);
    })

};

  useEffect(() => {
    // Write functions to execute on page-load.
    if (templateId){
        setImageFromID(templateId);
    }
    
  }, []);
  
  return (
            <img
            style={{backgroundColor:"#e6e6e6"}}
            className="imageForTableWrapper"
            src={source ? source : null}
            alt="..."
            />
  );
}

const mapStateToProps = state => {
  return {
    selectedDetailsID: state.store.selectedDetailsID,
    fileURL: state.store.fileURL
  };
};

const wrapperFunction = connect(mapStateToProps);
const MemoizedImageForTableComponent = React.memo(ImageForTable);

export default wrapperFunction(MemoizedImageForTableComponent);

ImageForTable.propTypes = {
    templateId: PropTypes.string,
    scrollPosition: PropTypes.object,

};
