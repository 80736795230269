import React, {useEffect, useState} from 'react';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import {makeStyles} from "@material-ui/core/styles";
import plansService from "../../services/plansService";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Badge from "../../components/Badge/Badge";
import {IconButton} from "@material-ui/core";
import {EditRounded} from "@material-ui/icons";
import RegularButton from "../../components/CustomButtons/RegularButton";
import {HEADERS, KEY, PLANE_TYPES, PRICE_PLANS, STATUS_CONFIG, TRIAL_MAP} from "./PlanConfig";
import {PlanStyles} from "./PlanStyles";

const useStyles = makeStyles(PlanStyles);

export default function PlanList(props) {
    const classes = useStyles();
    const [primaryItems, setPrimaryItems] = useState([]);
    const [design4MeItems, setDesign4MeItems] = useState([]);
    const [dCATItems, setDCATItems] = useState([]);
    const [driveItems, setDriveItems] = useState([]);
    const [currentType, setCurrentType] = useState(KEY.TYPE_PRIMARY);

    useEffect(() => {
        loadPlans();
    }, [currentType]);

    const loadPlans = async () => {
        const plans = await plansService.getAll(currentType === KEY.TYPE_PRIMARY ? "" : currentType);
        if (currentType === KEY.TYPE_DESIGN4ME && design4MeItems.length === 0) {
            setDesign4MeItems(plans);
        }
        else if (currentType === KEY.TYPE_DCAT && dCATItems.length === 0) {
            setDCATItems(plans);
        }
        else if (currentType === KEY.TYPE_DRIVE && driveItems.length === 0) {
            setDriveItems(plans);
        }
        else if (currentType === KEY.TYPE_PRIMARY && primaryItems.length === 0) {
            setPrimaryItems(plans);
        }
    }
    const loadTableHeaders = () => {

        return HEADERS.map((h, i) => {
            if (h.hasOwnProperty("hideFrom") && h.hideFrom === currentType) {
                return ;
            }

            if (h.hasOwnProperty("type") && h.type != currentType) {
                return;
            }
            return <TableCell align={h.align} key={i}>{h.name}</TableCell>
        });
    }
    const goToEdit = item => {
        localStorage.setItem("store", JSON.stringify({"action": "planEdit", "data": item}))
        props.history.push({pathname: 'plans-edit/' + item.Id, params: ""});
    }
    const formatCellValues = (h, item) => {
        switch (h.key) {
            case "Status":
                return <Badge
                    color={STATUS_CONFIG[item[h.key]] ? STATUS_CONFIG[item[h.key]]["color"] : ""}>{STATUS_CONFIG[item[h.key]] ? STATUS_CONFIG[item[h.key]]["text"] : ""}</Badge>;
            case "PrimaryCost":
            case "SecondaryCost":
                return '$ ' + item[h.key] + '.00';
            case "IsTrial":
                return TRIAL_MAP[item[h.key]];
            case "action":
                return <IconButton size={"small"} onClick={() => {
                    goToEdit(item)
                }}>
                    <EditRounded fontSize={"small"}/>
                </IconButton>
            case "Storage":
                return (item[h.key]/1000000000 + ' GB');
            case "Designs":
                return item[h.key];
            case "pricePlane":
                if (item.hasOwnProperty("OneTime") && item.OneTime === 1) {
                    return "Onetime"
                }
                if (item.hasOwnProperty("Recurring")) {
                    const conf = PRICE_PLANS.find(d => {
                        return d.key === item.Recurring;
                    })
                    return conf && conf.display ? conf.display : ""
                }

                break;
            default:
                return item[h.key]
        }
    }
    const loadRows = () => {
        let items = [...primaryItems];
        if (currentType === KEY.TYPE_DESIGN4ME) {
            items = [...design4MeItems];
        } else if (currentType === KEY.TYPE_DCAT) {
            items = [...dCATItems];
        } else if (currentType === KEY.TYPE_DRIVE) {
            items = [...driveItems];
        }
        return items.map((item, index) => {
            return <TableRow key={index}>
                {
                    HEADERS.map((h, i) => {
                        if (h.hasOwnProperty("hideFrom") && h.hideFrom === currentType) {
                            return ;
                        }
                        if (h.hasOwnProperty("type") && h.type != currentType) {
                            return;
                        }
                        return <TableCell align={h.align} key={index + i}>
                            {formatCellValues(h, item)}
                        </TableCell>
                    })
                }
            </TableRow>
        })
    }
    const loadTypeButtons = () => {
        return PLANE_TYPES.map(pt => {
            return <RegularButton
                color={currentType === pt.key ? 'info' : ''}
                key={pt.key}
                size={"sm"}
                onClick={() => {setCurrentType(pt.key)}}
            >
                {pt.display}
            </RegularButton>
        })
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader>
                        <h4 className={classes.cardTitle}>Plan list</h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                {
                                    loadTypeButtons()
                                }
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <TableContainer>
                                    <Table className={classes.table} component={Paper}>
                                        <TableHead>
                                            <TableRow>
                                                {loadTableHeaders()}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {
                                                loadRows()
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}