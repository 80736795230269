import * as React from 'react';
import useProductStore from "../useProductStore";
import {ACTIVE_STATUS, PRODUCT_DATA_TYPES, TABLE_HEADER_CONFIG} from "../productConfig";
import {Chip, TableRow} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Moment from "react-moment";
import {ProductImage} from "./ProductImage";
import IconButton from "@material-ui/core/IconButton";
import {Delete, Edit} from "@material-ui/icons";
import {SHOW_APP_ALERT} from "../../../action-types";
import {useDispatch} from "react-redux";
import {ProductService} from "../../../services/ProductService";

type Props = {

};

export default function ProductTableBody() {
    const dispatch = useDispatch();

    const {tab, products, changeEditable, changeFormOpen, loadProducts} = useProductStore();

    const editHandler = p => {
        console.log(p)
        changeEditable(p);
        changeFormOpen(true);
    }
    const cellSwitch = (th, prod) => {
        switch (th.type) {
            case PRODUCT_DATA_TYPES.STRING:
                return prod[th.Key];
            case PRODUCT_DATA_TYPES.DATE_TIME:
                return <Moment format={"YYYY-MM-DD HH:MM:SS"}>{prod[th.Key]}</Moment>
            case PRODUCT_DATA_TYPES.S3:
                return <ProductImage product={prod}/>
            case PRODUCT_DATA_TYPES.STATUS:
                return <Chip variant="outlined" size="small" label={ACTIVE_STATUS[prod[th.Key]]}/>
            case PRODUCT_DATA_TYPES.ACTIONS:
                return <>
                    <IconButton size={"small"} onClick={() => {editHandler(prod)}}><Edit/></IconButton>
                    <IconButton size={"small"} onClick={() => deleteConfirmation(prod)}><Delete/></IconButton>
                </>
            case PRODUCT_DATA_TYPES.HTML:
                return <div dangerouslySetInnerHTML={{__html:prod[th.Key]}}/>
            default:
                return prod[th.Key];
        }
    }

    const loadRaws = () => {
        console.log("loadRaws", products)
        if (products && products[tab.key] && products[tab.key]) {
            return products[tab.key].map(prod => {
                return <TableRow key={prod.Id}>
                    {
                        TABLE_HEADER_CONFIG[tab.key].map(th => <TableCell key={th.Key + prod.Id}>{cellSwitch(th, prod)}</TableCell>)
                    }
                </TableRow>
            })
        } else {
            return <TableRow><TableCell colSpan={TABLE_HEADER_CONFIG[tab.key].length}></TableCell></TableRow>;
        }
    }
    const deleteProduct = async id => {
        await ProductService.delete(id)
        let config = {
            type: "success",
            message: 'Product deleted successfully',
        };
        dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        loadProducts()
    }
    const deleteConfirmation = product => {
        let config = {
            type: "confirmation",
            message: 'Do you want to delete the ' + product.Name + ' ?',
            onConfirm: () => deleteProduct(product.Id)
        };
        dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
    }
    return <TableBody>
        {loadRaws()}
    </TableBody>
}