import {useInputProps} from "../../components/CustomInput/InputPropsHook";

export default class Printer {

    constructor(inti= {}) {
        this.ArgsMac = inti.ArgsMac ? inti.ArgsMac : "";
        this.ArgsWin = inti.ArgsWin ? inti.ArgsWin : "";
        this.CommandMac = inti.CommandMac ? inti.CommandMac : "";
        this.CommandWin = inti.CommandWin ? inti.CommandWin : "";
        this.CreatedAt = inti.CreatedAt ? inti.CreatedAt : "";
        this.CreatedBy = inti.CreatedBy ? inti.CreatedBy : "";
        this.Id = inti.Id ? inti.Id : "";
        this.ImageFile = inti.ImageFile ? inti.ImageFile : "";
        this.LogoFile = inti.LogoFile ? inti.LogoFile : "";
        this.MultiSupport = inti.MultiSupport ? inti.MultiSupport : "";
        this.Name = inti.Name ? inti.Name : "";
        this.Status = inti.Status ? inti.Status : "";
        this.UpdatedAt = inti.UpdatedAt ? inti.UpdatedAt : "";
        this.UpdatedBy = inti.UpdatedBy ? inti.UpdatedBy : "";
        this.MacSupport = inti.MacSupport ? inti.MacSupport : 0;
        this.WinSupport = inti.WinSupport ? inti.WinSupport : 0;
    }

    inputName = () => {
        return useInputProps({"type": "text", rules: ["required"]})
    }
}