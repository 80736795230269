import { connect } from "react-redux";
import React, { useEffect, useState, getState, Suspense } from "react";
import { useDispatch } from "react-redux";
import TutorialComponent from "./tutorialComponent";



function TutorialView(props) {
  let dispatch = useDispatch();
  let classes = useState([]);
  const [update, setUpdate] = useState(false);
  
  // eslint-disable-next-line react/prop-types
  var uniqid = require('uniqid');

  const shouldUpdate = () =>{
    if (update===false){
      setUpdate(true);
    }
    setUpdate(false);
  }

  useEffect(() => {

  }, []);

  return (
    <TutorialComponent shouldUpdate={shouldUpdate} />
  );
}

const mapStateToProps = state => {

  return {
//Map Redux values to props here in the form of an object
  };
};

const wrapperFunction = connect(mapStateToProps);
const finalTutorialComponent = wrapperFunction(TutorialComponent);

export default TutorialView;
