import React, {useState} from 'react';
import Card from "../../../components/Card/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardHeader from "../../../components/Card/CardHeader";
import {CardActions, CardContent} from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import {red} from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import {CloudDownload, CloudUpload, Delete} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {SHOW_APP_ALERT} from "../../../action-types";
import templateService from "../../../services/templateService";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import TemplateFileView from "./TemplateFileView";

const useStyles = makeStyles((theme) => ({
    ...styles,
    root: {
        maxWidth: 345,
    },
    '&:hover': {
        color: "rgb(128 128 128)"
    },
    media: {
        padding: "20px 20px 20px 20px",
        margin: "auto",
        display: "block",
        maxHeight: "100%",
        maxWidth: "100%",
    },
    mediaParent: {
        height: "150px",
        width: "100%",
        backgroundColor: "#EAEAEA",
        position: "relative"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    cardContent: {
        textAlign: "center"
    },
    cardActions: {
        textAlign: "right",
    },
    cardActionsButtons: {
        marginLeft: 'auto',
    },
    progressBar: {width: "100%", display: "inline-block"}
}));


export default function ImgComponent(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {FileLabel, NoImage, FileIndex, Id} = props.item;
    const {isImage} = props;
    const {templateId, selectedDetailsID} = useSelector(state => state.store);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleDownload = async () => {
        try {
            await templateService.getFromS3(props.item.URL);
        } catch (err) {
            console.error(err);
        }
    }
    const handleUploadFile = async (event) => {
        let newFile = event.target.files[0];
        let newFileName = event.target.files[0].name;
        try {
            let templateForAPI = {
                template_id: templateId,
                detail_id: selectedDetailsID,
                file_name: newFileName,
                file_label: FileLabel,
                file_index: FileIndex.toString()
            };
            let reader = new FileReader();
            if (isImage || isImage == undefined) {
                reader.readAsDataURL(newFile);
                reader.onloadend = async () => {
                    templateForAPI['file'] = reader.result.split("base64,")[1];
                    const r = await templateService.saveFileData(templateForAPI, (event) => {
                        setUploadProgress(Math.round((100 * event.loaded) / event.total));
                    });
                    fileUploadSuccess(r);
                }
            } else {
                let s3Url = await templateService.getUploadURL(templateForAPI);
                if (s3Url !== '') {
                    reader.readAsDataURL(newFile);
                    reader.onloadend = async () => {
                        const r = await templateService.putToS3(s3Url, newFile, (event) => {
                            setUploadProgress(Math.round((100 * event.loaded) / event.total));
                        });
                        fileUploadSuccess(r);
                    }
                } else {
                    let config = {
                        type: 'error',
                        message: "Can't get upload URL"
                    };
                    dispatch({type: SHOW_APP_ALERT, alertConfig: config});
                }

            }

        } catch {
            console.error("FIle upload failed")
        }
    }
    const fileUploadSuccess = r => {
        if (r && r.hasOwnProperty('status') && r.status === 200) {
            let config = {
                type: 'success',
                title: "Success",
                message: "File uploaded successfully"
            };
            dispatch({type: SHOW_APP_ALERT, alertConfig: config});
            props.refresh();
        } else {
            let config = {
                type: 'error',
                title: "Error",
                message: "Something went wrong!"
            };
            dispatch({type: SHOW_APP_ALERT, alertConfig: config});
            props.refresh();
        }
    }
    const deleteTemplate = async id => {
        let result = await templateService.putFileDetail({
            file_id: id,
            is_deleted: 1
        });
        dispatch({type: SHOW_APP_ALERT, alertConfig: result});
        if (result.type === 'success') {
            props.refresh();
        }
    }
    const deleteConfirmation = id => {
        let config = {
            type: 'confirmation',
            message: "Do you want to delete this template?",
            onConfirm: () => {
                deleteTemplate(id)
            }
        };
        dispatch({type: SHOW_APP_ALERT, alertConfig: config});
    }
    return <Card className={classes.root}>
        <CardHeader>
            <h4 className={classes.cardTitle}>{FileLabel}</h4>
        </CardHeader>
        <CardContent className={classes.cardContent}>

            <div className={classes.mediaParent}>
                <TemplateFileView
                    IsImage={isImage}
                    NoImage={NoImage}
                    item={props.item}
                />
            </div>

            {
                (uploadProgress > 0 && uploadProgress < 100)
                    ? <CustomLinearProgress
                        variant="determinate"
                        color="success"
                        value={uploadProgress}
                        className={classes.progressBar}
                    />
                    : ''
            }
        </CardContent>
        <CardActions disableSpacing>
            {
                NoImage
                    ? <div aria-label="add to favorites" className={classes.cardActionsButtons}>
                        <div>
                            <input
                                style={{display: "none"}}
                                id={JSON.stringify(props.item.FileIndex)}
                                multiple
                                accept={isImage ? 'image/*' : '.stl, .obj, .json'}
                                type="file"
                                onChange={(event) => {
                                    handleUploadFile(event)
                                }}
                            />
                            <label htmlFor={JSON.stringify(props.item.FileIndex)}>
                                <Button color="default" component="span" style={{float: "right"}}>
                                    <CloudUpload/>
                                </Button>
                            </label>
                        </div>
                    </div>
                    : <div className={classes.cardActionsButtons}>
                        <IconButton onClick={() => {
                            deleteConfirmation(Id)
                        }}>
                            <Delete/>
                        </IconButton>
                        <IconButton href={props.item.URL} color={"default"}>
                            <CloudDownload/>
                        </IconButton>
                        {/*<a href={props.item.URL} target="_blank" style={{color: "rgb(128 128 128)"}}>*/}
                        {/*    <CloudDownload />*/}
                        {/*</a>*/}
                    </div>

            }


        </CardActions>
    </Card>
}