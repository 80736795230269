import { connect } from "react-redux";
import React, { useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import CustomInput from "components/CustomInput/CustomInput2.js";
import RegularButton from "components/CustomButtons/RegularButton.js";
import Dropdown from "components/Dropdown/dropdown.js";

const useStyles = makeStyles((theme) => ({
  root: {
   // display: 'flex',
  //   flexWrap: 'wrap',
  //  overflow: 'auto',
  //  overflowX: 'hidden',
  //  height: 400
  },

}));

function SettingsComponent(props) {
  let dispatch = useDispatch();
  const classes = useStyles();
  var uniqid = require('uniqid');
  const [userInputValues, setUserInputURLs] = useState(); 
  const [elements, setElements] = useState([]); 
  const serverData = [
    {
      id: 3459384746,
      type: "input",
      value: "input1",
      savedValue: ""
    },
    {
      id: 593957,
      type: "input",
      value: "input2",
      savedValue: ""
    },
    {
      id: 593957,
      type: "dropdown",
      values:[110,453,896,742],
      savedValue: 453
    }
  ];

  const getToken = () => {
    let token = localStorage.getItem("idToken");
    return token;
  };

  const activeRoute = routeName => {
    props.history.push({
      pathname: routeName,
      params: ""
    });
  };

  const updateNews = (value) => {
    setUserInputURLs(state => ({...state, "dropdownValue": value}));
  };

  const createElements = (serverObject) =>{
    if (serverObject.type === "input"){
      return (
        <GridItem xs={12} sm={12} md={12}>
        <CustomInput
            labelText={"Enter " + serverObject.value + " URL Here"}
            id="news-url"
            formControlProps={{
              fullWidth: true
            }}
            onChange={e =>{
              let value=e.target.value;
              let text = serverObject.value;
              setUserInputURLs(state => ({...state, [text]: value}));
            }}
          />
        </GridItem>
      )
    }
    else if (serverObject.type=="dropdown"){
      return(
      <GridItem xs={12} sm={12} md={12}>
        <Dropdown status={serverObject.savedValue} values={serverObject.values} action={updateNews} />
      </GridItem>
      )
    }
  }

  const settingsType = () =>{
    let path = props.location.pathname;
       return path.substring(7).toUpperCase();
  }
 
  useEffect(() => {
    //Once endpoint is implemented, id will be stored in store.serverSettings. 
    //Then loop through the store's array, find the correct id through the url path, and make the call with the correct id
    let serverElements = [];
    for (let i=0; i<= serverData.length-1; i++){
      serverElements.push(createElements(serverData[i]));
    }
    setElements(serverElements);
  }, []);

  return (
    <div>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
            <GridItem xs={12} sm={12} md={12}>
            <h5>Edit {settingsType()}</h5>
            </GridItem>
              {elements}
              <GridItem xs={12} sm={12} md={12}>
              <RegularButton
                color="success"
                onClick = {e => {
                  console.log(userInputValues);
                  //whatever logic to save to server
                }}
              >
                Submit
              </RegularButton>
              <RegularButton
                onClick={() => {
                  props.history.goBack();
                }}
              >
                Cancel
              </RegularButton>
            </GridItem>      
            </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
        </div>
  );
}
 
const mapStateToProps = state => {
  return {
  };
};

const wrapperFunction = connect(mapStateToProps);
const finalSettingsComponent = wrapperFunction(SettingsComponent);

SettingsComponent.propTypes = {
  history: PropTypes.array,
  push: PropTypes.func,
};

export default finalSettingsComponent;
