// @flow
import * as React from 'react';
import useProductStore from "../useProductStore";
import {TABLE_HEADER_CONFIG} from "../productConfig";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
export function ProductTableHeader() {
    const {tab} = useProductStore();
    return (
        <TableHead>
            <TableRow>
                {
                    TABLE_HEADER_CONFIG[tab.key].map(h => {
                        return <TableCell key={h.Key} align={h.Align} variant={"head"}>{h.Name}</TableCell>;
                    })
                }
            </TableRow>
        </TableHead>

    );
};