import {PrinterService} from "../../services/PrinterService";
import React, {useEffect, useState} from "react";
import GridItem from "../../components/Grid/GridItem";
import RegularButton from "../../components/CustomButtons/RegularButton";
import {Add} from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import modalStyle from "../../assets/jss/material-dashboard-pro-react/modalStyle";
import GridContainer from "../../components/Grid/GridContainer";
import PrinterGridCard from "./PrinterGridCard";
import Printer from "./Printer";
import DialogForm from "../Components/DialogForm/DialogForm";
import PrinterForm from "./PrinterFom";

const useStyles = makeStyles(modalStyle);

export default function PrinterGrid() {
    const classes = useStyles();

    const [printers, setPrinters] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [forEdit, setForEdit] = useState(null);

    useEffect(() => {
        loadPrinter();
    }, [])

    useEffect(() => {
        if(forEdit) {
            setOpenForm(true);
        }
    },[forEdit])

    const loadPrinter = async () => {
        const res = await PrinterService.getAll()
        setPrinters(res);
    }
    const loadPrinterCards = () => {
        if (printers) {
            return printers.map(item => {
               return <GridItem sm={3}>
                    <PrinterGridCard printer={new Printer(item)} setForEdit={(d) => setForEdit(d)} refresh={loadPrinter} />
               </GridItem>
            });
        }
    }
    return <GridContainer>
        <GridItem md={12}>
            <RegularButton
                justIcon
                round
                color="success"
                // className={classes.floatRight}
                onClick={() => {
                    setOpenForm(true);
                }}
            >
                <Add className={classes.icons} />
            </RegularButton>
        </GridItem>
        {
            loadPrinterCards()
        }
        <DialogForm title="Printer form" open={openForm} setOpen={setOpenForm}>
            <PrinterForm
                reFresh={loadPrinter}
                open={openForm}
                setOpenForm={setOpenForm}
                forEdit={forEdit}
            />
        </DialogForm>
    </GridContainer>
}