import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Dropdown from "components/Dropdown/dropdown.js";
import ImageForTable from "components/CustomUpload/imageForTable.js";
import Spinner from '../Initials/spinner.js'

//import actions for Redux
import {
  COMMIT_TEMPLATE,
  RESET_FILE_INDEX,
  COMMIT_ALL_DETAILS,
  COMMIT_ALL_TEMPLATES,
  COMMIT_TYPES,
  COMMIT_ACTION, SHOW_APP_ALERT
} from "../../action-types.js";

import adminActions from "../../services/admin";

//@material-ui plus React and custom styling
import { makeStyles } from "@material-ui/core/styles";
import ReactTable from "react-table";
import "./templateTableStyle.css";

// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Close from "@material-ui/icons/Close";


// core components
import RegularButton from "components/CustomButtons/RegularButton.js";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

function TemplateTableComponent(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  var uniqid = require('uniqid');
  let [tableData, setTableData] = useState([]);
  let [localTypeOneTemplates, setLocalTypeOneTemplates] = useState([]);
  let [localTypeTwoTemplates, setLocalTypeTwoTemplates] = useState([]);
  let [tableHeaders, setTableHeaders] = useState([]);
  let [janHaitoTabSelected, setJanHaitoTabSelected] = useState("selected");
  let [smileDonatorTabSelected, setSmileDonatorTabSelected] = useState("");
  let [spinner, setSpinner] = useState(<Spinner fetching={true}/>);

  const getToken = () => {
    let token = localStorage.getItem("idToken");
    return token;
  };

  const activeRoute = routeName => {
    // window.location.href = process.env.REACT_APP_BASE_URL + routeName;
    props.history.push({
      pathname: routeName,
      params: ""
    });
  };

  const filterResults = result => {
    let filteredResults = [];
    for (let i = 0; i <= result.length-1; i++) {
      if (result[i].IsDeleted == 0 && i != result.length - 1) {
        filteredResults.push(result[i]);
      } 
      else if (result[i].IsDeleted == 0 && i == result.length - 1) {
        filteredResults.push(result[i]);
        return filteredResults;
      } else if (result[i].IsDeleted == 1 && i == result.length - 1) {
        return filteredResults;
      }
    }
  };

  const getTemplates = async () => {
    try {
      let result = await adminActions.fetchTemplates();
      let filteredResults = filterResults(result.data.data.result.data);
      return filteredResults;
    } catch (err) {
      console.error(err);
    }
  };

  const getTemplateTypes = async () => {
    try {
      let result = await adminActions.getTemplateTypes();
      let types = result.data.data.result;
      return types;
    } catch (err) {
      console.error(err);
    }
  };

  const displayTabs = () =>{
    let tabs = [];

      tabs.push(<Tab
        label="jan-haito"
        className={janHaitoTabSelected}
        onClick={() => {
          if(janHaitoTabSelected == ""){
            setJanHaitoTabSelected("selected");
            setSmileDonatorTabSelected("");
          }
          let correctHeaders = organizeHeaders(props.allTypes[0]);
          mapTableHeaders(correctHeaders, localTypeOneTemplates);
          getPriorities(localTypeOneTemplates);
          setTableData(localTypeOneTemplates);
        }}
      />)

      tabs.push(<Tab
        label="3D Templates"
        className={smileDonatorTabSelected}
        onClick={() => {
          if(smileDonatorTabSelected == ""){
            setSmileDonatorTabSelected("selected");
            setJanHaitoTabSelected("");
          }
          let correctHeaders = organizeHeaders(props.allTypes[1]);
          mapTableHeaders(correctHeaders, localTypeTwoTemplates);
          getPriorities(localTypeTwoTemplates);
          setTableData(localTypeTwoTemplates);
        }}
      />)
      return tabs;
  }

      const deleteTemplate = async (row, id, tableDataPlaceholder) => {

        dispatch({
          type: COMMIT_TEMPLATE,
          templateId: id
        });
        tableDataPlaceholder.splice(row.index,1);

    try {
      let result =  await adminActions
      .putTemplates({
        template_id: id,
        is_deleted: "1"
      })
      .then(result => {
        let config = {
          type: 'error',
          title: "Success",
          message: "You deleted a template!",
        };
        dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
      })
    }
    catch (err) {
      let config = {
        type: 'error',
        title: "Error",
        message: "Yes this is the error"
      };
      dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
    }

    try {
        const response = await getTemplates().then(result =>{
        });
        if (response) {
          dispatch({
            type: COMMIT_ALL_TEMPLATES,
            payload: response
          });
        }
    }
    catch (err) {
      let config = {
        type: 'error',
        title: "Error",
        message: "Couldn't retrieve all templates after delete."
      };
      dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
    }
};

 

  const markForAI = async (id, checked, tableDataPlaceholder) => {
    if (checked != true) {
      let result = adminActions
        .putTemplates({
          template_id: id,
          ai_support: "1"
        })
        .then(result => {
          let config = {
            type: 'success',
            title: "Success",
            message: "You marked this template for AI Smile Simulation!",
          };
          dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        });
    } else {
      let result = adminActions
        .putTemplates({
          template_id: id,
          ai_support: "0"
        })
        .then(result => {
          let config = {
            type: 'success',
            title: "Success",
            message: "You removed this template from AI Smile Simulation!",
            buttons: [
              {
                text: "Ok"
              }
            ]
          };
          dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        });
    }
  };

  const organizeHeaders = (label, initialHeaders) =>{
    let tabArray = [];
      if(initialHeaders){
        initialHeaders.sort(function (a,b){
          let keyA = a.Key;
          let keyB = b.Key;
    
          if (keyA< keyB){
            return -1;
          }
          if (keyA > keyB){
            return 1;
          }
          return 0;
        });
      for (let i=0;i<=initialHeaders.length-1;i++){
        if (initialHeaders[i].Type == label.type){
          tabArray.push(initialHeaders[i]);
        }
    }
      return tabArray;
    }
   else{
      for (let i=0;i<=props.allDetails.length-1;i++){
        if (props.allDetails[i].Type == label.type){
         tabArray.push(props.allDetails[i]);
        }
     }
   return tabArray;
   }
  }

  //This gets template details from getAllDetails endpoint.
  const getTemplateInformation = async () => {
    try {
      let result = await adminActions.fetchTemplateInformation();
      let curatedResults = result.data.data.result.data;
      return curatedResults;

    } catch (err) {
      console.error(err);
    }
  };

  const makeLowerCaseAndRemoveSpaces = string => {
    let stringTwo = string.toLowerCase();
    let finalString = stringTwo.replace(" ", "-");
    return finalString;
  };

  const templateNameCell = (tableDataPlaceholder, row) => {
      let name = tableDataPlaceholder[row.index].Name;
      let id = tableDataPlaceholder[row.index].Id;

      return <a onClick={() => {
        dispatch({
          type: COMMIT_TEMPLATE,
          templateId: id,
          name: name
        });
        activeRoute("overview/")
      }}>{name}</a>;
  };

  const editCell = (tableDataPlaceholder, row) => {

      let id = tableDataPlaceholder[row.index].Id;
      let name = tableDataPlaceholder[row.index].Name;
      let type = tableDataPlaceholder[row.index].Type;
      let form = tableDataPlaceholder[row.index].Form;
      let age = tableDataPlaceholder[row.index].Age;
      let priority = tableDataPlaceholder[row.index].Priority;

      return (
        <div>
          {/* use this button to add a edit kind of action */}
          <RegularButton
            justIcon
            round
            simple
            onClick={() => {
              dispatch({
                type: COMMIT_TEMPLATE,
                templateId: id,
                name: name,
                templateType: type,
                age : age,
                form: form,
                priority: priority
              });
              dispatch({
                type: COMMIT_ACTION,
                payload: "edit"
              });
              activeRoute("edit-template/");
            }}
            color="warning"
            className="edit"
          >
            <Dvr />
          </RegularButton>
          {" "}
        </div>
      );
  };

  const deleteCell = (tableDataPlaceholder, row) => {
      let id = tableDataPlaceholder[row.index].Id;

      return (
          <div>
        {/* use this button to add a edit kind of action */}
        {" "}
        {/* use this button to remove the data row */}
        <RegularButton
          justIcon
          round
          simple
         onClick={() => { 
           deleteTemplate(row, id, tableDataPlaceholder);
          }}
          color="danger"
          className="remove"
          id="align-right"
        >
          <Close />
        </RegularButton>
      </div>
      );
  };

  const updateServer = (value, key, id) =>{
    if (value === "Active"){
      value = "1";
    }
    else if (value=="Inactive"){
      value = "0";
    }
    let result = adminActions
      .putTemplates({
        template_id: id,
        [key]: value
      })
      .then(result => {
        let config = {
          type: 'error',
          title: "Success",
          message: "You marked this template as "+ value,
          buttons: [
            {
              text: "Ok"
            }
          ]
        };
        dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
      });
    }

  const statusCell = (tableDataPlaceholder, row) => {
      let status=tableDataPlaceholder[row.index].Status;
      let id = tableDataPlaceholder[row.index].Id;

      if (status ==1){
        status="Active"
      } else {
        status="Inactive"
      };
  
      return ( 
        <Dropdown
        status = {status}
        type = "status"
        id = {id}
        action={updateServer}
        values={["Active", "Inactive"]}
        />
    );
  };

  const priorityCell = (tableDataPlaceholder, row) => {
    let id = tableDataPlaceholder[row.index].Id;
    let priority = tableDataPlaceholder[row.index].Priority;

    return ( 
      <Dropdown
      status = {priority}
      type = "priority"
      id = {id}
      action={updateServer}
      values={numberOfPriorities}
      />
  );
  };

  const formCell = (tableDataPlaceholder, row) => {
    let status = tableDataPlaceholder[row.index].Form;
    let id = tableDataPlaceholder[row.index].Id;

    return ( 
    <Dropdown
    status = {status}
    type = "form"
    id = {id}
    action={updateServer}
    values={["round", "square", "triangle"]}
    />
  );
  };

  const ageCell = (tableDataPlaceholder, row) => {
    let age = tableDataPlaceholder[row.index].Age;
    let id = tableDataPlaceholder[row.index].Id;

    return ( 
    <Dropdown
    status = {age}
    type = "age"
    id = {id}
    action={updateServer}
    values={["young","mid-age","mature"]}
    />
  );
  };

  const aiSupportCell = (tableDataPlaceholder, row) => {
      let aiSupport = tableDataPlaceholder[row.index].AISupport;
      let  id = tableDataPlaceholder[row.index].Id;
      let checked;

      if (aiSupport == 1) {
        checked= true;
      } else {
        checked= null;
      };

      const handleChange = () => {
        if (checked == null){
          checked = true;
        }
        else if (checked == true) {
          checked = null;
        }
      };

    return (
      <Checkbox
        checked={checked}
        onChange={handleChange}
        onClick={() => markForAI(id, checked, tableDataPlaceholder)}
      />
    );
  };
 
  const imageCell = (tableDataPlaceholder, row) => {
    let id = tableDataPlaceholder[row.index].Id;
    return ( 
    <ImageForTable
    templateId = {id}
    />
  );
  };

  const templateTypeCell = (tableDataPlaceholder, row) => {
    let type = tableDataPlaceholder[row.index].Type;
    return <p>{type}</p>;
};

  let array = [];
  const mapTableHeaders = (response, tableDataPlaceholder) => {
    // array.push({
    //   Header: "Donor",
    //   accessor: "donor",
    //   Cell: row => imageCell(tableDataPlaceholder, row)
    // });

    array.push({
      Header: "Name",
      accessor: "name",
      Cell: row => templateNameCell(tableDataPlaceholder, row)
    });

    array.push({
      Header: "Type",
      accessor: "type",
      Cell: row => templateTypeCell(tableDataPlaceholder, row)
    });

    for (let i = 0; i <= response.length - 1; i++) {
      //IS IMAGE FLAG CAN BE INCLUDED HERE
        array.push({
          Header: response[i].Name,
          accessor: makeLowerCaseAndRemoveSpaces(response[i].Name),
          id: response[i].Id,
          Cell: row => myCell(response[i].Id, response[i].Name)
        });
    }

    array.push({
      Header: "Priority",
      accessor: "priority",
      Cell: row => priorityCell(tableDataPlaceholder, row)
    });

    array.push({
      Header: "Form",
      accessor: "form",
      Cell: row => formCell(tableDataPlaceholder, row)
    });

    array.push({
      Header: "Age",
      accessor: "age",
      Cell: row => ageCell(tableDataPlaceholder, row)
    });

    array.push({
      Header: "Status",
      accessor: "status",
      Cell: row => statusCell(tableDataPlaceholder, row)
    });

    array.push({
      Header: "AI Support",
      accessor: "aiSupport",
      Cell: row => aiSupportCell(tableDataPlaceholder, row)
    });

    array.push({
      Header: "Edit",
      accessor: "edit",
      Cell: row => editCell(tableDataPlaceholder, row)
    });

    array.push({
      Header: "Delete",
      accessor: "delete",
      Cell: row => deleteCell(tableDataPlaceholder, row)
    });
    setTableHeaders(array);
    return array;
  };

  let numberOfPriorities;
  const getPriorities = (response) =>{
    let priorities = [];
    for (let i=1;i<=response.length;i++){
          priorities.push(i);
    }
    numberOfPriorities = priorities;
  }

  const sortTemplates = (allTemplates, types) =>{
    let typeOneTemplates = [];
    let typeTwoTemplates = [];

    for (let i=0; i<=allTemplates.length-1;i++){
      if (allTemplates[i].Type == types[0].type){
        typeOneTemplates.push(allTemplates[i]);
      }
      else if (allTemplates[i].Type == types[1].type){
        typeTwoTemplates.push(allTemplates[i]);
      }
    }
    getPriorities(typeOneTemplates);
   // setJanHaitoTemplates(typeOneTemplates);
    setLocalTypeOneTemplates(typeOneTemplates);
    setTableData(typeOneTemplates);
    setLocalTypeTwoTemplates(typeTwoTemplates);
    return typeOneTemplates;
  }

  useEffect(() => {
    // Update the document title using the browser API
    dispatch({ type: RESET_FILE_INDEX });
    let tableDataPlaceholder;
    let templateTypes;
    async function fetchData() {
       Promise.all([
         getTemplateTypes(), 
         getTemplates(),
         getTemplateInformation(),]).
      then((response) =>{
        if (response[0]){
          templateTypes = response[0];
          dispatch({
            type: COMMIT_TYPES,
            payload: response[0]
          });
        }
        if (response[1]){
          tableDataPlaceholder = sortTemplates(response[1], templateTypes);
        }
        if (response[2]){
          //ISIMAGE WILL BE IN HERE
          dispatch({
            type: COMMIT_ALL_DETAILS,
            payload: response[2]
          });
          let correctHeaders = organizeHeaders(templateTypes[0], response[2]);
          //MAKE SURE ISIMAGE FLAG IS IN correctHeaders
          mapTableHeaders(correctHeaders, tableDataPlaceholder);
          setSpinner('');
        }
      })
    }
    fetchData();
  }, []);

  const myCell = (id, name, templateId) => {
    return <a>{name}</a>;
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <h4 className={classes.cardTitle}>
                Templates - Add, Modify Or Delete
              </h4>
            </CardHeader>
            <CardBody>
              <Tabs
                // value={value}
                // onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
              {displayTabs()}
              </Tabs>
              {spinner}
              <ReactTable
                key={uniqid()}
                data={tableData}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (
                        column.Header != "Name" &&
                        column.Header != "Actions" &&
                        column.Header != "AI Support" &&
                        column.Header !="Status" &&
                        column.Header !="Form" &&
                        column.Header !="Age" &&
                        column.Header != "Delete" &&
                        column.Header != "Edit" &&
                        column.Header !="Type" &&
                        column.Header !="Priority" &&
                        column.Header != "Donor"
                      ) {
                        dispatch({
                          type: COMMIT_TEMPLATE,
                          templateId: rowInfo.original.Id,
                          name: rowInfo.original.Name,
                          selectedDetailsID: column.id
                        });
                        activeRoute("upload/");
                      }
                      if (handleOriginal) {
                        handleOriginal();
                      }
                    }
                  };
                }}
                sortable={false}
                columns={tableHeaders}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    templateId: state.store.templateId,
    templateType: state.store.templateType,
    smileDonatorColorsImageCount: state.store.smileDonatorColorsImageCount,
    selectedDetailsID: state.store.selectedDetailsID,
    uploadedFileDetails: state.store.uploadedFileDetails,
    fileURL: state.store.fileURL,
    allDetails: state.store.allDetails,
    allTemplates: state.store.allTemplates,
    janHaitoTemplates: state.store.janHaitoTemplates,
    smileDonatorTemplates: state.store.smileDonatorTemplates,
    janHaitoDetails: state.store.janHaitoDetails,
    smileDonatorDetails: state.store.smileDonatorDetails,
    allTypes: state.store.allTypes
  };
};

TemplateTableComponent.propTypes = {
  history: PropTypes.array,
  push: PropTypes.func,
  templateId: PropTypes.string,
  selectedDetailsID: PropTypes.string,
  uploadedFileDetails: PropTypes.array,
  image: PropTypes.array,
  scrollPosition: PropTypes.object,
  allDetails: PropTypes.array,
  allTemplates: PropTypes.array
};

const wrapperFunction = connect(mapStateToProps);
export default wrapperFunction(TemplateTableComponent);
