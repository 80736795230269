import React, {useEffect, useState} from 'react';
import adminActions from "../../services/admin";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import {useInputProps} from "../../components/CustomInput/InputPropsHook";
import InputErrors from "../../components/CustomInput/InputErrors";
import CustomInput from "../../components/CustomInput/CustomInput";
import {TEMPLATE_AGE_TYPES, TEMPLATE_FORM_TYPES, TEMPLATE_EMBRASURES_TYPES, TEMPLATE_INCISAL_EDGE_TYPES, TEMPLATE_BUCAL_SURFACE_TYPES} from "../../common/const";
import RegularButton from "../../components/CustomButtons/RegularButton";
import {useDispatch} from "react-redux";
import {SHOW_APP_ALERT} from "../../action-types";

const useStyles = makeStyles(styles);

export default function TemplateForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [templateTypes, setTemplateTypes] = useState([]);
    const templateType = useInputProps({type: "select", rules: ["required"]});
    const templateName = useInputProps({type: "text", rules: ["required"]});
    // const templateAge = useInputProps({type: "text", rules: ["required"]});
    const templateForm = useInputProps({type: "text", rules: ["required"]});
    const templateEmbrasures = useInputProps({type: "text", value: ''});
    const templateIncisalEdges = useInputProps({type: "text", value: ''});
    const templateBucalSurface = useInputProps({type: "text", value: ''});

        useEffect(() => {
        getTemplateTypes();
    }, []);

    const getToken = () => {
        return  localStorage.getItem("idToken");
    };
    const getTemplateTypes = async () => {
        try {
            let result = await adminActions.getTemplateTypes();
            setTemplateTypes(result.data.data.result);
        } catch (err) {
            console.error(err.message);
        }
    };

    const loadTemplateSelectItems = () => {
        return templateTypes.map( tp => {
            return (
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple
                    }}
                    value={tp.type}
                >
                    {tp.name}
                </MenuItem>
            )
        })
    }
    const loadSelectItemFromArray = (arr) => {
        return arr.map( at => {
            return (
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple
                    }}
                    value={at}
                >
                    {at}
                </MenuItem>
            )
        })
    }
    const checkValidity = () => {
        return (templateForm.doValidate() &
        // templateAge.doValidate() &
        templateName.doValidate() &
        templateType.doValidate());
    };
    const activeRoute = routeName => {
        // window.location.href = process.env.REACT_APP_BASE_URL + routeName;
        props.history.push({
            pathname: routeName,
            params: ""
        });
    };
    const submit = () => {
        if(!checkValidity()) {
            return;
        }
        let params = {
            // age: templateAge.props.value,
            form: templateForm.props.value,
            name: templateName.props.value,
            type: templateType.props.value,
            embrasures: templateEmbrasures.props.value,
            incisal_edges: templateIncisalEdges.props.value,
            bucal_surface: templateBucalSurface.props.value
        }
        adminActions
            .postTemplates(params)
            .then(result => {
                let config = {
                    type: "success",
                    title: "Success",
                    message: "Template saved successfully!",
                };
                dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
                activeRoute('/admin/templates-list');

            });
    }
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader>
                        <h4 className={classes.cardTitle}>Template form</h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <CustomInput
                                    error={!templateName.validity.isValid}
                                    labelText="Template Name...*"
                                    id="templateName"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{...templateName.props}}
                                />
                                <InputErrors errors={templateName.validity.errors}/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="templateType"
                                        className={classes.selectLabel}
                                    >
                                        Template type
                                    </InputLabel>
                                    <Select
                                        value={templateType.props.value}
                                        onChange={templateType.props.onChange}
                                        MenuProps={{className: classes.selectMenu}}
                                        classes={{select: classes.select}}
                                        inputProps={{
                                            name: "templateType",
                                            id: "templateType"
                                        }}
                                    >
                                        {loadTemplateSelectItems()}
                                    </Select>
                                </FormControl>
                                <InputErrors errors={templateType.validity.errors} />
                            </GridItem>
                            {/* <GridItem xs={12} sm={12} md={3}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="ageTypeSelect"
                                        className={classes.selectLabel}
                                    >
                                        Age type
                                    </InputLabel>
                                    <Select
                                        value={templateAge.props.value}
                                        onChange={templateAge.props.onChange}
                                        MenuProps={{className: classes.selectMenu}}
                                        classes={{select: classes.select}}
                                        inputProps={{
                                            name: "ageTypeSelect",
                                            id: "ageTypeSelect"
                                        }}
                                    >
                                        {loadSelectItemFromArray(TEMPLATE_AGE_TYPES)}
                                    </Select>
                                </FormControl>
                                <InputErrors errors={templateAge.validity.errors} />
                            </GridItem> */}
                            <GridItem xs={12} sm={12} md={3}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="formTypeSelect"
                                        className={classes.selectLabel}
                                    >
                                        Form Type
                                    </InputLabel>
                                    <Select
                                        value={templateForm.props.value}
                                        onChange={templateForm.props.onChange}
                                        MenuProps={{className: classes.selectMenu}}
                                        classes={{select: classes.select}}
                                        inputProps={{
                                            name: "formTypeSelect",
                                            id: "formTypeSelect"
                                        }}
                                    >
                                        {loadSelectItemFromArray(TEMPLATE_FORM_TYPES)}
                                    </Select>
                                </FormControl>
                                <InputErrors errors={templateForm.validity.errors} />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="embrasuresSelect"
                                        className={classes.selectLabel}
                                    >
                                        Embrasures
                                    </InputLabel>
                                    <Select
                                        value={templateEmbrasures.props.value}
                                        onChange={templateEmbrasures.props.onChange}
                                        MenuProps={{className: classes.selectMenu}}
                                        classes={{select: classes.select}}
                                        inputProps={{
                                            name: "embrasuresSelect",
                                            id: "embrasuresSelect"
                                        }}
                                    >
                                        {loadSelectItemFromArray(TEMPLATE_EMBRASURES_TYPES)}
                                    </Select>
                                </FormControl>
                                {/* <InputErrors errors={templateAge.validity.errors} /> */}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="incisalEdgesSelect"
                                        className={classes.selectLabel}
                                    >
                                        Incisal Edges
                                    </InputLabel>
                                    <Select
                                        value={templateIncisalEdges.props.value}
                                        onChange={templateIncisalEdges.props.onChange}
                                        MenuProps={{className: classes.selectMenu}}
                                        classes={{select: classes.select}}
                                        inputProps={{
                                            name: "incisalEdgesSelect",
                                            id: "incisalEdgesSelect"
                                        }}
                                    >
                                        {loadSelectItemFromArray(TEMPLATE_INCISAL_EDGE_TYPES)}
                                    </Select>
                                </FormControl>
                                {/* <InputErrors errors={templateAge.validity.errors} /> */}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="bucalSurfaceSelect"
                                        className={classes.selectLabel}
                                    >
                                        Bucal Surface
                                    </InputLabel>
                                    <Select
                                        value={templateBucalSurface.props.value}
                                        onChange={templateBucalSurface.props.onChange}
                                        MenuProps={{className: classes.selectMenu}}
                                        classes={{select: classes.select}}
                                        inputProps={{
                                            name: "bucalSurfaceSelect",
                                            id: "bucalSurfaceSelect"
                                        }}
                                    >
                                        {loadSelectItemFromArray(TEMPLATE_BUCAL_SURFACE_TYPES)}
                                    </Select>
                                </FormControl>
                                {/* <InputErrors errors={templateAge.validity.errors} /> */}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <RegularButton
                                    className={classes.floatRight}
                                    color="success"
                                    onClick={() => {
                                        submit();
                                    }}
                                >
                                    Save
                                </RegularButton>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}