import dsdInstance from "./dsdInstance";

const put = (user_id , param) => {
    return dsdInstance.put("/subscription-service/user", {user_id, ...param});
}
const editAssign = (user_id , id, end_date) => {
    return dsdInstance.put("/subscription-service/user", {user_id, id, end_date});
}
const assign = (user_id, plan_id) => {
    return dsdInstance.post("/subscription-service/user", {user_id, plan_id});
};

const getHistory = (userId, params) => {
    return dsdInstance.get("/subscription-service/user/" + userId + '/all' + '?' + params);
};

const getPlans = (param = "") => {
    return dsdInstance.get("/subscription-service/plans?status=1" + param);
};

const getDriveHistory = (userId, params) => {
    return dsdInstance.get("/drive-service/user/" + userId + '/plans' + '?' + params);
};

const updateDrive = (userId, params) => {
    return dsdInstance.put("/drive-service/user/" + userId + '/plans', params);
};

export default {
    getHistory: getHistory,
    getDriveHistory: getDriveHistory,
    getPlans: getPlans,
    assign: assign,
    editAssign: editAssign,
    put: put,
    updateDrive: updateDrive
};