import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { connect } from "react-redux";
import React, { useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function Dropdown(props){
const classes = useStyles();
const { status, values, action, type, id} = props;
const [localStatus, setLocalStatus] = useState();
const [open, setOpen] = React.useState(false);

const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) =>{
    setLocalStatus(event.target.value);
    if (action){
      action(event.target.value, type, id);
    }
  }

  const capitalizeFirstLetter = (string) =>{
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const mapValues = (values) => {
    let menuItemArray = [];
    for (let i = 0; i<=values.length-1; i++){
      if (typeof(values[i])==typeof("")){
        let displayedValue = capitalizeFirstLetter(values[i]);
        menuItemArray.push(<MenuItem value={values[i]}>{displayedValue}</MenuItem>);
      }
      else{
        menuItemArray.push(<MenuItem value={values[i]}>{values[i]}</MenuItem>);
      }
    };
    return menuItemArray;
  };

  useEffect((props) => {
      // Write functions to execute on page-load.
    }, []);

  return (
    <div>
        <FormControl className={classes.formControl} style={{textAlign: "center"}}>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={localStatus ? localStatus: status}
            onChange={handleChange}
          >
            {values ? mapValues(values): <MenuItem>no values provided</MenuItem>}
          </Select>
        </FormControl>
      </div>
  )
}

const mapStateToProps = state => {
    return {
//Return redux values here
    };
  };

const wrapperFunction = connect(mapStateToProps);
const MemoizedDropdownComponent = React.memo(Dropdown);
export default wrapperFunction(MemoizedDropdownComponent);