export const LANGUAGES = [
  {code: 'EN', lan: 'English'},
  {code: 'ES', lan: 'Spanish'},
  {code: 'PT', lan: 'Portuguese'},
  {code: 'DE', lan: 'German'},
  {code: 'ZH-HANT', lan: 'Chinese'},
  {code: 'FR', lan: 'French'},
  {code: 'IT', lan: 'Italian'},
  {code: 'JA', lan: 'Japanese'},
  {code: 'KO', lan: 'Korean'},
  {code: 'RU', lan: 'Russian'},
  {code: 'AR', lan: 'Arabic'},
  {code: 'ZH-HANS', lan: 'Chinese Simplified'},
  {code: 'FA', lan: 'Persian'},
  {code: 'pl', lan: 'Polish'}
];