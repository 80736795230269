import { takeEvery, put } from "redux-saga/effects";
import {
  ALLOW_NAVIGATION,
  ALLOW_NAVIGATION_NOT_OK,
  ALLOW_NAVIGATION_OK, SHOW_APP_ALERT_OK, SHOW_APP_ALERT
} from "../action-types";



let showAppAlert = function*({ alertConfig }) {
  yield put({ type: SHOW_APP_ALERT_OK, alertConfig });
};

export function* showAppAlertSaga() {
  console.log("hit");
  yield takeEvery(SHOW_APP_ALERT, showAppAlert);
}

let allowNavigation = function*({ payload }) {
  if (payload.allowNavigation) {
    yield put({ type: ALLOW_NAVIGATION_OK, payload });
  } else {
    yield put({ type: ALLOW_NAVIGATION_NOT_OK, payload });
  }
};
export function* allowNavigationSaga() {
  yield takeEvery(ALLOW_NAVIGATION, allowNavigation);
}
