import * as React from 'react';
import AppListTabFilter from "../../../components/AppListTabFilter";
import RegularButton from "../../../components/CustomButtons/RegularButton";
import {ArrowBack} from "@material-ui/icons";
import useProductStore from "../useProductStore";
import CardHeader from "../../../components/Card/CardHeader";
import {Card, InputLabel, MenuItem, Select} from "@material-ui/core";
import CardBody from "../../../components/Card/CardBody";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import {useInputProps} from "../../../components/CustomInput/InputPropsHook";
import {ACTIVE_STATUS, GRID_CONF, PRODUCT_CREDITS, TAB_DATA} from "../productConfig";
import {useEffect, useState} from "react";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputErrors from "../../../components/CustomInput/InputErrors";
import ImageUploadV1 from "../../../components/CustomUpload/ImageUploadV1";
import {ProductService} from "../../../services/ProductService";
import {SHOW_APP_ALERT} from "../../../action-types";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(styles);

export default function ProductForm() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {editable, changeEditable, loadProducts, changeFormOpen, config, tab} = useProductStore();
    const [newFile, setNewFile] = useState({});

    const productType = useInputProps({type: "select", rules: ["required"], value: tab.key});
    const productCurrency = useInputProps({type: "select", rules: ["required"], value: "USD"});
    const productName = useInputProps({type: "text", rules: ["required"]});
    const productCredits = useInputProps({type: "select", value: 0});
    const productStatus = useInputProps({type: "text", value: 1});
    const productDescription = useInputProps({type: "text"});
    const productPrice = useInputProps({type: "text"});

    useEffect(() => {
        if (editable) {
            const {Type, Name, Credits, Status, Description, Currency, Price} = editable;
            productType.setValue(Type);
            productName.setValue(Name);
            productCredits.setValue(Credits);
            productStatus.setValue(Status);
            productDescription.setValue(Description);
            productCurrency.setValue(Currency);
            productPrice.setValue(Price);
        }
    }, [editable])
    const backHandler = () => {
        changeEditable(null)
        changeFormOpen(false)
    }

    const productTypesSelectItems = () => {
        return TAB_DATA.map(td => <MenuItem
            classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelectedMultiple
            }}
            value={td.key}
        >
            {td.value}
        </MenuItem>)
    }
    const productStatusSelectItems = () => {

        return Object.keys(ACTIVE_STATUS).map((key) => <MenuItem
            classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelectedMultiple
            }}
            value={key}
        >
            {ACTIVE_STATUS[key]}
        </MenuItem>)
    }
    const productCurrencySelectItems = () => {

        const {currency} = config;
        return currency.map((c) => <MenuItem
            classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelectedMultiple
            }}
            value={c}
        >
            {c}
        </MenuItem>)
    }
    const productCreditSelectItems = () => {

        return PRODUCT_CREDITS.map((credit) => <MenuItem
            classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelectedMultiple
            }}
            value={credit}
        >
            {credit}
        </MenuItem>)
    }
    const l1 = () => {
        return GRID_CONF[productType.props.value] ? GRID_CONF[productType.props.value].l1 : 12
    }
    const l2 = () => {
        return GRID_CONF[productType.props.value] ? GRID_CONF[productType.props.value].l2 : 3
    }
    const l2Des = () => {
        return GRID_CONF[productType.props.value] ? GRID_CONF[productType.props.value].l2Des : 6
    }
    const s3Upload = async s3Url => {
        const data = new FormData();
        data.append('file', newFile);
        return await fetch(s3Url, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': newFile.type,
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: newFile
        });
    }
    const submit = async () => {
        let params = {
            credits: productCredits.props.value,
            name: productName.props.value,
            status: productStatus.props.value * 1,
            type: productType.props.value
        }
        if (productType.props.value === 'LAB') {
            params['file_name'] = newFile ? newFile["name"] : '';
            params['price'] = productPrice.props.value * 1;
            params['description'] = productDescription.props.value;
            params['currency'] = productCurrency.props.value;
        }
        let config = {
            type: "success",
            title: "Success",
            message: "Product saved successfully",
        };
        let res = {};
        if (editable) {
            res = await ProductService.update(params, editable.Id);
        } else {
            res = await ProductService.create(params);
        }
        console.log(res)
        if (!res['status']) {
            config = {
                type: "error",
                title: "Error",
                message: res['data'],
            };
            dispatch({type: SHOW_APP_ALERT, alertConfig: config});
            return;
        }
        if (res['data'].hasOwnProperty('url')) {
            const s3Res = await s3Upload(res.data.url);
            if (s3Res) {
                dispatch({type: SHOW_APP_ALERT, alertConfig: config});
            }
        } else {
            dispatch({type: SHOW_APP_ALERT, alertConfig: config});
        }
        loadProducts();
        changeEditable(null);
        changeFormOpen(false);
    }
    return (<Card>
            <CardHeader>
                <h4>Products Form</h4>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem md={12} style={{marginBottom: "18px"}}>
                        <RegularButton
                            color="tumblr"
                            size={"sm"}
                            onClick={backHandler}
                        >
                            <ArrowBack/> Back
                        </RegularButton>
                    </GridItem>
                    {
                        productType.props.value === 'LAB'
                            ? <GridItem xs={12} sm={12} md={3}>

                                <ImageUploadV1
                                    imagePreviewUrl={editable && editable.hasOwnProperty('URL') ? editable.URL : ''}
                                    setNewFile={(file) => setNewFile(file)}
                                    addButtonProps={{
                                        color: "rose",
                                        round: true,
                                        style: {float: "right"}
                                    }}
                                    changeButtonProps={{
                                        color: "rose",
                                        round: true,
                                        style: {float: "right"}

                                    }}
                                    removeButtonProps={{
                                        color: "danger",
                                        round: true,
                                        style: {float: "right"}
                                    }}
                                />
                            </GridItem>
                            : ''
                    }
                    <GridItem sm={l1()}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={l2()}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="productType"
                                        className={classes.selectLabel}
                                    >
                                        Product type
                                    </InputLabel>
                                    <Select
                                        disabled={editable}
                                        value={productType.props.value}
                                        onChange={productType.props.onChange}
                                        MenuProps={{className: classes.selectMenu}}
                                        classes={{select: classes.select}}
                                        inputProps={{
                                            name: "productType",
                                            id: "productType"
                                        }}
                                    >
                                        {productTypesSelectItems()}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={l2()}>
                                <CustomInput
                                    error={!productName.validity.isValid}
                                    labelText="Product Name...*"
                                    id="productName"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{...productName.props}}
                                />
                                <InputErrors errors={productName.validity.errors}/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={l2()}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="productCredits"
                                        className={classes.selectLabel}
                                    >
                                        Credits
                                    </InputLabel>
                                    <Select
                                        // disabled = {editable ? "disabled" : ""}
                                        value={productCredits.props.value}
                                        onChange={productCredits.props.onChange}
                                        MenuProps={{className: classes.selectMenu}}
                                        classes={{select: classes.select}}
                                        inputProps={{
                                            name: "productCredits",
                                            id: "productCredits"
                                        }}
                                    >
                                        {productCreditSelectItems()}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={l2()}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="productStatus"
                                        className={classes.selectLabel}
                                    >
                                        Status
                                    </InputLabel>
                                    <Select
                                        // disabled = {editable ? "disabled" : ""}
                                        value={productStatus.props.value}
                                        onChange={productStatus.props.onChange}
                                        MenuProps={{className: classes.selectMenu}}
                                        classes={{select: classes.select}}
                                        inputProps={{
                                            name: "productType",
                                            id: "productType"
                                        }}
                                    >
                                        {productStatusSelectItems()}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            {
                                productType.props.value === 'LAB'
                                    ? <GridItem xs={12} sm={12} md={l2Des()}>
                                        <CustomInput
                                            error={!productDescription.validity.isValid}
                                            labelText="Product Description"
                                            id="productDescription"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{...productDescription.props}}
                                        />
                                        <InputErrors errors={productDescription.validity.errors}/>
                                    </GridItem>
                                    : ''

                            }
                            {
                                productType.props.value === 'LAB'
                                    ? <GridItem xs={12} sm={12} md={l2()}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="productCurrency"
                                                className={classes.selectLabel}
                                            >
                                                Product Currency
                                            </InputLabel>
                                            <Select
                                                value={productCurrency.props.value}
                                                onChange={productCurrency.props.onChange}
                                                MenuProps={{className: classes.selectMenu}}
                                                classes={{select: classes.select}}
                                                inputProps={{
                                                    name: "productCurrency",
                                                    id: "productCurrency"
                                                }}
                                            >
                                                {productCurrencySelectItems()}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    : ''

                            }
                            {
                                productType.props.value === 'LAB'
                                    ? <GridItem xs={12} sm={12} md={l2()}>
                                        <CustomInput
                                            error={!productPrice.validity.isValid}
                                            labelText="Product Price"
                                            id="productCurrency"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{...productPrice.props}}
                                        />
                                        <InputErrors errors={productPrice.validity.errors}/>
                                    </GridItem>
                                    : ''
                            }
                        </GridContainer>
                    </GridItem>
                    <GridItem md={12}>
                        <div style={{textAlign: "right", width: "100%"}}>
                            <RegularButton
                                size={"sm"}
                                // disabled={!validForm()}
                                color="success"
                                onClick={() => {
                                    submit();
                                }}
                            >
                                Save
                            </RegularButton>
                        </div>
                    </GridItem>
                </GridContainer>

            </CardBody>
        </Card>
    )
}