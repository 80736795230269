import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import CardHeader from "../../components/Card/CardHeader";
import {CardActions, CardContent, IconButton} from "@material-ui/core";
import Card from "../../components/Card/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import {red} from "@material-ui/core/colors";
import Printer from "./Printer";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import PrinterImage from "./PrinterImage";
import {Delete, EditRounded} from "@material-ui/icons";
import {PrinterService} from "../../services/PrinterService";
import {SHOW_APP_ALERT} from "../../action-types";
import {useDispatch} from "react-redux";
const useStyles = makeStyles((theme) => ({
    ...styles,
    root: {
        // maxWidth: 345,
    },
    '&:hover': {
        color: "rgb(128 128 128)"
    },
    stlMedia: {
        // padding: "20px 20px 20px 20px",
        margin: "auto",
        display: "block",
        maxHeight: "100%",
        maxWidth: "100%",
    },
    media: {
        padding: "1px",
        margin: "auto",
        display: "block",
        maxHeight: "100%",
        maxWidth: "100%",
    },
    mediaParent: {
        height: "200px",
        width: "100%",
        // backgroundColor: "#EAEAEA",
        position: "relative"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    cardContent: {
        textAlign: "center"
    },
    cardHeader: {
        textAlign: "center"
    },
    cardActions: {
        textAlign: "right",
    },
    cardActionsButtons: {
        marginLeft: 'auto',
    },
    progressBar: {width: "100%", display: "inline-block"}
}));

const PrinterGridCard = ({printer, setForEdit, refresh}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const editButtonHandler = () => {
        setForEdit(printer);
    }
    const deletePrinter = async () => {
        await PrinterService.delete(printer.Id);
        let config = {
            type: "success",
            message: 'Printer deleted successfully',
        };
        dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        refresh();

    }
    const deleteConfirmation = () => {
        let config = {
            type: "confirmation",
            message: 'Do you want to delete this configuration?',
            onConfirm: deletePrinter
        };
        dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
    }
    return <Card className={classes.root}>
        <CardHeader>
            <h4 className={classes.cardHeader}>{printer.Name}</h4>
        </CardHeader>
        <CardContent className={classes.cardContent}>
            <GridContainer>
                <GridItem sm={6}>
                   <PrinterImage src={printer.LogoFile}/>
                </GridItem>
                <GridItem sm={6}>
                    <PrinterImage src={printer.ImageFile}/>
                </GridItem>

            </GridContainer>

        </CardContent>
        <CardActions disableSpacing>
            <div className={classes.cardActionsButtons}>
                <IconButton color={"default"} onClick={editButtonHandler}>
                    <EditRounded/>
                </IconButton>
                <IconButton onClick={deleteConfirmation}>
                    <Delete/>
                </IconButton>

                {/*<a href={props.item.URL} target="_blank" style={{color: "rgb(128 128 128)"}}>*/}
                {/*    <CloudDownload />*/}
                {/*</a>*/}
            </div>
        </CardActions>
    </Card>
}

PrinterGridCard.prototype = {
    printer: PropTypes.objectOf(Printer)
}

export default PrinterGridCard;