import React from 'react';
import {STLViewer} from "react-stl-obj-viewer";
import makeStyles from "@material-ui/core/styles/makeStyles";
const objIcon =  require('./objIcon.png');
const useStyles = makeStyles(() => ({
    media: {
        padding: "20px 20px 20px 20px",
        margin: "auto",
        display: "block",
        maxHeight: "100%",
        maxWidth: "100%",
    },
    stlMedia: {
        // padding: "20px 20px 20px 20px",
        margin: "auto",
        display: "block",
        maxHeight: "100%",
        maxWidth: "100%",
    },
}))

export default function TemplateFileView(props) {
    const classes = useStyles();
    const {IsImage, NoImage, item} = props;
    return (
        IsImage || IsImage === undefined || (NoImage && true)
            ?
            <img src={item.URL} className={classes.media} loading="lazy" alt={'...'}/>
            :
            (
                item.FileName.split('.').pop() === 'stl'
                    ? <STLViewer
                        className={classes.stlMedia}
                        url={item.URL}
                        width={150}
                        height={150}
                        modelColor='#fff'
                        backgroundColor='#EAEAEA'
                        rotate={true}
                        orbitControls={true}
                    />
                    : <img src={objIcon} className={classes.media} loading="lazy" alt={'...'}/>
            )

    )
}