import React, {useEffect, useState} from 'react';
import SmallEditNumber from "./Cmps/SmallEditNumbr/SmallEditNumber";
import commonStyle from "../../common/styles/common";
import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Moment from "react-moment";
const useStyles = makeStyles(commonStyle);

export default function DriveHistoryBody({si, update}) {
    const classes = useStyles();

    return <GridContainer>
        <GridItem md={6}>
            {
                si.Status == 1
                    ? <SmallEditNumber value={si.Storage/1000000000} id={si.Id} label={"Storage (GB)"}
                                       update={update} paramKey="storage"/>  
                    : <span>  Storage (GB) :&nbsp; <b>{si.Storage/1000000000}</b></span>
                    
            }
        </GridItem>
        <GridItem md={6} className={classes.contentRight}>
            {
                si.Status == 1
                ? <span>Last Updated :&nbsp; <b><Moment format={"YYYY-MM-DD"}>{si.UpdatedAt}</Moment></b></span>

                : <p className={classes.floatLeft}>Duration : From&nbsp; <b><Moment
                            format={"YYYY-MM-DD"}>{si.StartDate}</Moment></b> to&nbsp;  
                            <b><Moment format={"YYYY-MM-DD"}>{si.EndDate}</Moment></b></p>
            }
        </GridItem>
    </GridContainer>
}