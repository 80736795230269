import {connect, useSelector} from "react-redux";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import RegularButton from "components/CustomButtons/RegularButton.js";
import PropTypes from "prop-types";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import {
  trackWindowScroll
} from "react-lazy-load-image-component";
import {
  COMMIT_FILE_DETAILS_TO_STORE,
  COMMIT_UPLOAD_FILE_TO_STORE,
  IMAGE_COUNT, SHOW_APP_ALERT
} from "../../action-types";
import adminActions from "../../services/admin";
import "tui-image-editor/dist/tui-image-editor.css";
import Spinner from '../Initials/spinner.js'

function UploadComponent(props) {
  let dispatch = useDispatch();
  const store = useSelector(state => state.store);
  let classes = useState([]);
  // eslint-disable-next-line react/prop-types
  var uniqid = require('uniqid');
  const [detailName, setDetailName] = useState("");
  const [data, setData] = useState();
  const [isObject, setIsObject] = useState(false);
  const [allDetail, getAllDetails] = useState([]);

  const getToken = () => {
    let token = localStorage.getItem("idToken");
    return token;
  };

  const activeRoute = routeName => {
    // window.location.href = process.env.REACT_APP_BASE_URL + routeName;
    props.history.push({
      pathname: routeName,
      params: ""
    });
  };

  const displayImageEditor = (file) => {
    document.querySelector('.image-editor').style.display="block";
    document.querySelector('.image-editor-box').style.display="block";
    var ImageEditor = require('tui-image-editor');
    var FileSaver = require('file-saver'); //to download edited image to local. Use after npm install file-saver
    var instance = new ImageEditor(document.querySelector('#tui-image-editor'), {
         includeUI: {
             loadImage: {
                 path: file,
                 //need name field here for image editor to properly function.
                 name: 'SampleImage'
             },
             menu:['filter'],
             menuBarPosition: 'top'
         },
        cssMaxWidth: 700,
        cssMaxHeight: 800,
        cssMinHeight: 800,
        selectionStyle: {
            cornerSize: 20,
            rotatingPointOffset: 70
        }
    });
  }
  const mapImageCount = imageCount => {
    let codeBlocks = [];
    let index = 0;
    let fileArray = [];
    if (props.uploadedFileDetails) {
      for (let i = 0; i <= props.uploadedFileDetails.length - 1; i++) {
        fileArray[props.uploadedFileDetails[i].fileIndex] = props.uploadedFileDetails[i];
      }

      //Pass file ID to imageUpload component below.
      //This inserts the blank image upload components where there are no files present from the server.
      for (let k=1; k<=props.ImageCount; k++){
        if (!fileArray[k]){
            codeBlocks.push(
              <GridItem xs={3} key={uniqid()} >
                <ImageUpload
              key={uniqid()}  
              fileIndex = {k}
              displayImageEditor={displayImageEditor}
              isObject = {isObject}
              />{" "}
              </GridItem>);
        }
        //This grabs the files from the server and renders them as 
        //part of the final array with their correct index position.
        //Pass url instead of id
        else {
          codeBlocks.push(
            <GridItem xs={3} key={uniqid()}>
              <ImageUpload
                key={uniqid()}
                fileIndex = {parseInt(fileArray[k].fileIndex)}
                p={10}
                id={fileArray[k].id}
                url = {fileArray[k].URL}
                scrollPosition={window.pageYOffset}
                displayImageEditor={displayImageEditor}
                isObject = {isObject}
              />{" "}
            </GridItem>
        );
        }
      }
    } 
    
    //This clause is for a completely blank component (nothing received from server).
    else {
      for (let i = 0; i <= props.ImageCount - 1; i++) {
        codeBlocks.push(
          <GridItem xs={3} >
            <ImageUpload 
            key={uniqid()}
            fileIndex = {index++}
            displayImageEditor={displayImageEditor}
            isObject = {isObject}
             />{" "}
          </GridItem>
        );
      }
    }
    return codeBlocks;
  };

  let templateForAPI = {
    template_id: props.templateId,
    detail_id: props.selectedDetailsID,
    signed_url: 1
  };

  let fileIDs = [];
  const getFileIDs = result => {
    let array = result.data.data.result;
    //filter for deleted images here
    for (let i = 0; i <= array.length - 1; i++) {
      if (array[i].IsDeleted == 0){
        let fileObject = {id: array[i].Id, fileIndex: array[i].FileIndex, URL: array[i].URL};
        fileIDs.push(fileObject);
      //Below increases the file index in local store as IDs are mapped over from server's response.
      dispatch({ type: COMMIT_UPLOAD_FILE_TO_STORE });
      }
    }
    let fileArray = fileIDs.sort((a,b)=>a.fileIndex-b.fileIndex);
    return fileArray;
  };

  //This gets the files associated with the detail and template.
  const getThisTemplateColors = async () => {
    try {
      let result = await adminActions.getTemplateDetails(
        templateForAPI
      );
      let finalResult = getFileIDs(result);
      return finalResult;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // Write functions to execute on page-load.
    setData(<Spinner fetching={true}/>);
    async function fetchData() {
      try {
        const response = await getThisTemplateColors();
        if (response) {
          //Below adds file ID's to the store
          dispatch({
            type: COMMIT_FILE_DETAILS_TO_STORE,
            payload: response
          });
        }
        setData('');
      } catch (err) {
        console.error(err);
      }
    }
    adminActions.fetchTemplateInformation().then(result => {
      const dataArr = result.data.data.result.data;
      let imageCount = "";
      dataArr.forEach(data => {
        if (store.selectedDetailsID == data.Id) {
          imageCount = data.ImageCount;
          setDetailName(data.Name);
          if (data.hasOwnProperty('IsImage') && data.IsImage === 0){
            setIsObject(true);
          }
          //FIND ISIMAGE FLAG HERE
          //SET A VALUE EQUAL TO TO THAT CAN BE ACCESSED BY mapImageCount()
          dispatch({
            type: IMAGE_COUNT,
            payload: imageCount
          });
        }
      })
      fetchData();

    }

  )


  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <h4 className={classes.cardTitle}>
              <strong>
                Upload {detailName} for Template: {store.templateName}
              </strong>{" "}
            </h4>
          </CardHeader>

          <CardBody>
            {/* <div>
                <p id="loading">Loading images now...</p>
              </div> */}
              <div className = "image-editor-box" style={{height:800}}><div className = "image-editor" id="tui-image-editor" style={{height: "800px"}}><canvas></canvas></div></div>
              <GridContainer direction="row" alignItems="center">
              {data}
            {mapImageCount(store.ImageCount)}
            </GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <RegularButton
                onClick={() => {
                  props.history.goBack();
                }}
              >
                Go Back
              </RegularButton>
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>

  );
}

const mapStateToProps = state => {
  return {
    templateId: state.store.templateId,
    templateType: state.store.templateType,
    selectedDetailsID: state.store.selectedDetailsID,
    uploadedFileDetails: state.store.uploadedFileDetails,
    fileURL: state.store.fileURL,
    templateName: state.store.templateName,
    allDetails: state.store.allDetails,
    ImageCount: state.store.ImageCount
  };
};

const wrapperFunction = connect(mapStateToProps);
const FinalUploadShadesComponent = wrapperFunction(UploadComponent);

UploadComponent.propTypes = {
  history: PropTypes.array,
  push: PropTypes.func,
  templateId: PropTypes.string,
  selectedDetailsID: PropTypes.string,
  uploadedFileDetails: PropTypes.array,
  image: PropTypes.array,
  scrollPosition: PropTypes.object
};

export default trackWindowScroll(FinalUploadShadesComponent);
