import React, { useEffect, useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import GridContainer from "../../components/Grid/GridContainer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import { Collapse, Grid } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { SearchOutlined, VerifiedUserTwoTone } from "@material-ui/icons";
import CustomInput from "../../components/CustomInput/CustomInput";
import CardBody from "../../components/Card/CardBody";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import SearchOptions from "./Cmps/SearchOptions";
import user from "../../services/userService";
import {
  successColor,
  warningColor
} from "../../assets/jss/material-dashboard-pro-react";
import UserProfile from "./Cmps/UserProfile";
import ConfirmUser from "./Cmps/ConfirmUser";
import UserPlans from "./Cmps/UserPlans";
import UserOneTimePlans from "./Cmps/UserOneTimePlans";
import UserDCatPlan from "./Cmps/UserDCatPlan";
import UserDrivePlan from "./Cmps/UserDrivePlan";

const useStyles = makeStyles({
  ...styles,
  ...customCheckboxRadioSwitch,
  margin: {
    margin: "2px"
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  suggestionBox: {
    backgroundColor: "#fbfbfb",
    height: "65px",
    borderRadius: "6px",
    border: "1px solid #fbfbfb",
    clear: "both",
    position: "relative",
    cursor: "pointer",
    "&:hover": {
      background: "#efefef"
    }
  },
  firstRow: {
    minHeight: "400px"
  },
  iconVerify: {
    width: "50px",
    height: "50px",
    marginTop: "6px"
  }
});
const statusColor = {
  UNCONFIRMED: warningColor[0],
  CONFIRMED: successColor[0]
};
export default function AppUserManager() {
  const classes = useStyles();
  const [searchFocus, setSearchFocus] = useState(false);
  const [selectedOption, setSelectedOption] = useState("email");
  const [term, setTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selected, setSelected] = useState("");
  useEffect(() => {
    search(term);
  }, [term, selectedOption]);

  const search = async t => {
    if (t.length > 3) {
      try {
        const users = await user.searchUsers(
          "?" + selectedOption + "=" + encodeURIComponent(t)
        );
        setSuggestions(users.data.data.result.Users);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const doSelect = data => {
    setSelected(data);
    // setSuggestions([]);
    setSearchFocus(false);
    // setTerm('');
  };
  const loadSuggestions = () => {
    if (!suggestions) {
      return [];
    }
    return suggestions.map((s, i) => {
      return (
        <div
          className={classes.suggestionBox}
          onClick={() => doSelect(s)}
          key={i}
        >
          <div style={{ float: "left" }}>
            <p style={{ padding: "2px 0px 0px 10px" }}>
              <b>
                {s.name} {s.family_name}
              </b>
              <br />
              {s.email}
              <br />
              {s.phone_number}
            </p>
          </div>
          <div style={{ float: "right" }}>
            <VerifiedUserTwoTone
              className={classes.iconVerify}
              style={{ color: statusColor[s.UserStatus] }}
            />
          </div>
        </div>
      );
    });
  };
  const resetSearch = () => {
    setTerm("");
    setSelected("");
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText={<span> User search</span>}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: term,
                    onFocus: () => {
                      setSearchFocus(true);
                    },
                    onChange: e => {
                      setTerm(e.target.value);
                    },
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <SearchOutlined
                          className={classes.inputAdornmentIcon}
                        />
                      </InputAdornment>
                    )
                  }}
                />
              </GridItem>
              <Collapse in={searchFocus} style={{ marginTop: "20 px" }}>
                <Card plain>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}>
                        <SearchOptions
                          selected={selectedOption}
                          onChange={setSelectedOption}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={9}>
                        <div>{loadSuggestions()}</div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </Collapse>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem md={6}></GridItem>

      {selected ? (
        <GridItem md={3}>
          <UserProfile
            isConfirmed={selected.UserStatus === "CONFIRMED"}
            {...selected}
            resetSearch={() => {
              resetSearch();
            }}
          />
        </GridItem>
      ) : (
        ""
      )}
      {selected &&
      selected.hasOwnProperty("UserStatus") &&
      selected.UserStatus === "UNCONFIRMED" ? (
        <GridItem md={3}>
          <ConfirmUser selected={selected} setSelected={setSelected} />
        </GridItem>
      ) : (
        ""
      )}
      {selected &&
      selected.hasOwnProperty("UserStatus") &&
      selected.UserStatus === "CONFIRMED" ? (
        <GridItem md={3}>
          <UserPlans selected={selected} />
        </GridItem>
      ) : (
        ""
      )}
      {selected &&
      selected.hasOwnProperty("UserStatus") &&
      selected.UserStatus === "CONFIRMED" ? (
        <GridItem md={3}>
          <UserOneTimePlans selected={selected} />
        </GridItem>
      ) : (
        ""
      )}
      {selected &&
      selected.hasOwnProperty("UserStatus") &&
      selected.UserStatus === "CONFIRMED" ? (
        <GridItem md={3}>
          <UserDCatPlan selected={selected} />
        </GridItem>
      ) : (
        ""
      )}
      {selected &&
      selected.hasOwnProperty("UserStatus") &&
      selected.UserStatus === "CONFIRMED" ? (
        <GridItem md={3}>
          <UserDrivePlan selected={selected} />
        </GridItem>
      ) : (
        ""
      )}
    </GridContainer>
  );
}
