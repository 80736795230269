import React, {useEffect} from 'react';
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import useProductStore from "./useProductStore";
import {ProductList} from "./Cmp/ProductList";
import ProductProvider from "./ProductProvider";
import ProductForm from "./Cmp/ProductForm";
import ProductFormNew from "./Cmp/ProductFormNew";

export default function Product() {

    const CmpSwitch = () => {
        const {formOpen, loadConfig} = useProductStore();
        useEffect(() => {
            loadConfig();
        }, [])
        return !formOpen ? <ProductList/> : <ProductFormNew/>
    }
    return <ProductProvider>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <CmpSwitch/>
            </GridItem>
        </GridContainer>
    </ProductProvider>
}