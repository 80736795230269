import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import RegularButton from "components/CustomButtons/RegularButton.js";
import adminActions from "../../services/admin";
import Spinner from '../Initials/spinner.js'
import CustomInput from "components/CustomInput/CustomInput2.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import People from "@material-ui/icons/People";
import {SHOW_APP_ALERT} from "../../action-types";

export default function EditAdminUser(props) {
    const dispatch = useDispatch();
    const classes = useState([]);
    // eslint-disable-next-line react/prop-types
    const {id} = props;
    let [userData, setUserData] = useState({});

    const [email, setEmail] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [spinner, setSpinner] = useState("");

    const getUser = async () => {
        setSpinner(<Spinner fetching={true}/>);
        try {
            let result = await adminActions.fetchUser(id);
            let dum = result.data.resp.result;
            setUserData(result.data.resp.result);
            let disname = "";
            let disemail = "";
            if (search("custom:display_name", dum.UserAttributes)) {
                disname =
                    search("custom:display_name", dum.UserAttributes).Value || null;
            }

            if (search(search("email", dum.UserAttributes))) {
                disemail = search("email", dum.UserAttributes).Value || null;
            }
            // let email = search("email", dum.UserAttributes).Value || null

            setEmail(disemail);
            setDisplayName(disname);
            setSpinner('');

            return result.data.resp.result;
        } catch (err) {
            console.error(err);
        }
    };

    const change = (i, e) => {
        userData.UserAttributes[i].Value = e.currentTarget.value;
        setUserData(userData);
    };

    const submit = async () => {
        try {
            let result = await adminActions.updateUser(id, {
                email: email,
                display_name: displayName
            });

            let response = result.data.resp.result;

            let config = {
                type: "success",
                title: "Success",
                message: response
            };
            dispatch({type: SHOW_APP_ALERT, alertConfig: config});

            return result.data.resp.result;
        } catch (err) {
            console.error(err);
        }
    };

    const search = (nameKey, myArray) => {
        if (myArray) {
            for (var i = 0; i < myArray.length; i++) {
                if (myArray[i].Name === nameKey) {
                    return myArray[i];
                }
            }
        } else {
            return {
                Value: ""
            };
        }
    };

    useEffect(() => {
        // Update the document title using the browser API

        async function fetchData() {
            // You can await here
            try {
                const response = await getUser();

                if (!response) {
                    // let config = {
                    //   open: true,
                    //   title: "Error",
                    //   message: "user not found"
                    // };
                    // dispatch({ type: SHOW_ALERT, alertConfig: config });
                }
            } catch (err) {
                console.error(err);
            }
        }

        fetchData().then();
    }, []);
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader>
                        <h4 className={classes.cardTitle}>
                            User <small> - {id}</small>
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            {spinner}
                            {userData.UserAttributes ?
                                <GridItem xs={6} sm={6} md={4}>
                                    <CustomInput
                                        labelText="User Id"
                                        id="float"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            defaultValue: " ",
                                            value: search("sub", userData.UserAttributes).Value,
                                            disabled: true
                                        }}
                                        onChange={x => change(0, x)}
                                    />
                                </GridItem>
                                : ""}
                            {userData.UserAttributes ?
                                <GridItem xs={6} sm={6} md={4}>
                                    <CustomInput
                                        labelText={"Email Verified"}
                                        id="float"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: search("email_verified", userData.UserAttributes)
                                                .Value,
                                            defaultValue: " ",
                                            disabled: true
                                        }}
                                    />
                                </GridItem> : ""}
                            {userData.UserAttributes ?
                                <GridItem xs={6} sm={6} md={4}>
                                    <CustomInput
                                        labelText={"Display Name"}
                                        id="float"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            // floatingLabelFixed: true,
                                            value: displayName,
                                            defaultValue: " "
                                        }}
                                        onChange={event => setDisplayName(event.target.value)}
                                    />
                                </GridItem>
                                : ""}
                            {userData.UserAttributes ?
                                <GridItem xs={6} sm={6} md={4}>
                                    <CustomInput
                                        labelText={"Email"}
                                        id="float"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            // floatingLabelFixed: true,
                                            value: email,
                                            defaultValue: " "
                                        }}
                                        onChange={event => setEmail(event.target.value)}
                                    />
                                </GridItem> : ""}
                            <GridItem xs={6} sm={6} md={4}>
                                <CustomInput
                                    labelText="Username"
                                    id="float"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <People/>
                                            </InputAdornment>
                                        ),
                                        floatingLabelFixed: true,
                                        value: userData["Username"],
                                        defaultValue: " ",
                                        disabled: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={4}>
                                <CustomInput
                                    labelText="User Created Date"
                                    id="float"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        floatingLabelFixed: true,
                                        value: userData["UserCreateDate"],
                                        defaultValue: " ",
                                        disabled: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={4}>
                                <CustomInput
                                    labelText="User Last Modified Date"
                                    id="float"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        floatingLabelFixed: true,
                                        value: userData["UserLastModifiedDate"],
                                        defaultValue: " ",
                                        disabled: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={4}>
                                <CustomInput
                                    labelText="Enabled"
                                    id="float"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        floatingLabelFixed: true,
                                        value: userData["Enabled"],
                                        defaultValue: " "
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={4}>
                                <CustomInput
                                    labelText="User Status"
                                    id="float"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        floatingLabelFixed: true,
                                        value: userData["UserStatus"],
                                        defaultValue: " ",
                                        disabled: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={12} style={{textAlign: "right"}}>
                                <RegularButton
                                    color="warning"
                                    onClick={() => {
                                        submit();
                                    }}
                                >
                                    Update
                                </RegularButton>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
            {/* {table} */}
        </GridContainer>
    );
}
