import React, {useEffect, useState} from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {ChatStyles} from "./ChatStyles";
import TableCell from "@material-ui/core/TableCell";
import {CHAT_GROUP_MEMBER_HEADERS} from "./ChatGroupConfigs";
import {IconButton, Input, InputLabel} from "@material-ui/core";
import {Add, ArrowBack, PeopleAlt} from "@material-ui/icons";
import {useHistory, useParams} from "react-router-dom";
import ChatMemberRaw from "./ChatMemberRaw";
import ChatService from "../../services/ChatService";
import {SHOW_APP_ALERT} from "../../action-types";
import {useDispatch} from "react-redux";
import RegularButton from "../../components/CustomButtons/RegularButton";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const useStyles = makeStyles(ChatStyles);

export default function ChatGroupMembers(props) {
    const classes = useStyles();
    const {group_id} = useParams();
    const [memberIds, setMemberIds] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();

    const [email, setEmail] = useState(null);
    useEffect(() => {
        if (history.location.occupants_ids) {
            setMemberIds(history.location.occupants_ids);
        }
        else {
            goToList();
        }
    }, [history]);


    const loadTableHeaders = () => {

        return CHAT_GROUP_MEMBER_HEADERS.map((h, i) => {
            return <TableCell align={h.align} key={i}>{h.name}</TableCell>
        });
    }
    const deleteMember = async member_id => {

        const res = await ChatService.deleteMember(group_id, member_id);
        if (res) {
            let tmp = [...memberIds];
            tmp = tmp.filter(item => item !== member_id);
            setMemberIds(tmp);
            let config = {
                type: "success",
                message: 'Member Deleted Successfully'
            };
            dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        }
        else {
            let config = {
                type: "error",
                message: 'Something went wrong'
            };
            dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        }

    }
    const deleteConfirmation = member_id => {
        let config = {
            type: "confirmation",
            message: 'Do you want to delete this user?',
            onConfirm: () => deleteMember(member_id)
        };
        dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
    }
    const loadRows = () => {
        return memberIds.map((mId, index) => {
            return <ChatMemberRaw group_id={group_id} member_id={mId} deleteConfirmation={deleteConfirmation}/>
        })
    }
    const goToList = () => {
        history.push('/admin/system-chat-integrations/');
    }
    const addMember = async () => {
        const res = await ChatService.addMember(group_id, {email});
        if (res['status']) {
            setMemberIds(res.data.occupants_ids);
            setEmail("");
            let config = {
                type: "success",
                message: 'Member Added Successfully'
            };
            dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        }
        else {
            let config = {
                title: "Error",
                type: "error",
                message: res['errors'].map(e => <p>{e}</p>)
            };
            dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        }
    }
    const keyPress = e => {
        if(e.keyCode == 13){
            addMember();
        }
    }
    const emailInputChange = e => {
       setEmail(e.target.value);
    }
    return <GridContainer>
        <GridItem md={12}>
            <RegularButton
                justIcon
                round
                color="white"
                // className={classes.floatRight}
                onClick={goToList}
            >
                <ArrowBack className={classes.icons} />
            </RegularButton>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
            <Card>
                <CardHeader>
                    <h4 className={classes.cardTitle}>Chat Group Members</h4>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <FormControl style={{width: "100%", paddingBottom: "24px"}} sx={{ m: 1, width: '25ch' }} variant="standard">
                                <InputLabel htmlFor="add-member">Email</InputLabel>
                                <Input
                                    id="add-member"
                                    type="text"
                                    value={email}
                                    onChange={emailInputChange}
                                    onKeyDown={keyPress}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={addMember}
                                            >
                                                <PersonAddIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <TableContainer>
                                <Table className={classes.table} component={Paper}>
                                    <TableHead>
                                        <TableRow>
                                            {loadTableHeaders()}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            loadRows()
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </GridItem>
    </GridContainer>
}
