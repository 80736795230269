import {APP_URL} from "../config/settings";
import * as axios from "axios";
import {API_URL} from "../config/api";
import {LOGIN, LOGIN_OK, LOGIN_NOT_OK} from "../common/action-types";
import { takeEvery, put, call, take, cancelled } from 'redux-saga/effects';
import {useDispatch, useSelector} from "react-redux";
import {componentDidMount} from 'react';


// axios.interceptors.response.use(function (response) {
//   // Any status code that lie within the range of 2xx cause this function to trigger
//   // Do something with response data

//   //Check expiration time on token from the front end,
//   //if token is expired, then just redo the login and 
//   //reset the idToken. D will hate this.
 
//  // console.log(response);
//   if (response.status==401){
//     console.log(response);
//     let payload = {
//      // refreshToken: localStorage.getItem('refreshToken'),
//       username: localStorage.getItem('username'),
//       password: localStorage.getItem('password')
//     };
//     // const url = API_URL.ADMIN_LOGIN;
//     // const response =  Http.postData(url, payload);
//     // console.log(response);

//   }
//  // return response;
// }, function (error) {
//   console.log(error);
    
//     // if (response.status == 200) {
//     //    dispatch({type: LOGIN_OK, payload:response});
//     // } else {
//     //    dispatch({type: LOGIN_NOT_OK, payload:response.err});
//     // }

//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error
//   return Promise.reject(error);
// });



// TODO axios improments
export default class Http {

  componentDidMount(){
    console.log("running?");
  }

  
  static async get(url) {
    console.log(url);
    console.log(url);
    const r = axios.default.get(url, {
      headers: {
        'Authorization': localStorage.getItem('idToken')
      }
    }).catch(e => {
      console.log(e.response);
      console.log(e.response.status);
      localStorage.removeItem('idToken');
      window.location.replace(APP_URL + "/#/auth/login");
    });
    console.log(r);
    this.statusHandler(r);
    return r;
  }
  static async postData2(url = '', data = {}) {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('idToken')
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    });
    return response;
  }
  static async postData(url = '', data = {}) {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('idToken')
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    });
    return await response.json();
  }
  static async post(url, data) {
    const r = await axios.default.post(url, data, {
      headers: {
        'Authorization': localStorage.getItem('idToken')
      }
    });
    this.statusHandler(r);
    return r;
  }
  static async put(url, data) {
    const r = await axios.default.put(url, data, {
      headers: {
        'Authorization': localStorage.getItem('idToken')
      }
    });
    this.statusHandler(r);
    return r;
  }
  static statusHandler(r) {
    console.log(r);
    if (r.hasOwnProperty("status")) {
      if (r.status === 403 || r.status === 401) {
        window.location.replace(APP_URL + "/#/auth/login");
      }
    }
  }
}
