import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import templateService from "../../services/templateService";
import GridContainer from "../../components/Grid/GridContainer";
import ImgComponent from "./cmps/ImgComponent";
import GridItem from "../../components/Grid/GridItem";
import defaultImage from "assets/img/image_placeholder.jpg";

export default function TemplateColumnData(props) {

    const store = useSelector(state => state.store);
    const [items, setItems] = useState([]);


    useEffect(() => {
        if (store) {
            loadFiles();
        }
    }, [store])

    const loadFiles = async () => {
       const count = store.columnData.ImageCount;
       let initArray = [];
       for (let i = 1; i <= count; i++) {
           initArray[i] = {
               URL: defaultImage,
               FileLabel: 'T' + i,
               NoImage: true,
               FileIndex: i
           };
        }

       const fileResp = await templateService.getAllFileShades({
            template_id: store.templateId,
            detail_id: store.selectedDetailsID,
            signed_url: 1
        });
       fileResp.forEach(fr => {
           if (!fr.hasOwnProperty('IsDeleted') || !fr.IsDeleted) {
               initArray[fr["FileIndex"]] = fr;
           }

       })
        setItems(initArray);

    }
    return (
        <GridContainer>
            <GridItem md={12}><h4>Manage {store.columnData.Name} for Template : {store.templateName}</h4></GridItem>
            {
                items.map((item, i) => {
                        return <GridItem lg={2} md={3} sm={4} key={i}><ImgComponent item={item} key={i} isImage={store.isImage} refresh = {loadFiles}/></GridItem>
                })
            }
        </GridContainer>
    )
}