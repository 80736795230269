import React, {useEffect, useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import {IconButton, TableCell} from "@material-ui/core";
import {CHAT_GROUP_MEMBER_HEADERS} from "./ChatGroupConfigs";
import {PeopleAlt} from "@material-ui/icons";
import ChatService from "../../services/ChatService";
import DeleteIcon from "@material-ui/icons/Delete";
import {SHOW_APP_ALERT} from "../../action-types";
import {useDispatch} from "react-redux";

export default function ChatMemberRaw({member_id, deleteConfirmation}) {
    const [cell, setCell] = useState({});

    const getGroupMembers = async mId => {
        return  await ChatService.getGroupMember(mId);
    }
    useEffect(() => {
        if (member_id) {
            getGroupMembers(member_id).then(d => {
                setCell(d);
            })
        }
    }, [member_id]);

    const formatCellValues = ({key}, item) => {
        if (item) {
            switch (key) {
                case "action":
                    return <IconButton size={"small"} onClick={() => deleteConfirmation(member_id)}>
                        <DeleteIcon fontSize={"small"}/>
                    </IconButton>
                default:
                    return item[key];
            }
        }
        else {
            return "";
        }

    }

    return <TableRow key={member_id}>
        {
            CHAT_GROUP_MEMBER_HEADERS.map((h, i) => {
                return <TableCell align={h.align} key={member_id + "-" +i}>
                    {formatCellValues(h, cell)}
                </TableCell>
            })
        }
    </TableRow>
}
