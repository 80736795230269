export function validate(value, rules) {
  let e = [];
  rules.forEach(rule => {
    const s = rule.search(":");
    const prefix = s > -1 ? rule.slice(0, s) : rule;
    const extra = rule.slice(s + 1);
    switch (prefix) {
      case "required":
        e = [...e, ...requiredValidation(value)];
        break;
      case "min":
        e = [...e, ...minValidation(value, extra)];
        break;
      case "password":
        e = [...e, ...passwordValidate(value)];
        break;
      case "maxLength":
        e = [...e, ...maxLengthValidate(value, extra)];
        break;
      case "email":
        e = [...e, ...emailValidation(value)];
        break;
      case "mobileNumber":
        e = [...e, ...mobileNumberValidation(value)];
        break;
    }
  });
  return e;
}
const mobileNumberValidation = value => {
  let e = [];
  if (value) {
    const reg = new RegExp("(^[07][0-9]{9}$)|(^[7][0-9]{8}$)");
    if (!reg.test(value)) {
      e.push("Invalid mobile number");
    }
  }
  return e;
};
const minValidation = (value, min) => {
  let e = [];
  if (value == null || parseInt(value, 10) < parseInt(min, 10)) {
    e.push("This field not be less than " + min);
  }
  return e;
};
const requiredValidation = value => {
  let e = [];
  if (value == null || value.toString().replace(/\s+/g, " ").length === 0) {
    e.push("This field is required");
  }
  return e;
};
const passwordValidate = value => {
  let e = [];
  if (value) {
    const strengthReg = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])"
    );
    const countReg = new RegExp("(?=.{8,})");
    if (!countReg.test(value)) {
      e.push("The password must be eight characters or longer");
    }
    if (!strengthReg.test(value)) {
      e.push(
        "The string must contain at least one lowercase, uppercase and numeric alphabetical character"
      );
    }
  }
  return e;
};
const maxLengthValidate = (value, lenght) => {
  let e = [];
  if (value == null || value.length > parseInt(lenght, 10)) {
    e.push("The character limit exceeded");
  }
  return e;
};
const emailValidation = value => {
  let e = [];
  if (value) {
    const strengthReg = new RegExp(
      "^[_a-z0-9-]+(.[a-z0-9-]+)@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$"
    );
    if (!strengthReg.test(value)) {
      e.push("Invalid email address");
    }
  }
  return e;
};
