import instance from "./dsdInstance";

const getAll = async (type) => {
   try {
       const r = await instance.get("subscription-service/plans?type=" + type);
       return r.data.data.result.data;
   }
   catch (e) {
     console.error(e);
    }
}
const get = async (id = "") => {
   try {
       const r = await instance.get('subscription-service/plans/' + id);
       return r.data.data.result;
   }
   catch (e) {
     console.error(e);
    }
}
const postPlans = async params => {
   return  await instance.post('subscription-service/plans', params);
}
const putPlans = async params => {
   return  await instance.put('subscription-service/plans', params);
}

export default {
    getAll: getAll,
    get: get,
    postPlans: postPlans,
    putPlans: putPlans
}