import React, {useEffect, useState} from 'react';
import RegularButton from "../../components/CustomButtons/RegularButton";
import {CHANNEL_TYPES} from "../../common/const";
import CardHeader from "../../components/Card/CardHeader";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {cardTitle} from "../../assets/jss/material-dashboard-pro-react";
import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";
import systemAppInfoService from "../../services/sytemAppInfo";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Button, InputLabel, MenuItem, Select} from "@material-ui/core";
import {COMMIT_TEMPLATE, SHOW_APP_ALERT} from "../../action-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import PropTypes from "prop-types";
import Moment from "react-moment";
import CustomInput from "../../components/CustomInput/CustomInput";
import {useInputProps} from "../../components/CustomInput/InputPropsHook";
import FormControl from "@material-ui/core/FormControl";
import InputErrors from "../../components/CustomInput/InputErrors";
import {useDispatch} from "react-redux";
const useStyles = makeStyles((theme) => ({
    cardTitle,
    ...styles,
    ...buttonStyle,
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));
function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const {count, page, rowsPerPage, onChangePage} = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};
export default function AppInfoComponent(props) {
    const tableHeaders = ["Version", "Action", "Modified Date"]
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedChannel, setSelectedChannel] = useState(CHANNEL_TYPES[0]);
    const [channelHistory, setChannelHistory] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, (channelHistory[selectedChannel] ? channelHistory[selectedChannel].length : 0) - page * rowsPerPage);
    const infoForm = {
        version: useInputProps({type: "text", rules: ["required"]}),
        action: useInputProps({type: "select", value: ""}),
    }

    useEffect(() => {
        if(selectedChannel && !channelHistory.hasOwnProperty(selectedChannel)) {
            loadChannelHistory(selectedChannel);
        }
    }, [selectedChannel]);

    const loadChannelHistory = async channel => {
        try {
            const res = await systemAppInfoService.getHistory(channel);
            let data = res.map(r => {
                const val = JSON.parse(r.Value);
                return {
                    date: r.LastModifiedDate,
                    version: val["VERSION"],
                    appAction: val["ACTION"]
                }
            })
            const cpChannelHistory = {...channelHistory}
            cpChannelHistory[selectedChannel] = data;
            setChannelHistory(cpChannelHistory);
        }
        catch (e) {
            console.error(e);
        }
    }
    const loadTypeButtons = () => {
        return CHANNEL_TYPES.map(tp => {
            return <RegularButton
                color={selectedChannel === tp ? 'info' : ''}
                key={tp}
                size={"sm"}
                onClick={() => {setSelectedChannel(tp)}}
            >
                {tp}
            </RegularButton>
        })
    }
    const loadTableHeaders = () => {

        return tableHeaders.map(h => {
            return <TableCell>{h}</TableCell>
        });
    }
    const getCurrentRows = () => {
        if (channelHistory.hasOwnProperty(selectedChannel)) {
            return channelHistory[selectedChannel];
        }
        else {
            return [];
        }
    }
    const loadRows = () => {
        let rows = getCurrentRows();

        return (rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
        ).map((row ,index) => {
            return <TableRow key={index}>
                <TableCell align={"left"}> {row["version"]}</TableCell>
                <TableCell align={"left"}>{row["appAction"]}</TableCell>
                <TableCell align={"left"}><Moment format={"YYYY-MM-DD HH:MM:SS"}>{row["date"]}</Moment></TableCell>
            </TableRow>

            });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const loadPagination = () => {

        return <TablePagination
            rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
            colSpan={tableHeaders.length}
            count={getCurrentRows().length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
        />
    }
    const clear = () => {
        infoForm.action.setValue("");
        infoForm.version.setValue("");
    }
    const checkValidity = () => {
        return (infoForm.action.doValidate() &
            infoForm.version.doValidate());
    };
    const submit = async () => {
        if(!checkValidity()) {
            return;
        }
        const data = {
            data: JSON.stringify({
                VERSION: infoForm.version.props.value,
                ACTION: infoForm.action.props.value,
            }),
            channel: selectedChannel
        }
        const res = await systemAppInfoService.put(data);
        let config = {
            type: "success",
            title: "Success",
            message: "App information saved!",
        };
        dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        loadChannelHistory(selectedChannel);
    }
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader>
                        <h4 className={classes.cardTitle}>App Version Configurations</h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={8}>
                                {loadTypeButtons()}
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={8}>
                                <h4>Create form</h4>
                                <GridContainer>
                                    <GridItem sm={4}>
                                        <CustomInput
                                            error={!infoForm.version.validity.isValid}
                                            labelText="Version...*"
                                            id="idVersion"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{...infoForm.version.props}}
                                        />
                                        <InputErrors errors={infoForm.version.validity.errors} />

                                    </GridItem>
                                    <GridItem sm={4}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="templateType"
                                                className={classes.selectLabel}
                                            >
                                                Action
                                            </InputLabel>
                                            <Select
                                                value={infoForm.action.props.value}
                                                onChange={infoForm.action.props.onChange}
                                                MenuProps={{className: classes.selectMenu}}
                                                classes={{select: classes.select}}
                                                inputProps={{
                                                    name: "Action",
                                                    id: "action"
                                                }}
                                            >
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelectedMultiple
                                                    }}
                                                    value=""
                                                >
                                                    NONE
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelectedMultiple
                                                    }}
                                                    value="UPDATE"
                                                >
                                                    UPDATE
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem sm={4}>
                                        <RegularButton
                                            style={{marginTop: "20px"}}
                                            color="warning"
                                            onClick={() => {
                                                clear();
                                            }}
                                            size={"sm"}
                                        >
                                            Clear
                                        </RegularButton>
                                        <RegularButton
                                            style={{marginTop: "20px"}}
                                            color="success"
                                            onClick={() => {
                                                submit();
                                            }}
                                            size={"sm"}
                                        >
                                            Save
                                        </RegularButton>

                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={8}>
                                <h4>History</h4>
                                <TableContainer>
                                    <Table className={classes.table} component={Paper}>
                                        <TableHead>
                                            <TableRow>
                                                {loadTableHeaders()}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {
                                                loadRows()
                                            }
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 53 * emptyRows}}>
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                {
                                                    loadPagination()
                                                }
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </GridItem>

                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}