export class S3FileService {
    static upload = async (s3Url, newFile) => {
        const data = new FormData();
        data.append('file', newFile);
        return await fetch(s3Url, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': newFile.type,
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: newFile
        });
    }}