import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { IconButton } from "@material-ui/core";
import { VpnKey } from "@material-ui/icons";
import GridItem from "../../../components/Grid/GridItem";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputErrors from "../../../components/CustomInput/InputErrors";
import GridContainer from "../../../components/Grid/GridContainer";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { useInputProps } from "../../../components/CustomInput/InputPropsHook";
import RegularButton from "../../../components/CustomButtons/RegularButton";
import userService from "../../../services/userService";
import { SHOW_APP_ALERT } from "../../../action-types";
import {useDispatch} from "react-redux";

const useStyles = makeStyles({
  ...styles,
  title: {},
  selectFormController: {
    // paddingTop: "12px",
    textAlign: "left",
    color: "#bababa"
  }
});
export default function ChangePasswordDialog({ email }) {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const password = useInputProps({
    type: "password",
    rules: ["required"],
    value: ""
  });
  const passwordCheck = useInputProps({
    type: "password",
    rules: ["required"],
    value: ""
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const resetForm = () => {
    password.props.value = "";
    passwordCheck.props.value = "";
  };
  const validatePassword = password => {
    const minLength = 8;
    const maxLength = 100;

    // Regular expressions for password criteria
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    // Check the length
    if (password.length < minLength || password.length > maxLength) {
      return "Password should be more than 8 characters";
    }
    if (!hasNumbers) {
      return "Password must contain at least one number.";
    }
    if (!hasSpecialChars) {
      return "Password must contain at least one special character.";
    }
    // Check for other criteria
    if (!hasUpperCase) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!hasLowerCase) {
      return "Password must contain at least one lowercase letter.";
    }

    return "valid";
  };
  const submit = async () => {
    let params = { username: email, password: "" };
    let validity = true;
    if (password.doValidate() && passwordCheck.doValidate()) {
      if (password.props.value === passwordCheck.props.value) {
        const patternValidate = validatePassword(password.props.value);
        if (patternValidate !== "valid") {
          password.setErrors([patternValidate]);
          validity = false;
        } else {
          params.password = password.props.value;
        }
      } else {
        passwordCheck.setErrors(["The password doesn't match"]);
        validity = false;
      }
    }
    if (validity) {
      const resp = await userService.changePassword(params);
      try {
        if (resp.status === 200) {
          dispatch({
            type: SHOW_APP_ALERT,
            alertConfig: {
              type: "success",
              message: "Password Changed Successfully"
            }
          });
          resetForm();
        } else {
          console.error(resp);
          dispatch({
            type: SHOW_APP_ALERT,
            alertConfig: {
              type: "error",
              message: "Something went wrong!"
            }
          });
        }
        setOpen(false);
      } catch (e) {
        console.error(e);
      }
    }
  };
  return (
    <>
      <IconButton
        size={"small"}
        onClick={() => {
          handleClickOpen(true);
        }}
      >
        <VpnKey fontSize={"small"} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <GridContainer>
            <GridItem xs={12}>
              <h4 className={classes.title}>Change password</h4>
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                error={!password.validity.isValid}
                labelText="New Password...*"
                id="userName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{ ...password.props }}
              />
              <InputErrors errors={password.validity.errors} />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                error={!passwordCheck.validity.isValid}
                labelText="Confirm Password...*"
                id="userName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{ ...passwordCheck.props }}
              />
              <InputErrors errors={passwordCheck.validity.errors} />
            </GridItem>
            <GridItem xs={12}>
              <RegularButton
                className={classes.floatRight}
                color="success"
                onClick={() => {
                  submit();
                }}
              >
                Save
              </RegularButton>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}
