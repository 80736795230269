import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import GridList from '@material-ui/core/GridList';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import RegularButton from "components/CustomButtons/RegularButton.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import adminActions from "../../services/admin";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import "./templateOverviewStyling.css";
import {
  trackWindowScroll
} from "react-lazy-load-image-component";
import "tui-image-editor/dist/tui-image-editor.css";
import Spinner from '../Initials/spinner.js'
import {API_URL} from "../../config/api";
import Http from "../../utils/Http";
import {SHOW_APP_ALERT} from "../../action-types";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
   overflow: 'auto',
   overflowY: 'hidden',
   height: 500
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },

}));

function TemplateOverviewComponent(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  // eslint-disable-next-line react/prop-types
  //const { id } = props.match.params;
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(<Spinner fetching={true}/>);

  const getToken = () => {
    let token = localStorage.getItem("idToken");
    return token;
  };

  const activeRoute = routeName => {
    // window.location.href = process.env.REACT_APP_BASE_URL + routeName;
    props.history.push({
      pathname: routeName,
      params: ""
    });
  };

  let globalArray = [];

  const templateForAPI = {
    template_id: props.templateId
  };

function onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
}

const displayImageEditor = (file) => {
  document.querySelector('.image-editor').style.display="block";
  document.querySelector('.image-editor-box').style.display="block";
  var ImageEditor = require('tui-image-editor');
  var FileSaver = require('file-saver'); //to download edited image to local. Use after npm install file-saver
  var instance = new ImageEditor(document.querySelector('#tui-image-editor'), {
       includeUI: {
           loadImage: {
               path: file,
               name: 'SampleImage'
           },
           menu:['filter'],
           menuBarPosition: 'top'
       },
      cssMaxWidth: 700,
      cssMaxHeight: 500,
      selectionStyle: {
          cornerSize: 20,
          rotatingPointOffset: 70
      }
  });
}

const mapOverview = (array, tempDetailId) =>{
  let codeBlocks = [];
  let finalCodeBlocks = [];

//Sort array to be in order of file index.
let sortedArray = array.sort((a,b)=>a.FileIndex-b.FileIndex);
let detailObject = props.allDetails.find(e => e.Id === sortedArray[0].DetailId);
let isObject = detailObject.IsImage;
if (isObject == 0){
  isObject = true;
}
else{
  isObject = false;
}

finalCodeBlocks.push(<GridContainer direction="row" alignItems="center" spacing={2}>      
<h4 className={classes.cardTitle}><strong>{sortedArray[0].DetailName}</strong></h4>
</GridContainer>);

  for (let i=0;i<=sortedArray.length-1;i++){
    if (sortedArray[i].IsDeleted === 0){

      codeBlocks.push(<GridItem xs={3} className="images">
        <ImageUpload
          p={10}
          scrollPosition={window.pageYOffset}
          displayImageEditor={displayImageEditor}
          id={array[i].FileId}
          fileIndex = {parseInt(sortedArray[i].FileIndex)}
          url = {array[i].URL}
          isObject = {isObject}
        />{" "}
      </GridItem>)
    }
  }

finalCodeBlocks.push(<div className={classes.root}><GridList className={classes.gridList} cols={2.5}>{codeBlocks}</GridList></div>);
globalArray.push(finalCodeBlocks);
//return finalCodeBlocks;
}

const groupFilesById = (response) => {

    //Grab every detail Id, put them into allIds.
  let allIds = [];
  for (let i=0;i<=response.length-1;i++){
    allIds.push(response[i].DetailId);
  }
//Grab only unique ids, since they reflect the detail headers.
let ids = allIds.filter(onlyUnique);

  let tempDetailId;
  for (let k=0;k<=ids.length-1;k++){
    let array = [];
    tempDetailId = ids[k];
    for (let i = 0;i<=response.length-1;i++){
      if (response[i].DetailId == ids[k]){
        array.push(response[i]);
      }
    }
  //call function to mapImage components here.
   mapOverview(array, tempDetailId);  
  } 
  if (globalArray.length>0){
    setData(globalArray);
    setSpinner('');
  }
  else{
    setData("No files available");
    setSpinner('');
  }
}
  const loadAllTemplateDetails = async () => {
    try {
      let result = await adminActions.getTemplateAllDetails(
        templateForAPI
      );
      groupFilesById(result.data.data.result);
     // let finalResult = getFileIDs(result);
     // return finalResult;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    //add information here to render when view loads or perform functions.
    loadAllTemplateDetails();

  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <h4 className={classes.cardTitle}>
             {props.templateName} Template Overview
            </h4>
          </CardHeader>
          <CardBody>
          <div className = "image-editor-box" style={{height:800}}><div className = "image-editor" id="tui-image-editor" style={{height: "700px"}}><canvas></canvas></div></div>
            {spinner}
            {data}
            <GridItem xs={12} sm={12} md={12}>
              <RegularButton
                onClick={() => {
                  props.history.goBack();
                }}
              >
                Go Back
              </RegularButton>
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
const mapStateToProps = state => {
  return {
    templateId: state.store.templateId,
    templateName: state.store.templateName,
    templateStatus: state.store.templateStatus,
    templateType: state.store.templateType,
    uploadedFile: state.store.uploadedFile,
    file_name: state.store.file_name,
    smileDonator: state.store.smileDonator,
    janHaito: state.store.janHaito,
    templateOverviewDetails: state.store.templateOverviewDetails,
    allDetails: state.store.allDetails,
  };
};
const wrapperFunction = connect(mapStateToProps);
const finalComponent = wrapperFunction(TemplateOverviewComponent);

TemplateOverviewComponent.propTypes = {
  history: PropTypes.array,
  push: PropTypes.func,
  templateId: PropTypes.number,
  templateName: PropTypes.string,
  templateStatus: PropTypes.string,
  templateType: PropTypes.string,
  templateOverviewDetails: PropTypes.array
};

export default trackWindowScroll(finalComponent);
