import React, {useEffect, useState} from 'react';
import SmallEditNumber from "./Cmps/SmallEditNumbr/SmallEditNumber";
import commonStyle from "../../common/styles/common";
import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
const useStyles = makeStyles(commonStyle);

export default function DCatHistoryBody({si, update}) {
    const classes = useStyles();

    return <GridContainer>
        <GridItem md={6}>
            {
                si.Status
                    ? <SmallEditNumber value={si.DCatOrders} id={si.Id} label={"Orders"}
                                       update={update} paramKey="dcat_orders"/>
                    : <span>  Orders :&nbsp; <b>{si.DCatOrders}</b></span>
            }
        </GridItem>
        <GridItem md={6} className={classes.contentRight}>
            {
                si.Status
                    ? <SmallEditNumber value={si.DCatOrdersUsed} id={si.Id} label={"Used Orders"}
                                       update={update} paramKey="dcat_orders_used"/>
                    : <span>  Orders :&nbsp; <b>{si.DCatOrdersUsed}</b></span>
            }
        </GridItem>
    </GridContainer>
}