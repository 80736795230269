import React, {createContext, useCallback, useState} from "react";
import {InfoService} from "../../services/InfoService";

export const InfoContext = createContext({
    editable: null,
    changeEditable: () => {},
    formOpen: false,
    changeFormOpen: () => {},
    info: null,
    loadInfo: () => {},
    config: null,
    loadConfig: () => {}
});

export default function InfoProvider({children}) {
    const [editable, setEditable] = useState(null);
    const [formOpen, setFormOpen] = useState(false);
    const [info, setInfo] = useState(null);
    const [config, setConfig] = useState(null);

    const changeEditable = row => {
        setEditable(row)
    }
    const changeFormOpen = isOpenForm => {
        setFormOpen(isOpenForm)
    }

    const loadInfo = () => {
        InfoService.list().then(res => {
            setInfo(res.data);
        })

    }
    const loadConfig = () => {
        InfoService.getConfig().then(data => {
            setConfig(data);
        })

    }
    const contextValue = {
        editable: editable,
        changeEditable: useCallback(row => {
            changeEditable(row);
        },[]),
        formOpen: formOpen,
        changeFormOpen: useCallback (isOpenForm => {
            changeFormOpen(isOpenForm)
        }, []),

        info: info,
        loadInfo: useCallback(() => {
           loadInfo();
        }, []),
        config: config,
        loadConfig: useCallback(() => {
            loadConfig();
        }, [])
    }

    return <InfoContext.Provider value={contextValue}>
        {children}
    </InfoContext.Provider>
}
