import React, { useEffect} from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import EditAdminUser from "components/AdminUser/EditAdminUser";
import ViewAdminUserEvents from "components/AdminUser/ViewAdminUserEvents";

export default function AdminUser(props) {
  const { id } = props.match.params;

  useEffect(() => {
    // Update the document title using the browser API

  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        {props.history.location.pathname === "/admin/edit/"+id ? <EditAdminUser id={id} />: null}
        {props.history.location.pathname === "/admin/view/admin-user"? <ViewAdminUserEvents />: null}
        </GridItem>
      </GridContainer>
    </div>
  );
}
