import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import userActions from "../../services/userService";

import { makeStyles } from "@material-ui/core/styles";
import ReactTable from "react-table";
// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Close from "@material-ui/icons/Close";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Spinner from '../../components/Initials/spinner.js'

// core components
import RegularButton from "components/CustomButtons/RegularButton.js";

import {SHOW_APP_ALERT} from "../../action-types";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

export default function AppUserList(props) {
  const dispatch = useDispatch();
  // const classes = useState([]);
  const classes = useStyles();
  const [spinner, setSpinner] = useState("");

  let [tableData, setTableData] = useState([]);
  let [open, setOpen] = useState(false);
  let [deleteUsername, setDeleteUser] = useState(null);
  let [deleteUserId, setDeleteUserId] = useState(null);

  const activeRoute = routeName => {
    // window.location.href = process.env.REACT_APP_BASE_URL + routeName;
    props.history.push({
      pathname: routeName,
      params: ''
    });

    props.history.go();
  };

  const getUsers = async () => {
    try {
      setSpinner(<Spinner fetching={true}/>)
      let result = await userActions.fetchUsers();
      tableData = result.data.data.result.data;
      setSpinner("");
      return result.data.data.result.data;
    } catch (err) {
      console.error(err);
    }
  };

  const deleteUser = async () => {
    try {
      let result = await userActions.deleteUser({
        username: deleteUsername,
        user_id: deleteUserId
      });

      tableData = result.data.data.result;

      setDeleteUser(null);
      setDeleteUserId(null);
      handleClose();

      let newUserList = await getUsers();
      setTableData(newUserList);

      return result.data.data.result;
    } catch (err) {
      console.error(err);
    }
  };

  const handleOpen = (uid, uname) => {
    setDeleteUser(uname);
    setDeleteUserId(uid);
    setOpen(true);
  };

  const handleClose = () => {
    setDeleteUser(null);
    setDeleteUserId(null)
    setOpen(false);
  };

  const viewUserCell = (row) =>{
    let userId = tableData[row.index].UserId;

    return (
      <span title={"View User "+row.value}>
      <a 
      className="userCell"
      onClick={() =>
        activeRoute("view/" + userId)
      }>{row.value}</a></span>
    );
  }


  useEffect(() => {
    // Update the document title using the browser API

    async function fetchData() {
      // You can await here
      try {
        const response = await getUsers();

        if (response) {
          setTableData(response);
        } else {
          let config = {
            type: "error",
            title: "Error",
            message: "something went wrong.please try again"
          };
          dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        }
      } catch (err) {
        console.error(err);

      }
    }

    fetchData().then();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <h4 className={classes.cardTitle}>
                App Users<small> - list</small>
              </h4>
            </CardHeader>
            <CardBody>
              {/* <CustomTable tableHead={tabelHead} tableData={tableData} /> */}
              {spinner}
              <ReactTable
                data={tableData.map((prop, key) => {
                  return {
                    id: key,
                    name: prop.Name,
                    email: prop.Email,
                    clinic: prop.Clinic,
                    clinicType: prop.ClinicType,
                    phoneNumber: prop.MobileNumber,
                    status: prop.Status,
                    actions: (
                      // we've added some custom button actions
                      <div className="actions-right">
                        {/* use this button to add a edit kind of action */}
                        <span title={"Edit User "+prop.Name}>
                        <RegularButton
                          justIcon
                          round
                          simple
                          onClick={() =>
                            activeRoute("edit/" + prop.UserName)
                          }
                          color="warning"
                          className="edit"
                        >
                          <Dvr />
                        </RegularButton></span>{" "}
                        {/* use this button to remove the data row */}
                        <span title={"Delete User "+prop.Name}>
                        <RegularButton
                          justIcon
                          round
                          simple
                          onClick={() => handleOpen(prop.UserId, prop.UserName)}
                          color="danger"
                          className="remove"
                        >
                          <Close />
                        </RegularButton></span>{" "}
                      </div>
                    )
                  };
                })}
                filterable
                columns={[
                  {
                    Header: "Name",
                    accessor: "name",
                    Cell: row => viewUserCell(row)
                  },
                  {
                    Header: "Email",
                    accessor: "email"
                  },
                  {
                    Header: "Clinic",
                    accessor: "clinic"
                  },
                  {
                    Header: "Clinic Type",
                    accessor: "clinicType"
                  },
                  {
                    Header: "Phone Number",
                    accessor: "phoneNumber"
                  },
                  {
                    Header: "Status",
                    accessor: "status"
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* <button type="button" onClick={handleOpen}>
        react-transition-group
      </button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h3 id="transition-modal-title">Alert</h3>
            <p id="transition-modal-description">
              Do you wan't to delete this user?
            </p>
            <RegularButton onClick={() => deleteUser()} color={"danger"}>
              yes
            </RegularButton>{" "}
            <RegularButton onClick={() => handleClose()}>no</RegularButton>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
