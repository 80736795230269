export const KEY = {
    TYPE_DESIGN4ME: "DESIGN4ME",
    TYPE_PRIMARY: "PRIMARY",
    TYPE_DCAT: "DCAT",
    TYPE_DRIVE: "DRIVE"
}
export const HEADERS = [
    {name: 'Title', key: 'Title', align: 'left'},
    {name: 'Description', key: 'Description', align: 'left'},
    {name: 'Stripe Id', key: 'StripeId', align: 'left'},
    {name: 'Primary Cost', key: 'PrimaryCost', align: 'right'},
    {name: 'Secondary Cost', key: 'SecondaryCost', align: 'right'},
    {name: 'Days', key: 'Days', align: 'right'},
    {name: 'Is Trial', key: 'IsTrial', align: 'center'},
    {name: 'No. of Designs', key: 'Designs', align: 'center', hideFrom: KEY.TYPE_PRIMARY, type: KEY.TYPE_DESIGN4ME},
    {name: 'Orders', key: 'DCatOrders', align: 'center', hideFrom: KEY.TYPE_PRIMARY, type: KEY.TYPE_DCAT},
    {name: 'Storage', key: 'Storage', align: 'center', hideFrom: KEY.TYPE_PRIMARY, type: KEY.TYPE_DRIVE},
    {name: 'Price Plan', key: 'pricePlane', align: 'center', hideFrom: KEY.TYPE_PRIMARY},
    {name: 'Status', key: 'Status', align: 'center'},
    {name: 'Actions', key: 'action', align: 'right'}
];
export const STATUS_CONFIG = {
    1: {text: "Active", color: "success"},
    0: {text: 'Inactive', color: "danger"}
};
export const TRIAL_MAP = {
    0: "No",
    1: "Yes",
}

export const PLANE_TYPES = [
    {key: KEY.TYPE_PRIMARY, display: "Primary"},
    {key: KEY.TYPE_DESIGN4ME, display: "Design4Me"},
    {key: KEY.TYPE_DCAT, display: "DiagnoCAT"},
    {key: KEY.TYPE_DRIVE, display: "Drive"}
]

export const PRICE_PLANS = [
    {key: "onetime" , display: "Onetime"},
    {key: "M" , display: "Monthly"},
    {key: "Y" , display: "Yearly"},
]