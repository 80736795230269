import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import RegularButton from "components/CustomButtons/RegularButton.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ReactTable from "react-table";
import CalendarInput from '../CalendarInput/CalendarInput'
import ServerPagination from '../ServerPagination/ServerPagination'
import userActions from "../../services/userService";
import CachedIcon from '@material-ui/icons/Cached';
import moment from 'moment';
import {LIMIT, CONFIG} from "./appUserAuditConstants.js";
import "./ViewAppUserEvents.css"
import {SHOW_APP_ALERT} from "../../action-types";


export default function ViewAppUserEvents(props) {
  const dispatch = useDispatch();
  let [tableData, setTableData] = useState();
  let [leftArrow, setLeftArrow] = useState("hidden");
  let [rightArrow, setRightArrow] = useState("hidden");
  let [endDate, setEndDate] = useState();
  let [startDate, setStartDate] = useState();
  let [endValue, setEndValue] = useState();
  let [startValue, setStartValue] = useState();
  let [startDateError, setStartDateError] = useState(false);
  let [endDateError, setEndDateError] = useState(false);
  let [nextKey, setNextKey] = useState();
  let [keys, setKeys] = useState([""]);
  let [index, setIndex] = useState(0);
  const { id, goBack } = props;

  let uniqid = require('uniqid');

  const updateStartDate = (date) =>{
    setStartValue(date);
    let timestamp = moment(date).startOf('day').format('X')*1000;
    if (timestamp<moment().unix()*1000){
      if(timestamp<endDate || !endDate){
        setStartDate(timestamp);
        setStartDateError(false);
      }
      else{
        setStartDateError(true);
      }
    }
    else{
      setStartDateError(true);
    }
  }

  const updateEndDate = (date) =>{
    setEndValue(date);
    let timestamp = moment(date).endOf('day').format('X')*1000;
    if (timestamp<moment().unix()*1000){
      if(timestamp>startDate || !startDate){
        setEndDate(timestamp);
        setEndDateError(false);
      }
      else{
        setEndDateError(true);
      }
    }
    else{
      setEndDateError(true);
    }
  }

  const columns = [
    {
      Header: "Date",
      accessor: "timestamp",
      filterable: false,
      sortable: false
    },
    {
      Header: "Event",
      accessor: "event",
      filterable: false,
      sortable: false
    },
    {
      Header: "Result",
      accessor: "result",
      filterable: false,
      sortable: false
    },
    {
      Header: "Device",
      accessor: "device",
      filterable: false,
      sortable: false
    },
    {
      Header: "IP",
      accessor: "ip",
      filterable: false,
      sortable: false
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: row => locationCell(row),
      filterable: false,
      sortable: false
    }
  ];

  const locationCell = (row) =>{
    return(
      <div className = "actions-right">{row.value}</div>
    )
  }

  const updateTable = async () =>{

    if (startDateError === true || endDateError === true){
      console.log(startValue, endValue);
      setStartValue("");
      setStartDate(null);
      setStartDateError(false);
      setEndValue("");
      setEndDateError(false);
      setEndDate(null);
    }

    if (id && startDateError===false && endDateError ===false){
      try{
        userActions.getUserDetails(id, endDate, startDate)
        .then(result =>{
          let data=result.data.data.result.Data;
          if (result.data.data.result.LastKey){
            setNextKey(result.data.data.result.LastKey);
            setRightArrow('visible');
            setKeys([""]);
            setIndex(0);
            setLeftArrow('hidden');
          }
          else{
            setRightArrow('hidden');
            setKeys([""]);
            setIndex(0);
            setLeftArrow('hidden');
          }
  
          setNextKey(result.data.data.result.LastKey);
          return {data: data, key: result.data.data.result.LastKey};
        }).then(result =>{
          setTableData(result.data.map((prop) => {
            return {
              timestamp: moment(prop['TimeStamp'])._d.toString(),
              event: prop["Event"],
              result: prop["Result"],
              device: prop["Device"],
              ip: prop["IP"],
              location: prop["Location"]
            }
          }
     ));
        })
      }
      catch{
        dispatch({ type: SHOW_APP_ALERT, alertConfig: CONFIG });
      }
    }
    else{
      dispatch({ type: SHOW_APP_ALERT, alertConfig: CONFIG });
    }
  }

  const getPage = async (string) =>{
    if (nextKey !== undefined && keys.includes(nextKey) === false){
      keys.push(nextKey);
    }
    try{
      let key;
      if (string === "next"){
          setLeftArrow("visible");
          let newIndex = index+1;
          key = keys[newIndex];
          setIndex(newIndex);
      }
      else if (string === "previous"){
        setRightArrow("visible");
        let newIndex = index-1;
          key=keys[newIndex];
          setIndex(newIndex);
          if(keys[newIndex] === ""){
            setLeftArrow("hidden");
          }
      }
  
      if (!startDate){
        setStartDate("");
      }
      else if (!endDate){
        setEndDate("");
      }

      userActions.getUserDetails( id, endDate, startDate, key)
      .then(result =>{
        let data=result.data.data.result.Data;
        if (string === "next"){
          if (keys.includes(result.data.data.result.LastKey) === false){
            if (result.data.data.result.LastKey !== undefined){
              keys.push(result.data.data.result.LastKey);
              setRightArrow("visible");
            }
            else {
              setRightArrow("hidden");
            }
          }
        }
        return data;
      }).then(result =>{
        setTableData(result.map((prop) => {
          return {
            timestamp: moment(prop['TimeStamp'])._d.toString(),
            event: prop["Event"],
            result: prop["Result"],
            device: prop["Device"],
            ip: prop["IP"],
            location: prop["Location"]
          }
        }
   ));
      })
    }
    catch{
      console.log("error");
    }
  }

//To get intial data, just pass token and id.
  useEffect(() => {
    const fetchUserDetails = async () =>{
      try{
          userActions.getUserDetails(id)
          .then(result =>{
            let data=result.data.data.result.Data;
            if (result.data.data.result.LastKey !== undefined){
              keys.push(result.data.data.result.LastKey);
              setRightArrow("visible");
            }
            else{
              setRightArrow("hidden");
            }


            return data;
          }).then(result =>{
            setTableData(result.map((prop) => {
              return {
                timestamp: moment(prop['TimeStamp'])._d.toString(),
                event: prop["Event"],
                result: prop["Result"],
                device: prop["Device"],
                ip: prop["IP"],
                location: prop["Location"]
              }
            }
       ));
          })
     }
      catch{
        console.log("error");
      }
    };
    fetchUserDetails();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader>
              User ID: {id}
        </CardHeader>
        <CardBody>
        <GridItem xs={12}>  
        <form className='dateContainer' noValidate>
        <GridItem xs={12} sm={7}>
        <CalendarInput 
          startValue={startValue}
          endValue={endValue}
          updateStartDate={updateStartDate} 
          updateEndDate={updateEndDate} 
          startDateError={startDateError}
          endDateError={endDateError}  
          />
        </GridItem>
        <GridItem xs={12} sm={1}>
              <CachedIcon
              className="icon"
              style={{marginTop: "10px", fontSize: "30px"}}
              onClick={() => {
                        updateTable();
                     }} />
        </GridItem>
        </form>
        </GridItem>  
        <GridItem xs={12}>
          <div className="pagination">
            <ServerPagination
              key={uniqid()} 
              getPage={getPage}
              leftArrow={leftArrow}
              rightArrow={rightArrow} />
          </div>
        </GridItem>
         <ReactTable
         data={tableData}
         columns={columns}
         defaultPageSize={LIMIT}
         showPaginationTop = {false}
         showPaginationBottom={false}
         className="-striped -highlight"
               />
        </CardBody>
      </Card>
      <GridItem xs={12} sm={12} md={12}>
        <RegularButton
          className='button'
          onClick={() => {
            goBack();
          }}
        >
          Go Back
        </RegularButton>
        </GridItem>
    </GridItem>
    </GridContainer>
  );
}
