export const ALERT_MESSAGES ={
    'DELETE_SUCCESS': {
        type: "success",
        message: 'Info deleted successfully',
    }
}
export const ACTIVE_STATUS = {
    1: "Active",
    0: "Inactive"
}
export const DATA_TYPES = {
    S3: "S3",
    DATE_TIME: "DATE_TIME",
    STRING: "STRING",
    CURRENCY: "CURRENCY",
    NUMBER: "NUMBER",
    STATUS: "STATUS",
    HTML: "HTML",
    ACTIONS: "ACTIONS",
    URLs: "URls",
    DEVICE: "DEVICE",
}
export const TABLE_HEADER_CONFIG = {

    DEFAULT: [
        { Name: "Name", Align: "left" , Key: "Name", type: DATA_TYPES.STRING},
        { Name: "Code", Align: "left" , Key: "Code", type: DATA_TYPES.STRING},
        { Name: "Device", Align: "left" , Key: "Device", type: DATA_TYPES.DEVICE},
        { Name: "Image", Align: "left" , Key: "FileName", type: DATA_TYPES.S3},
        { Name: "URl", Align: "left" , Key: "URLs", type: DATA_TYPES.URLs},
        // { Name: "UpdatedAt", Align: "left" , Key: "UpdatedAt", type: DATA_TYPES.DATE_TIME},
        { Name: "Status", Align: "left" , Key: "Status", type: DATA_TYPES.STATUS},
        { Name: "Actions", Align: "left" , Key: "Action", type: DATA_TYPES.ACTIONS},

    ]
}
