import React, {useEffect, useState} from "react";
import ChatService from "../../services/ChatService";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {ChatStyles} from "./ChatStyles";
import TableCell from "@material-ui/core/TableCell";
import {CHAT_GROUP_HEADERS} from "./ChatGroupConfigs";
import {HEADERS} from "../Plans/PlanConfig";
import {IconButton} from "@material-ui/core";
import {Add, EditRounded, PeopleAlt} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import RegularButton from "../../components/CustomButtons/RegularButton";

const useStyles = makeStyles(ChatStyles);

export default function ChatGroupList(props) {
    const classes = useStyles();
    const history = useHistory();
    const [items, setItems] = useState([]);
    useEffect(() => {
        getGroups();
    }, []);

    const getGroups = async () => {
        const res = await ChatService.getAllGroups({type: 2, limit: 100, page: 0});
        setItems(res.items);
    }
    const loadTableHeaders = () => {

        return CHAT_GROUP_HEADERS.map((h, i) => {
            return <TableCell align={h.align} key={i}>{h.name}</TableCell>
        });
    }
    const loadRows = () => {
        return items.map((item, index) => {
            return <TableRow key={index}>
                {
                    CHAT_GROUP_HEADERS.map((h, i) => {
                        return <TableCell align={h.align} key={index + i}>
                                {formatCellValues(h, item)}
                        </TableCell>
                    })
                }
            </TableRow>
        })
    }
    const goToEdit = item => {
        history.push({pathname: '/admin/chat-groups-members/' + item._id, occupants_ids: item.occupants_ids});
    }
    const goToAdd = () => {
        history.push({pathname: '/admin/chat-groups-form'});
    }
    const formatCellValues = ({key}, item) => {
        switch (key) {
            case "user_count":
                return item.occupants_ids.length
            case "action":
                return <IconButton size={"small"} onClick={() => {
                    goToEdit(item)
                }}>
                    <PeopleAlt fontSize={"small"}/>
                </IconButton>
            default:
                return item[key];
        }
    }

        return <GridContainer>
            <GridItem md={12}>
                <RegularButton
                    justIcon
                    round
                    color="success"
                    // className={classes.floatRight}
                    onClick={goToAdd}
                >
                    <Add className={classes.icons} />
                </RegularButton>
            </GridItem>
        <GridItem xs={12} sm={12} md={12}>
            <Card>
                <CardHeader>
                    <h4 className={classes.cardTitle}>Chat Group list</h4>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <TableContainer>
                                <Table className={classes.table} component={Paper}>
                                    <TableHead>
                                        <TableRow>
                                            {loadTableHeaders()}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            loadRows()
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </GridItem>
    </GridContainer>
}
