import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import RegularButton from "../../components/CustomButtons/RegularButton";

import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import adminActions from "../../services/admin";
import {COMMIT_TEMPLATE, SHOW_APP_ALERT} from "../../action-types";
import { useDispatch} from "react-redux";
import {Button, MenuItem, Select, Switch} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import {CloudUpload} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import {TEMPLATE_AGE_TYPES, TEMPLATE_FORM_TYPES, TEMPLATE_EMBRASURES_TYPES, TEMPLATE_INCISAL_EDGE_TYPES, TEMPLATE_BUCAL_SURFACE_TYPES} from "../../common/const";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles1 = makeStyles((theme) => ({
    cardTitle,
    ...styles,
    ...buttonStyle,
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const {count, page, rowsPerPage, onPageChange} = props;
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
              <FirstPageIcon/>
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                <KeyboardArrowLeft/>
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight/>
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon/>
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});

const staticHeaders1 = [
    { Name: "Name", Align: "left" , Static: true, Key: 'Name'},
    { Name: "Type", Align: "left" , Static: true, Key: 'Type'}
];
const staticHeaders2 = [
    { Name: "Priority", Align: "right" , Static: true, Key: 'Priority'},
    { Name: "Form", Align: "left" , Static: true, Key: 'Form'},
    // { Name: "Age", Align: "left" , Static: true, Key: 'Age'},
    { Name: "Embrasures", Align: "left" , Static: true, Key: 'Embrasures'},
    { Name: "Incisal Edges", Align: "left" , Static: true, Key: 'IncisalEdges'},
    { Name: "Bucal Surface", Align: "left" , Static: true, Key: 'BucalSurface'},
    { Name: "Status", Align: "right" , Static: true, Key: 'Status'}
];
export default function TemplateList(props) {
    const classes = useStyles2();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [templateTypes, setTemplateTypes] = useState([]);
    const [templateDynamicColumns, setTemplateDynamicColumns] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [currentType, setCurrentType] = useState({type: null});
    const [prioritySelectOptions, setPrioritySelectOptions] = useState([]);
    useEffect(() => {
        initCalls().then();
    }, []);

    useEffect(() => {
        setPrioritySelectOptions(loadCountArray());

    }, [templates, currentType])

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, templates.length - page * rowsPerPage);

    const initCalls = async () => {
        // await Promise.all(([loadTemplateTypes(), getTemplateInformation(), getTemplateInformation()]))
       await Promise.all([getTemplateInformation(), getTemplates()])
       await loadTemplateTypes();

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const activeRoute = routeName => {
        // window.location.href = process.env.REACT_APP_BASE_URL + routeName;
        props.history.push({
            pathname: routeName,
            params: ""
        });
    };

    const filterResults = result => {
        let filteredResults = [];
        for (let i = 0; i <= result.length-1; i++) {
            if (result[i]['IsDeleted'] === 0 && i !== result.length - 1) {
                filteredResults.push(result[i]);
            }
            else if (result[i]['IsDeleted'] === 0 && i === result.length - 1) {
                filteredResults.push(result[i]);
                return filteredResults;
            } else if (result[i]['IsDeleted'] === 1 && i === result.length - 1) {
                return filteredResults;
            }
        }
    };

    const loadTemplateTypes = async () => {
        try {
            let result = await adminActions.getTemplateTypes();
            setTemplateTypes(result.data.data.result);
            setCurrentType(result.data.data.result[0]);
        } catch (err) {
            console.error(err);
        }
    };

    const getTemplates = async () => {
        try {
            let result = await adminActions.fetchTemplates();
            let filteredResults = filterResults(result.data.data.result.data);
            setTemplates(filteredResults);
        } catch (err) {
            console.error(err);
        }
    };

    const loadTypeButtons = () => {
        return templateTypes.map(tp => {
            return <RegularButton
                color={currentType.type === tp.type ? 'info' : 'tumblr'}
                key={tp.type}
                size={"sm"}
                onClick={() => {setCurrentType(tp)}}
            >
                {tp.name}
            </RegularButton>
        })
    }

    const getTemplateInformation = async () => {
        try {
            let result = await adminActions.fetchTemplateInformation();
            const data = result.data.data.result.data.sort(function (a,b){
                let keyA = a.Key;
                let keyB = b.Key;

                if (keyA< keyB){
                    return -1;
                }
                if (keyA > keyB){
                    return 1;
                }
                return 0;
            });
            setTemplateDynamicColumns(data);
        } catch (err) {
            console.error(err);
        }
    };
    const getHeaders = () => {
        const customHeader = [];
        templateDynamicColumns.forEach(tdc => {
            if (tdc.Type === currentType.type) {
                customHeader.push(tdc);
            }
        });
        let staticHeaders2Cp = [...staticHeaders2];

        if (currentType.hasOwnProperty('ai_support') && currentType.ai_support) {
            staticHeaders2Cp.push({ Name: "AI Support", Align: "right" , Static: true, Key: 'AISupport'})
        }
        staticHeaders2Cp.push({ Name: "Actions", Align: "right" , Static: true, Key: 'Actions'})
        return staticHeaders1.concat(customHeader).concat(staticHeaders2Cp);
    }

    const loadTableHeaders = () => {

        return getHeaders().map((h, i) => {
            return <TableCell key={i}>{h.Name}</TableCell>
        });
    }
    const getCurrentRows = () => {
        return templates.filter((row) => {
                if (row.Type === currentType.type) {
                    return row;
                }
            }
        );
    }
    const aISupportCell = (row) => {

       if (currentType.hasOwnProperty('ai_support') && currentType.ai_support) {
          return (<TableCell align={"center"}>
              <Switch
                  inputProps={{ role: 'switch' }}
                  size={"small"}
                  checked={row["AISupport"] ===1}
                  onChange={(e) =>{
                      updateTemplate({template_id: row.Id, ai_support: e.target.checked ? "1" : "0"})
                  }}
                  name={row.Id}
                  color={"primary"}
              />
          </TableCell>)
       }
    }

    const loadCountArray = () => {
        const ar = ["None"];
        for (let i = 0; i < getCurrentRows().length; i++) {
            ar.push(i + 1);
        }
        return ar;
    }
    const loadRows = () => {
        let rows = getCurrentRows();
        return (rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
        ).map((row ,index) => {
            return <TableRow key={index}>
                <TableCell align={"left"}>
                    <Button onClick={() => {
                        dispatch({
                            type: COMMIT_TEMPLATE,
                            templateId: row.Id,
                            name: row.Name
                        });
                        activeRoute("templates-view/")
                    }}>{row.Name}</Button>
                </TableCell>
                <TableCell align={"left"}>
                    {row.Type}
                </TableCell>
                {
                    templateDynamicColumns.map((tdc, i) => {
                        if (tdc.Type === currentType.type) {
                            return <TableCell align="left" key={i}>
                                <IconButton onClick={() => {
                                    dispatch({
                                        type: COMMIT_TEMPLATE,
                                        templateId: row.Id,
                                        name: row.Name,
                                        selectedDetailsID: tdc.Id,
                                        isImage: tdc.hasOwnProperty('IsImage') && tdc.IsImage !== undefined ? tdc.IsImage === 1 : true,
                                        columnData: tdc
                                    });
                                    activeRoute("templates-upload/");
                                }}>
                                    <CloudUpload/>
                                </IconButton>
                            </TableCell>
                        }
                    })
                }
                <TableCell align={"left"}>
                    <FormControl variant="outlined"  size="small">
                        <Select
                            value={row.Priority}
                            onChange={(e) =>{
                                updateTemplate({template_id: row.Id, priority: e.target.value});
                            }}
                        >
                            {
                                prioritySelectOptions.map(i => {
                                    return <MenuItem key={i} value={i}>{i}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell align={"left"}>
                    <FormControl variant="outlined"  size="small" style={{minWidth: 110}}>
                        <Select
                            value={row['Form']}
                            onChange={(e) =>{
                                updateTemplate({template_id: row.Id, form: e.target.value});
                            }}                        >
                            {
                                TEMPLATE_FORM_TYPES.map(i => {
                                    return <MenuItem key={i} value={i}>{i}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </TableCell>
                {/* <TableCell align={"left"}>
                    <FormControl variant="outlined"  size="small" style={{minWidth: 110}}>
                        <Select
                            value={row['Age']}
                            onChange={(e) =>{
                                updateTemplate({template_id: row.Id, age: e.target.value});
                            }}
                        >
                            {
                                TEMPLATE_AGE_TYPES.map(i => {
                                    return <MenuItem key={i} value={i}>{i}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </TableCell> */}
                <TableCell align={"left"}>
                    <FormControl variant="outlined"  size="small" style={{minWidth: 110}}>
                        <Select
                            value={row['Embrasures'] || ''}
                            onChange={(e) =>{
                                updateTemplate({template_id: row.Id, embrasures: e.target.value});
                            }}
                        >
                            {
                                TEMPLATE_EMBRASURES_TYPES.map(i => {
                                    return <MenuItem key={i} value={i}>{i}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell align={"left"}>
                    <FormControl variant="outlined"  size="small" style={{minWidth: 110}}>
                        <Select
                            value={row['IncisalEdges'] || ''}
                            onChange={(e) =>{
                                updateTemplate({template_id: row.Id, incisal_edges: e.target.value});
                            }}
                        >
                            {
                                TEMPLATE_INCISAL_EDGE_TYPES.map(i => {
                                    return <MenuItem key={i} value={i}>{i}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell align={"left"}>
                    <FormControl variant="outlined"  size="small" style={{minWidth: 110}}>
                        <Select
                            value={row['BucalSurface'] || ''}
                            onChange={(e) =>{
                                updateTemplate({template_id: row.Id, bucal_surface: e.target.value});
                            }}
                        >
                            {
                                TEMPLATE_BUCAL_SURFACE_TYPES.map(i => {
                                    return <MenuItem key={i} value={i}>{i}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell align={"left"}>
                    <FormControl variant="outlined"  size="small" style={{minWidth: 110}}>
                        <Select
                            value={row['Status']}
                            onChange={(e) =>{
                                updateTemplate({template_id: row.Id, status: e.target.value});
                            }}
                        >
                            <MenuItem value={"0"}>Inactive</MenuItem>
                            <MenuItem value={"1"}>Active</MenuItem>
                        </Select>
                    </FormControl>
                </TableCell>
                {
                    aISupportCell(row)

                }
                <TableCell align={"center"}>
                    <IconButton size={"small"} onClick={() => {deleteConfirmation(row.Id)}}>
                        <DeleteIcon fontSize={"small"}/>
                    </IconButton>

                </TableCell>
            </TableRow>
        })
    }

    const deleteConfirmation = id => {
        let config = {
            type: 'confirmation',
            message: "Do you want to delete this template?",
            onConfirm: () => {
                deleteTemplates(id)
            }
        };
        dispatch({type: SHOW_APP_ALERT, alertConfig: config});
    }
    const deleteTemplates = async id => {
        try {
            await adminActions
                .putTemplates({
                    template_id: id,
                    is_deleted: "1"
                })
                .then(() => {
                    getTemplates();
                    let config = {
                        type: 'success',
                        title: "Success",
                        message: "You deleted a template!",
                        buttons: [
                            {
                                text: "Ok"
                            }
                        ]
                    };
                    dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
                });
        }
        catch (err) {
            let config = {
                type: 'error',
                title: "Error",
                message: "Yes this is the error"
            };
            dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        }
    }

    const loadPagination = () => {

        return <TablePagination
            rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
            colSpan={getHeaders().length}
            count={getCurrentRows().length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
            }}
            onChangePage={handleChangePage}
            // onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
        />
    }

    const updateTemplate = (param) => {
        adminActions
            .putTemplates(param)
            .then(() => {
                getTemplates();
                let config = {
                    type: 'success',
                    title: "Success",
                    message: "Template saved succefully",
                    buttons: [
                        {
                            text: "Ok"
                        }
                    ]
                };
                dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
            });
    }


    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader>
                        <h4 className={classes.cardTitle}>Template form</h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>{loadTypeButtons()}</GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <TableContainer>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                {loadTableHeaders()}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {
                                                loadRows()
                                            }
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 53 * emptyRows}}>
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                {
                                                    loadPagination()
                                                }
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}
