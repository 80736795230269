import axios from "axios";
import dsdInstance from "./dsdInstance";
import dsdInstanceNoAuth from "./dsdInstanceNoAuth";
const extName = require('ext-name');

const resetPassword = data => {
  return dsdInstance.post("/admin/changePassword", data);
};

const newPassword = (data) => {
  return dsdInstanceNoAuth.post("/admin/newPassword", data);
};

const fetchUsers = async () => {
  try {
    const d = await dsdInstance.get("/admin/listUsers");
    if (d.data.hasOwnProperty('resp')) {
      return d.data.resp.result.Users;
    }
    else {
      console.error(d);
      return [];
    }
  } catch (e) {
    console.error(e);
    return [];
  }
};

const fetchTemplates = () => {
  return dsdInstance.get("/template/getAll");
};

const fetchJanHaitoTemplates = () => {
  return dsdInstance.get("/template/getAll/jan-haito");
};

const fetchSmileDonatorTemplates = () => {
  return dsdInstance.get("/template/getAll/smile-donator");
};

const getTemplateTypes = () => {
  return dsdInstance.get("/template/getTypes");
};

const postTemplates = data => {
  return dsdInstance.post("/template/create", data);
};

const postUpload = data => {
  return dsdInstance.post("/template/saveFileData", data);
};

const getSTLURL = data => {
  return dsdInstance.post("/template/getUploadURL", data);
};

const putTemplates = data => {
  return dsdInstance.put("/template/edit", data);
};

const fetchTemplateInformation = () => {
  return dsdInstance.get("/template/getAllDetails");
};

const getTemplateDetails = data => {
  return dsdInstance.post("/template/getAllFileShades", data);
};

const getTemplateAllDetails = data => {
  return dsdInstance.post("/template/getAllFileShadeUrls", data);
};

const getFile = data =>{
  return dsdInstance.get("/template/getFileShade/" + data);
};

const getImageSignedForTable = data =>{
  return dsdInstance.get("/template/getFileURL/" + data);
};

const getImageFromURL = (url) =>{
  return axios.get(url);
};

const putObjectFromURL = async (url, data) =>{
  let headerType;
  let type = extName(data.name);
  if (type.length === 0){
    headerType='model/ply'
  }
  else if (type[0].ext === "obj"){
    headerType = 'model/obj'
  }
  else{
    headerType=type[0].mime;
  }
  console.log(url);
  return axios.put(url, data, {headers: {'Content-Type': headerType}});
}

const editFileData = data =>{
  return dsdInstance.put("/template/editFileData/", data);
};

const fetchUser = id => {
  return dsdInstance.get("/admin/user/" + id);
};

const updateUser = (id, data) => {
  return dsdInstance.put("/admin/editUser/" + id, data);
};

const deleteUser = data => {
  return dsdInstance.delete("/admin/deleteUser", {data: data});
};

const changePassword =  async data => {
  try {
    const r = await dsdInstance.put("/admin/user/password", data);
    return r.data.status === 200
  } catch (e) {
    console.error(e);
  }
};

const createUser = data => {
  return dsdInstance.post("/admin/signupUser", data);
};

const searchAdminUsers = user => {
  return dsdInstance.get("/admin/search/" + user);
};

const limit = 15; 
const getAdminAuditTrail = (id, endDate, startDate, lastKey) => {
  if (lastKey && startDate && endDate){
    return dsdInstance.get("admin/audit-trail/get/"+id+"?limit="+limit+"?&last_key="+lastKey+"?&to_date="+endDate+"&from_date="+startDate);
  }
  
  else if (lastKey && startDate){
    return dsdInstance.get("admin/audit-trail/get/"+id+"?limit="+limit+"?&last_key="+lastKey+"&from_date="+startDate);
  }
  else if (lastKey && endDate){
    return dsdInstance.get("admin/audit-trail/get/"+id+"?limit="+limit+"?&last_key="+lastKey+"?&to_date="+endDate);
  }
  else if (lastKey){
    return dsdInstance.get("admin/audit-trail/get/"+id+"?limit="+limit+"?&last_key="+lastKey);
  }

  else if(startDate && endDate){
    return dsdInstance.get("admin/audit-trail/get/"+id+"?limit="+limit+"?&to_date="+endDate+"&from_date="+startDate);
  }
  else if(startDate){
    return dsdInstance.get("admin/audit-trail/get/"+id+"?limit="+limit+"&from_date="+startDate);
  }
  else if(endDate){
    return dsdInstance.get("admin/audit-trail/get/"+id+"?limit="+limit+"?&to_date="+endDate);
  }
  else{
    return dsdInstance.get("admin/audit-trail/get/"+id+"?limit="+limit);
  }
};


export default {
  fetchUsers: fetchUsers,
  fetchUser: fetchUser,
  updateUser: updateUser,
  deleteUser: deleteUser,
  createUser: createUser,
  searchAdminUsers: searchAdminUsers,
  getAdminAuditTrail: getAdminAuditTrail,
  fetchTemplates: fetchTemplates,
  fetchTemplateInformation: fetchTemplateInformation,
  postTemplates: postTemplates,
  putTemplates: putTemplates,
  getTemplateDetails: getTemplateDetails,
  postUpload: postUpload,
  getFile: getFile,
  fetchJanHaitoTemplates: fetchJanHaitoTemplates,
  fetchSmileDonatorTemplates: fetchSmileDonatorTemplates,
  getTemplateTypes: getTemplateTypes,
  editFileData: editFileData,
  getTemplateAllDetails: getTemplateAllDetails,
  getImageFromURL: getImageFromURL,
  getImageSignedForTable: getImageSignedForTable,
  resetPassword: resetPassword,
  newPassword: newPassword,
  getSTLURL: getSTLURL,
  putObjectFromURL: putObjectFromURL,
  changePassword: changePassword
};
