export const HEADERS = [
    {name: 'Username', key: 'Username', align: 'left'},
    {name: 'Sub', key: 'sub', align: 'left'},
    // {name: 'Email', key: 'email', align: 'left'},
    {name: 'Created date', key: 'UserCreateDate', align: 'left'},
    {name: 'Status', key: 'UserStatus', align: 'left'},
    {name: 'Actions', key: 'action', align: 'right'}
];
export const STATUS_CONFIG = {
    CONFIRMED: {text: "Confirmed", color: "success"},
    FORCE_CHANGE_PASSWORD: {text: 'Force change', color: "danger"}
};