import {
  COMMIT_TEMPLATE,
  COMMIT_UPLOAD_FILE_TO_STORE,
  COMMIT_FILE_DETAILS_TO_STORE,
  COMMIT_FILE_URL_TO_STORE,
  COMMIT_IMAGE_TO_STORE,
  RESET_FILE_INDEX,
  COMMIT_ALL_DETAILS,
  COMMIT_DETAIL,
  COMMIT_ALL_TEMPLATES,
  IMAGE_COUNT,
  COMMIT_JAN_HAITO_TEMPLATES,
  COMMIT_SMILE_DONATOR_TEMPLATES,
  COMMIT_TYPES,
  COMMIT_SMILE_DONATOR_DETAILS,
  COMMIT_JAN_HAITO_DETAILS,
  COMMIT_FILE,
  PREVIEW_IMAGE,
  COMMIT_ACTION,
  RESET_APP,
  COMMIT_AVAILABLE_TUTORIAL_LANGUAGES,
  COMMIT_ALL_TUTORIAL_MODULES,
  COMMIT_SERVER_SETTINGS,
  COMMIT_ALL_MENU_ITEMS
} from "../action-types";

const initialState = {
  templateId: "",
  fileIndex: 1,
  action: "",
  allTemplates: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_FILE_INDEX:
      return {
        ...state,
        fileIndex: 1,
        templateId: "",
        selectedDetailsID: "",
        templateType: "",
        templateName: "",
        age: "",
        form: "",
        action: "",
        priority: "",
        uploadedFile: "",
        isImage: "",
        columnData: ""
      };
      case COMMIT_TYPES:
        return {
          ...state,
          allTypes: action.payload
        };
        case RESET_APP:
        return {
          ...state,
          reset:1
        };
        case PREVIEW_IMAGE:
          return {
            ...state,
            previewImage: action.payload
          };
    case COMMIT_ALL_DETAILS:
      return {
        ...state,
        allDetails: action.payload
      };
      case COMMIT_JAN_HAITO_DETAILS:
        return {
          ...state,
          janHaitoDetails: action.payload
        };
        case COMMIT_SMILE_DONATOR_DETAILS:
          return {
            ...state,
            smileDonatorDetails: action.payload
          };
      case IMAGE_COUNT:
      return {
        ...state,
        ImageCount: action.payload
      };
      case COMMIT_ALL_TEMPLATES:
      return {
        ...state,
        allTemplates: action.payload
      };
      case COMMIT_JAN_HAITO_TEMPLATES:
      return {
        ...state,
        janHaitoTemplates: action.payload
      };
      case COMMIT_SMILE_DONATOR_TEMPLATES:
      return {
        ...state,
        smileDonatorTemplates: action.payload
      };
    case COMMIT_TEMPLATE:
      return {
        ...state,
        templateId: action.templateId,
        templateName: action.name,
        templateStatus: action.status,
        templateType: action.templateType,
        selectedDetailsID: action.selectedDetailsID,
        age: action.age,
        form: action.form,
        priority: action.priority,
        isImage: action.isImage,
        columnData: action.columnData
      };
      case COMMIT_ACTION:
      return {
        ...state,
        action: action.payload
      };

      case COMMIT_DETAIL:
      return {
        ...state,
        detail: action.name,
        id: action.id
      };

    case COMMIT_UPLOAD_FILE_TO_STORE:
      return {
        ...state,
        uploadedFile: action.payload,
        file_name: action.file_name,
        fileIndex: state.fileIndex + 1
      };
    case COMMIT_FILE_DETAILS_TO_STORE:
      return {
        ...state,
        uploadedFileDetails: action.payload
      };
    case COMMIT_FILE_URL_TO_STORE:
      return {
        ...state,
        fileURL: action.payload
      };
      case COMMIT_FILE:
      return {
        ...state,
        file: action.payload
      };
    case COMMIT_IMAGE_TO_STORE:
      return {
        ...state,
        image: [action.payload]
      };
      case COMMIT_AVAILABLE_TUTORIAL_LANGUAGES:
        return {
          ...state,
          tutorialInputs: action.payload
        };
        case COMMIT_ALL_TUTORIAL_MODULES:
        return {
          ...state,
          tutorialModules: action.payload
        };
        case COMMIT_SERVER_SETTINGS:
        return {
          ...state,
          menuSettings: action.payload
        };
        case COMMIT_ALL_MENU_ITEMS:
        return {
          ...state,
          allMenuItems: action.payload
        };

    default:
      return state;
  }
}
export default reducer;
