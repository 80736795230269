import React, {useEffect} from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import IconButton from "@material-ui/core/IconButton";
import {AddAPhoto, CloudUpload, InsertPhoto, RemoveCircle} from "@material-ui/icons";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

export default function ImageUploadV1(props) {
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.avatar ? defaultAvatar : defaultImage
  );

  useEffect(() => {
    if(props.reset) {
      setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
      setFile(null);
    }
  }, [props.reset])
  useEffect(() => {
    if (props.imagePreviewUrl) {
      console.log(props.imagePreviewUrl);
      setImagePreviewUrl(props.imagePreviewUrl);
    }
  }, [props.imagePreviewUrl]);

  useEffect(() => {
    props.setNewFile(file);
  }, [file]);

  let fileInput = React.createRef();
  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  // eslint-disable-next-line
  const handleSubmit = e => {
    e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
  };
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props;
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
        <img src={imagePreviewUrl} alt="..." />
      </div>
      <div>
        {file === null ? (
          <IconButton {...addButtonProps} onClick={() => handleClick()} color={"default"}>
            {avatar ? <AddAPhoto/> : <AddAPhoto/>}
          </IconButton>
        ) : (
          <span>
            <IconButton {...changeButtonProps} onClick={() => handleClick()} color={"default"}>
              <Edit/>
            </IconButton>
            {avatar ? <br /> : null}
            <IconButton {...removeButtonProps} onClick={() => handleRemove()} color={"default"}>
              <DeleteIcon/>
            </IconButton>
          </span>
        )}
      </div>
    </div>
  );
}

ImageUploadV1.propTypes = {
  avatar: PropTypes.bool,
  setNewFile: PropTypes.object,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};
