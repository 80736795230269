import React from 'react';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import {red} from "@material-ui/core/colors";
import CardHeader from "../../../components/Card/CardHeader";
import {CardContent} from "@material-ui/core";
import Card from "../../../components/Card/Card";
import TemplateFileView from "./TemplateFileView";
const useStyles = makeStyles((theme) => ({
    ...styles,
    root: {
        maxWidth: 345,
    },
    '&:hover': {
        color: "rgb(128 128 128)"
    },
    stlMedia: {
        // padding: "20px 20px 20px 20px",
        margin: "auto",
        display: "block",
        maxHeight: "100%",
        maxWidth: "100%",
    },
    media: {
        padding: "20px 20px 20px 20px",
        margin: "auto",
        display: "block",
        maxHeight: "100%",
        maxWidth: "100%",
    },
    mediaParent: {
        height: "150px",
        width: "100%",
        backgroundColor: "#EAEAEA",
        position: "relative"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    cardContent: {
        textAlign:"center"
    },
    cardActions: {
        textAlign:"right",
    },
    cardActionsButtons: {
        marginLeft: 'auto',
    },
    progressBar: { width: "100%", display: "inline-block" }
}));

export default function TabContent(props) {
    const classes = useStyles();

    const {data, isImage} = props;
    return  <GridContainer>
        {
            data.map(d => {
                return (
                    <GridItem lg={2} md={3} sm={4}>
                        <Card className={classes.root}>
                            <CardHeader>
                                <h4 className={classes.cardTitle}>{d.FileLabel}</h4>
                            </CardHeader>
                            <CardContent className={classes.cardContent}>
                                <div className={classes.mediaParent}>
                                    <TemplateFileView
                                        IsImage={isImage}
                                        item={d}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </GridItem>
                )
            })
        }
    </GridContainer>

}