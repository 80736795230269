import React, {useState} from "react";
import CardHeader from "../../components/Card/CardHeader";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import InputErrors from "../../components/CustomInput/InputErrors";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import GridContainer from "../../components/Grid/GridContainer";
import CardBody from "../../components/Card/CardBody";
import {Card} from "@material-ui/core";
import {useInputProps} from "../../components/CustomInput/InputPropsHook";
import Button from "../../components/CustomButtons/Button";
import ChatService from "../../services/ChatService";
import {SHOW_APP_ALERT} from "../../action-types";
import {useDispatch} from "react-redux";
import {Alert} from "@material-ui/lab";
import RegularButton from "../../components/CustomButtons/RegularButton";
import {ArrowBack} from "@material-ui/icons";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(styles);

export default function ChatGroupsForm() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const gName = useInputProps({type: "text", rules: ["required"]});
    const [file, setFile] = useState(null);

    const reset = () => {
        setFile(null);
        gName.setValue("");
    }
    const fileChange = e => {
        console.log(e.target.files[0]);
        setFile(e.target.files[0]);
    }
    const canSave = () => {
        return gName.props.value && file
    }
    const getBase64 = f => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(f);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const submit = async () => {
        const file64 = await getBase64(file);
        console.log(file64);
        const params = {
            group_name: gName.props.value,
            file: file64.split(",")[1]
        };
        console.log(params);
       const result = await ChatService.createGroup(params);
       if (result.status) {
           reset();
           let config = {
               type: "success",
               title: "Success",
               message: "Group added successfully!",
           };
           dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
       }
       else {
           let config = {
               type: "error",
               title: "Failed",
               message: result.errors.map(ee => {
                   return <p>{ee}</p>
               }),
           };
           dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
       }
       console.log(result);

    }
    const goToList = () => {
        history.push('/admin/system-chat-integrations/');
    }
    return <GridContainer>
        <GridItem md={12}>
            <RegularButton
                justIcon
                round
                color="white"
                // className={classes.floatRight}
                onClick={goToList}
            >
                <ArrowBack className={classes.icons} />
            </RegularButton>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
            <Card>
                <CardHeader>
                    <h4 className={classes.cardTitle}>Chat Group form</h4>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12}>
                            <CustomInput
                                error={!gName.validity.isValid}
                                labelText="Group Name...*"
                                id="gName"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{...gName.props}}
                            />
                            <InputErrors errors={gName.validity.errors}/>
                        </GridItem>
                        <GridItem xs={12}>
                            {
                                file
                                    ? <Alert onClose={() => {setFile(null)}}>{file.name}</Alert>
                                    :  <Button
                                        size={"sm"}
                                        variant="contained"
                                        component="label"
                                    >
                                        Emails CSV
                                        <input
                                            onChange={fileChange}
                                            accept={"text/csv"}
                                            type="file"
                                            hidden
                                        />
                                    </Button>
                            }

                        </GridItem>
                        <GridItem xs={12} className={classes.alignRight}>
                            <Button color={"success"} size={"sm"} disabled={!canSave()} onClick={submit}>Save</Button>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </GridItem>
    </GridContainer>
}
