import dsdInstance from "./dsdInstance";
import { API_URL } from "../config/api";

export class ProductService {
  static getConfig = async () => {
    try {
      const res = await dsdInstance.get(API_URL.PRODUCT_CONFIG);
      return res.data.data.result;
    } catch (e) {
      console.error(e);
      return [];
    }
  };
  static list = async (type = "") => {
    try {
      const res = await dsdInstance.get(API_URL.PRODUCT + "?type=" + type);
      return res.data.data.result.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };
  static create = async (params) => {
    try {
      const res = await dsdInstance.post(API_URL.PRODUCT, params);
      return this.responseHandler(res);
    } catch (e) {
      console.error(e);
      return [];
    }
  };
  static update = async (params, Id) => {
    try {
      const res = await dsdInstance.put(API_URL.PRODUCT + "/" + Id, params);
      return this.responseHandler(res);
    } catch (e) {
      console.error(e);
      return [];
    }
  };
  static delete = async (Id) => {
    try {
      const res = await dsdInstance.delete(API_URL.PRODUCT + "/" + Id);
      return this.responseHandler(res);
    } catch (e) {
      console.error(e);
      return [];
    }
  };
  static responseHandler = (res) => {
    console.log(res);
    if (res.status === 200) {
      return { status: true, data: res.data.data.result };
    } else {
      return { status: false, data: res.data.err };
    }
  };
}
