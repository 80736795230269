import React, { useEffect, useState } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import "tui-image-editor/dist/tui-image-editor.css";
import "./imageUploadStyle.css";

// core components
import RegularButton from "components/CustomButtons/RegularButton.js";
import { connect } from "react-redux";
import defaultImage from "assets/img/image_placeholder.jpg";
import teeth from "assets/img/teeth.png";
import { useDispatch} from "react-redux";
import {COMMIT_UPLOAD_FILE_TO_STORE, SHOW_APP_ALERT} from "../../action-types";
import adminActions from "../../services/admin";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { STLViewer} from 'react-stl-obj-viewer';

function ImageUpload(props) {
  const dispatch = useDispatch();
  const [displayFileName, setDisplayFileName] = useState();
  const [changedImageForState, setChangedImageForState] = useState(
    defaultImage
  );
  let [change, setChange] = useState();
  let [imageSource, setImageSource] = useState();
  let [deleted, setDeleted] = useState(false);
  const [file, setFile] = React.useState(null);
  const [objURL, setObjURL] = React.useState(null);
  const { id, fileIndex, url, isObject} = props;

  const getToken = () => {
    let token = localStorage.getItem("idToken");
    return token;
  };

  const setImageFromSignedURL = async (urlFromProp) => {
    if (deleted == false){
      if (!isObject){
       setImageSource(urlFromProp);
       setFile(urlFromProp);
       await adminActions
       .getImageFromURL(urlFromProp);
      }
      else if (isObject){
       setFile(urlFromProp);
       setObjURL(urlFromProp);
      }
   }
     else{
       return null;
     }
 };

  let fileInput = React.createRef();

  const stripExtensions = (string) =>{
    return string.replace(/\.[^/.]+$/, "");
  }

  //Send display name, "T1,T2" in here. Use T + fileIndex for file_label field.
  const uploadFileToDb = async (fileName, fileContent) => {
      let templateForAPI;
      if (fileIndex === 13){
       templateForAPI =  {
          template_id: props.templateId,
          detail_id: props.selectedDetailsID,
          file_name: fileName,
          file_label: "T"+fileIndex,
          file: fileContent,
          is_preview: 1,
          file_index: fileIndex.toString()
        };
      }
      else if(isObject === true){
          templateForAPI = {
          template_id: props.templateId,
          detail_id: props.selectedDetailsID,
          file_index: fileIndex.toString(),
          file_label: "T"+fileIndex,
          file_name: fileName
        };
        try {
          let result = await adminActions.getSTLURL(templateForAPI);
          let url = result.data.data.result;
          let postToDb = await adminActions.putObjectFromURL(url, fileContent);
          if (postToDb.status==200){
            setChangedImageForState(teeth);
            setImageSource(teeth);
            setDisplayFileName(stripExtensions(fileName)+ " Uploaded Successfully!");
          }
        } catch (err) {
          setDisplayFileName(stripExtensions(fileName)+" Upload Failed.")
        }
        return;
      }
      else {
        templateForAPI = {
          template_id: props.templateId,
          detail_id: props.selectedDetailsID,
          file_name: fileName,
          file_label: "T"+fileIndex,
          file: fileContent,
          file_index: fileIndex.toString()
        };
        try{
          let result = await adminActions.postUpload(templateForAPI);
          if (result.status===200){
            setDisplayFileName(stripExtensions(fileName)+ " Uploaded Successfully!");
          }
        }
        catch{
          setDisplayFileName(stripExtensions(fileName)+" Upload Failed.")
        }
      }
   }

  const handleImageChange = (e) => {
      e.preventDefault();

      //Define whether or not this is STL before opening the reader
      if (!isObject){
        let reader = new FileReader();
        let newFile = e.target.files[0];
        reader.readAsDataURL(newFile);
        reader.onloadend = () => {
          setFile(newFile);
          dispatch({
            type: COMMIT_UPLOAD_FILE_TO_STORE,
            payload: reader.result.split("base64,")[1],
            file_name: newFile.name
          });
            setChangedImageForState(reader.result);
            setImageSource(reader.result);
          if (props.action !== "edit" && props.action !== "create"){
              uploadFileToDb(newFile.name, reader.result.split("base64,")[1]);
          }
        };
      }
      else{
        let reader = new FileReader();
        let newFile = e.target.files[0];
        setObjURL(URL.createObjectURL(newFile));
        reader.readAsBinaryString(newFile);
        reader.onloadend = () => {
          setFile(newFile);
          dispatch({
            type: COMMIT_UPLOAD_FILE_TO_STORE,
            payload: reader.result,
            file_name: newFile.name
          });
          if (props.action !== "edit" && props.action !== "create"){
              uploadFileToDb(newFile.name, newFile);
          }
        };
      }
    }

  const handleClick = (e) => {
    if (change=="change"){
      setChange(true);
      fileInput.current.click();
    }
    else{
      fileInput.current.click();
    }
  };

  const handleEdit = () => {
  props.displayImageEditor(file);
  };

  const handleDownload = async () =>{
    try{
      let result = await adminActions
      .getImageFromURL(file);
    }
    catch (err){
      let config = {
        type: 'error',
        title: "Error",
        message: err.message + ". File could not be downloaded."
      };
      dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
    }
  }

  const handleRemove = async (fileId, file_label) => {
    let templateForAPI={
      file_id: fileId,
      is_deleted: 1
    };

   try{
    let result = await adminActions.editFileData(templateForAPI);
    if (result.status === 200){
      fileInput.current.value = null;
      setDeleted(true);
      setFile(null);
      setDisplayFileName(null);
      setImageSource(defaultImage);
      setObjURL(null);
      dispatch({
        type: COMMIT_UPLOAD_FILE_TO_STORE,
        payload: "",
        file_name: ""
      });
    } 
   }
   catch (e) {
    setDisplayFileName("File Not Deleted Succesfully");
   }
  };
  let { avatar, addButtonProps, removeButtonProps } = props;

  useEffect(() => {
    // Write functions to execute on page-load.
    if (url){
      setImageFromSignedURL(url);
    }    
  }, []);

  const checkPreview = (index) =>{
    if (index ===13){
      return "Preview Image"
    }
    else if (index == "Smile-Donor"){
      return "Smile-Donor Image"
    }
    else {
      return "T "+index;
    }
  }

  const formatComponent = () =>{
    if (props.displayImageEditor && !isObject){
      return(
        <span>
            <RegularButton {...removeButtonProps} onClick={() => handleEdit()}>
               Edit
            </RegularButton>
            {avatar ? <br /> : null}
            <RegularButton {...removeButtonProps} style={{background: "red"}} onClick={() => handleRemove(id)}>
              <i className="fas fa-times" /> Remove
            </RegularButton>
          </span>
      )
    }
    else{
      return (<span>
          <a href={file} download>
          <RegularButton {...removeButtonProps} onClick={() => handleDownload()}>
              Download
          </RegularButton>
          </a>
        {avatar ? <br /> : null}
        <RegularButton {...removeButtonProps} style={{background: "red"}} onClick={() => handleRemove(id)}>
          <i className="fas fa-times" /> Remove
        </RegularButton>
      </span>)
    }
  }
  
  return (
    <div>
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <h4 style={{ color: "green" }}>
        {displayFileName ? displayFileName : checkPreview(fileIndex)}
      </h4>
      <div className={"thumbnail" + (avatar ? " img-circle" : "")} style={{backgroundColor:"#D3D3D3"}}>
        {objURL ? 
        <STLViewer
          url={objURL}
          width={200}
          height={200}
          modelColor='#FFFFFF'
          backgroundColor='#EAEAEA'
          rotate={true}
          orbitControls={true}
        />: 
        <LazyLoadImage
        src={imageSource ? imageSource : changedImageForState}
        alt="..."
        scrollPosition={props.scrollPosition}
      />}
      </div>
      <br />
        {file === null ? (
          <RegularButton {...addButtonProps} onClick={() => handleClick()}>
            {isObject === true ? "Add Object" : "Select image"}
          </RegularButton>
        ) : formatComponent()}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    action: state.store.action,
    templateId: state.store.templateId,
    selectedDetailsID: state.store.selectedDetailsID,
    fileURL: state.store.fileURL
  };
};

const wrapperFunction = connect(mapStateToProps);
const MemoizedImageUploadComponent = React.memo(ImageUpload);

export default wrapperFunction(MemoizedImageUploadComponent);

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  id: PropTypes.string,
  scrollPosition: PropTypes.object,
  displayImageEditor: PropTypes.func,
  noDisplayName: PropTypes.bool,
  previewImage: PropTypes.bool,
  changePreviewState: PropTypes.func,
  previewValue: PropTypes.number,
  fileIndex: PropTypes.number,
  url: PropTypes.string,
};
