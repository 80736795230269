import {cardTitle} from "../../assets/jss/material-dashboard-pro-react";
import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";

export const PlanStyles = (theme) => ({
    cardTitle,
    ...styles,
    ...buttonStyle,
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    table: {
        minWidth: 500,
    }
})