import React, {useEffect} from 'react';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import {makeStyles} from "@material-ui/core/styles";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputErrors from "../../../components/CustomInput/InputErrors";
import {useInputProps} from "../../../components/CustomInput/InputPropsHook";
import userService from "../../../services/userService";
import {LANGUAGES} from "../../../common/languages";
import {COUNTRIES} from "../../../common/counties";
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import RegularButton from "../../../components/CustomButtons/RegularButton";
import {SHOW_APP_ALERT} from "../../../action-types";
import {useDispatch} from "react-redux";

const useStyles = makeStyles({
    ...styles,
    title: {},
    selectFormController: {
        // paddingTop: "12px",
        textAlign: "left",
        color: "#bababa"
    }
})
export default function AppUserForm(props) {
    const {currentUser} = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        if(currentUser) {
            resetForm();
            loadCurrentUser();
        }
    },[currentUser])

    const countrySelectOptions = () => {

        return (
            COUNTRIES.map((cd, i) => {
                return (<MenuItem
                    key = {i}
                    value={cd.code}
                >
                    {cd.name}
                </MenuItem>)
            })
        );
    };
    const languageSelectOptions = () => {

        return (
            LANGUAGES.map((cd, i) => {
                return (<MenuItem key={i}
                    value={cd.code}
                >
                    {cd.lan}
                </MenuItem>)
            })
        );
    };
    const formInputs = {
        name: useInputProps({type: "text", rules: ["required"], value: ''}),
        family_name: useInputProps({type: "text", rules: ["required"], value: ''}),
        language: useInputProps({type: "text", value: ''}),
        country: useInputProps({type: "select", value: ''}),
        phone_number: useInputProps({type: "text", rules: ["required"], value: ''}),
        keep_id: useInputProps(({type: "text", value: ''})),
        i_phone: useInputProps({type: "number", value: '', rules: ["min:0"]}),
        i_pad: useInputProps({type: "number", value: '', rules: ["min:0"]}),
        mac: useInputProps({type: "number", value: '', rules: ["min:0"]}),
    }
    const loadCurrentUser = async () => {
        formInputs.name.setValue(currentUser["Name"] ? currentUser["Name"] : '')
        formInputs.family_name.setValue(currentUser["FamilyName"] ? currentUser["FamilyName"] : '')
        formInputs.language.setValue(currentUser["Language"] ? currentUser["Language"] : '')
        formInputs.country.setValue(currentUser["Country"] ? currentUser["Country"] : '')
        formInputs.phone_number.setValue(currentUser["PhoneNumber"] ? currentUser["PhoneNumber"] : '')
        formInputs.keep_id.setValue(currentUser.hasOwnProperty("KeepId") ? currentUser.KeepId : "");
        formInputs.i_phone.setValue(currentUser["devices"] ? currentUser["devices"]["IPHONE"] : '')
        formInputs.i_pad.setValue(currentUser["devices"] ? currentUser["devices"]["IPAD"] : '')
        formInputs.mac.setValue(currentUser["devices"] ? currentUser["devices"]["MAC"] : '')

    }
    const resetForm = () => {
        Object.keys(formInputs).forEach(fik => {
            formInputs[fik].setValue('');
        })
    }
    const submit = async () => {
        let params = {user_id: currentUser.UserId, username: currentUser.Email, devices: {IPAD: 0, IPHONE: 0, MAC: 0}};
        let validity = true;
        Object.keys(formInputs).forEach(fik => {
            params[fik] = formInputs[fik].props.value;
            const tempValidity = formInputs[fik].doValidate();
            if (!tempValidity) {
                validity = false
            }
        });
        if(validity) {
            params.devices.MAC = parseInt(params.mac);
            params.devices.IPHONE = parseInt(params.i_phone);
            params.devices.IPAD = parseInt(params.i_pad);
            delete params.mac;
            delete params.i_phone;
            delete params.i_pad;
            const resp = await userService.updateUser(params);
            try {
                if(resp.status === 200) {
                    dispatch({ type: SHOW_APP_ALERT, alertConfig: {
                            type: "success",
                            message: 'User Updated Successfully'
                        } });
                    resetForm();
                    props.getCurrentUser();
                }
                else {
                    console.error(resp);
                    dispatch({ type: SHOW_APP_ALERT, alertConfig:  {
                            type: "error",
                            message: 'Something went wrong!'
                        } });
                }
                props.setEditModal(false);
            }
            catch (e) {
                console.error(e);
            }
        }
    }
    return <GridContainer>
        <GridItem md={12}>
            <h4 className={classes.title}>Edit User Form</h4>
        </GridItem>
        <GridItem md={12}>
            <CustomInput
                error={!formInputs['name'].validity.isValid}
                labelText="First Name...*"
                id="userName"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{...formInputs['name'].props}}
            />
            <InputErrors errors={formInputs['name'].validity.errors}/>
        </GridItem>
        <GridItem md={12}>
            <CustomInput
                error={!formInputs['family_name'].validity.isValid}
                labelText="Last Name...*"
                id="lastName"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{...formInputs['family_name'].props}}
            />
            <InputErrors errors={formInputs['family_name'].validity.errors}/>
        </GridItem>
        <GridItem md={12}>
            <FormControl fullWidth className={classes.selectFormController}>
                <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabelLC}
                >
                    Language
                </InputLabel>
                <Select
                    id="simple-select"
                    MenuProps={{
                        className: classes.selectMenu
                    }}
                    classes={{
                        select: classes.select
                    }}
                    {...formInputs.language.props}
                >
                    <MenuItem
                        disabled
                        classes={{
                            root: classes.selectMenuItem
                        }}
                    >
                        Single Select
                    </MenuItem>
                    {languageSelectOptions()}
                </Select>
            </FormControl>
        </GridItem>
        <GridItem md={12} style={{marginTop: "15px"}}>
            <FormControl fullWidth className={classes.selectFormController}>
                <InputLabel
                    // style={{paddingTop: "14px"}}
                    htmlFor="simple-select-2"
                    className={classes.selectLabelLC}
                >
                    Country
                </InputLabel>
                <Select
                    id="simple-select-2"
                    MenuProps={{
                        className: classes.selectMenu
                    }}
                    classes={{
                        select: classes.select
                    }}
                    {...formInputs.country.props}
                >
                    {countrySelectOptions()}
                </Select>
            </FormControl>
        </GridItem>
        <GridItem md={12} style={{marginTop: "15px"}}>
            <CustomInput
                style={{paddingTop: "30px"}}
                error={!formInputs['phone_number'].validity.isValid}
                labelText="Phone Number...*"
                id="templateName"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{...formInputs['phone_number'].props}}
            />
            <InputErrors errors={formInputs['phone_number'].validity.errors}/>
        </GridItem>
        <GridItem md={12}>
            <CustomInput
                error={!formInputs['keep_id'].validity.isValid}
                labelText="Keep Id"
                id="keep_id"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{...formInputs['keep_id'].props}}
            />
            <InputErrors errors={formInputs['keep_id'].validity.errors}/>
        </GridItem>
        <GridItem md={12}>
            <p>Devices</p>
        </GridItem>
        <GridItem md={4}>
            <CustomInput
            error={!formInputs['i_phone'].validity.isValid}
            labelText="iPhone"
            id="i_phone"
            formControlProps={{
                fullWidth: true
            }}
            inputProps={{...formInputs['i_phone'].props}}
        />
            <InputErrors errors={formInputs['i_phone'].validity.errors}/>
        </GridItem>
        <GridItem md={4}>
            <CustomInput
            error={!formInputs['i_pad'].validity.isValid}
            labelText="iPade"
            id="i_pad"
            formControlProps={{
                fullWidth: true
            }}
            inputProps={{...formInputs['i_pad'].props}}
        />
            <InputErrors errors={formInputs['i_pad'].validity.errors}/>
        </GridItem>
        <GridItem md={4}>
            <CustomInput
            error={!formInputs['mac'].validity.isValid}
            labelText="MAC"
            id="mac"
            formControlProps={{
                fullWidth: true
            }}
            inputProps={{...formInputs['mac'].props}}
        />
            <InputErrors errors={formInputs['mac'].validity.errors}/>
        </GridItem>
        <GridItem md={12}>
            <GridItem xs={12} sm={12} md={12}>
                <RegularButton
                    className={classes.floatRight}
                    color="success"
                    onClick={() => {
                        submit();
                    }}
                >
                    Save
                </RegularButton>
            </GridItem>
        </GridItem>
    </GridContainer>
}