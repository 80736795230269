import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ReactTable from "react-table";
import adminActions from "../../services/admin";
import CachedIcon from '@material-ui/icons/Cached';
import './ViewAdminUserEvents.css'
import CloseIcon from '@material-ui/icons/Close';
import ServerPagination from '../ServerPagination/ServerPagination';
import CalendarInput from '../CalendarInput/CalendarInput'
import moment from 'moment';
import {LIMIT, CONFIG} from "./adminAuditConstants.js";
import {SHOW_APP_ALERT} from "../../action-types";

const useStyles = makeStyles((theme) => ({
  paper: {}
}));

export default function ViewAdminUserEvents(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  // eslint-disable-next-line react/prop-types
  let [tableData, setTableData] = useState();
  let [username, setUserName] = useState();
  let [loading, setLoading] = useState("false");
  let [leftArrow, setLeftArrow] = useState("hidden");
  let [rightArrow, setRightArrow] = useState("hidden");
  let [endDate, setEndDate] = useState();
  let [startDate, setStartDate] = useState();
  let [endValue, setEndValue] = useState();
  let [startDateError, setStartDateError] = useState(false);
  let [endDateError, setEndDateError] = useState(false);
  let [startValue, setStartValue] = useState();
  let [jsonObject, setJsonObject] = useState();
  let [userID, setUserID] = useState();
  let [nextKey, setNextKey] = useState();
  let [keys, setKeys] = useState([""]);
  let [index, setIndex] = useState(1);
  let [open, setOpen] = useState(false);
  let [searchResults, setSearchResults] = useState([{
    Username: "Please search users"
  }]);
  var uniqid = require('uniqid');

  const getToken = () => {
    let token = localStorage.getItem("idToken");
    return token;
  };

  const locationCell = (row) =>{
    return (
      <div className='actions-right'>
        {row.value}
      </div>
    )
  }

  const columns = [
    {
      Header: "Date",
      accessor: "timestamp"
    },
    {
      Header: "Event",
      accessor: "event"
    },
    {
      Header: "Result",
      accessor: "result"
    },
    {
      Header:"Reference ID",
      accessor:"referenceID"
    },
    {
      Header:"Parameters",
      accessor:"parameters",
      Cell: row => makeParametersElement(row)
    },
    {
      Header: "Device",
      accessor: "device",
      Cell: row => makeDevicesElement(row)
    },
    {
      Header: "IP",
      accessor: "ip"
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: row => locationCell(row),
      filterable: false,
      sortable: false
    }
  ];

  const updateUsername = (innerHTML) =>{
    if (typeof innerHTML == typeof 1){
      return;
    }
    else if (typeof innerHTML == typeof ''){
      if (innerHTML.includes('<')){
        setUserName('Please re-select user.')
      }
      else{
        setUserName(innerHTML);
      }
    }
    
}

const updateTable = () =>{

  if (startDateError === true || endDateError === true){
    setStartValue("");
    setStartDate(null);
    setStartDateError(false);
    setEndValue("");
    setEndDateError(false);
    setEndDate(null);
  }

  let id;
      for (let i=0;i<=searchResults.length-1;i++){
        if (searchResults[i].Username == document.getElementById("combo-box-demo").value){
            id = searchResults[i].Attributes[0].Value;
            setUserID(searchResults[i].Attributes[0].Value);
            updateUsername(searchResults[i].Username);
        }  
    }

    if (id && startDateError===false && endDateError ===false){
      try{
        let result= adminActions.getAdminAuditTrail(id, endDate, startDate).then(
          result =>{
            let data = result.data.resp.result.Data;
            if (result.data.resp.result.LastKey != undefined){
              setNextKey(result.data.resp.result.LastKey);
              setLeftArrow("hidden");
              setRightArrow("visible");
              setKeys([""]);
              setIndex(0);
            }
            else {
              setLeftArrow("hidden");
              setRightArrow("hidden");
              setKeys([""]);
              setIndex(0);
            }
            return data;
          }
        ).then(result=>{
          updateTableData(result);
        })
      }
      catch{
        dispatch({ type: SHOW_APP_ALERT, alertConfig: CONFIG });
      }
    }
    else{
      dispatch({ type: SHOW_APP_ALERT, alertConfig: CONFIG });
    }


}

const searchUsers = async (string) =>{
  if (string != undefined && string.length>=3){
    setLoading('Loading');
    try{
      let result = await adminActions.searchAdminUsers(string)
      .then(result =>{
        if (result.data.resp.result.Users.length){
          setSearchResults(result.data.resp.result.Users);
        }
        else if (result.data.resp.result.Users.length==0){
          setLoading('No users by this username');
        }

      });
    }
    catch (e){
      console.log(e);
    }
  }
}

const updateStartDate = (date) =>{
  setStartValue(date);
  let timestamp = moment(date).startOf('day').format('X')*1000;
  if (timestamp<moment().unix()*1000){
    if(timestamp<endDate || !endDate){
      setStartDate(timestamp);
      setStartDateError(false);
    }
    else{
      setStartDateError(true);
    }
  }
  else{
    setStartDateError(true);
  }
}

const updateEndDate = (date) =>{
  setEndValue(date);
  let timestamp = moment(date).endOf('day').format('X')*1000;
  if (timestamp<moment().unix()*1000){
    if(timestamp>startDate || !startDate){
      setEndDate(timestamp);
      setEndDateError(false);
    }
    else{
      setEndDateError(true);
    }
  }
  else{
    setEndDateError(true);
  }
}

const makeParametersElement = (value) =>{
  let parameters;
  if (value.original.parameters != undefined){
    try{
      parameters = JSON.parse(value.original.parameters);
    }
    catch (e){
      console.log(e);
    }
  }
  return(
    <div>
      <span title={parameters ? JSON.stringify(parameters): null}
            onClick = {e =>{handleOpen(value)}} className="parametersCell">...</span>
    </div>
)
}

const checkString = (string) =>{
  if (string.length>10){
    return string.substring(0,10)+" ...";
  }
}

const makeDevicesElement = (value) =>{
  let index = value.index;
  return(
    <div>
      <span title={value.original.device}>{checkString(value.original.device)}</span>
    </div>
  )
}

const handleOpen = (value) => {
  let parameters;
  if (value.original.parameters != undefined){
    parameters = JSON.parse(value.original.parameters);
    setJsonObject(JSON.stringify(parameters));
  }
  else{
    setJsonObject('No parameters available');
  }
    setOpen(true);
  }

const handleClose = () => {
  setOpen(false);
  setJsonObject('');
};

const updateTableData = (result) =>{
  setTableData(result.map((prop, key) => {
    return {
      timestamp: moment(prop['TimeStamp'])._d.toString(),
      event: prop["Event"],
      result: prop["Result"],
      referenceID: prop['ReferenceId'],
      parameters: prop['Parameters'],
      device: prop["Device"],
      ip: prop["IP"],
      location: prop["Location"]
    }
  }
))
}


const getPage = async (string) =>{
  if (nextKey !== undefined && keys.includes(nextKey) === false){
    keys.push(nextKey);
  }
  try{
    let key;
    if (string =="next"){
      setLeftArrow("visible");
        let newIndex = index+1;
        key = keys[newIndex];
        setIndex(newIndex);
    }
    else if (string=="previous"){
      setRightArrow("visible");
      let newIndex = index-1;
      key=keys[newIndex];
      setIndex(newIndex);
      if(keys[newIndex]===""){
        setLeftArrow("hidden");
      }
    }
    if (!startDate){
      setStartDate("");
    }
    else if (!endDate){
      setEndDate("");
    }
    let id = userID;
    let result = await adminActions.getAdminAuditTrail(id, endDate, startDate, key)
    .then(result =>{
      let data=result.data.resp.result.Data;
      if (string=="next"){
        if (result.data.resp.result.LastKey){
          if (keys.includes(result.data.resp.result.LastKey) === false){
            if (result.data.resp.result.LastKey != undefined){
              keys.push(result.data.resp.result.LastKey);
              setRightArrow("visible");
            }
          }
        }
        else{
          setRightArrow("hidden");
        }

      }
      return data;
    }).then(result =>{
      updateTableData(result);
    })
  }
  catch (e){
    console.log(e);
  }
}

  useEffect(() => {
  }, []);

  return (
    <div>
    <GridContainer>
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardBody>
          <GridItem className="topContainer">
        <GridItem xs={12} sm={4}>
          <Autocomplete
            id="combo-box-demo"
            autoSelect={true}
            onInputChange={e =>{searchUsers(e.target.value)}}
            options={searchResults}
            noOptionsText={loading}
            getOptionLabel={(results) => results.Username}
            renderInput={(params) => <TextField {...params} label="Type Username Here" variant="outlined" />}
          />
          </GridItem>
          <GridItem>
          <CalendarInput
          startValue={startValue}
          endValue={endValue}
          updateStartDate={updateStartDate} 
          updateEndDate={updateEndDate} 
          startDateError={startDateError}
          endDateError={endDateError} 
          rightInputMarginRight={'165px'} />
          </GridItem>
          <GridItem>
              <CachedIcon
              className="icon"
              id="cacheIcon"
              onClick={() => {
                        updateTable();
                    }} />
          </GridItem>
        </GridItem>
        </CardBody>
      </Card>
    </GridItem>
      <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader>
          {username ? "Username: "+username: "Please select user"}
        </CardHeader>
        <CardBody>
        <GridItem xs={12}>
            <ServerPagination
            key={uniqid()} 
            getPage={getPage}
            leftArrow={leftArrow}
            rightArrow={rightArrow} />
        </GridItem>
        <GridItem xs={12}>
            <ReactTable
            data={tableData}
            columns={columns}
            defaultPageSize={LIMIT}
            showPaginationTop = {false}
            showPaginationBottom={false}
            className="-striped -highlight"
                />
               </GridItem>
        </CardBody>
      </Card>
    </GridItem>
    </GridContainer>
              <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className="modal"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              <Fade in={open}>
                <div className="paper">
                <div className="closeIconContainer">
                  <CloseIcon className="icon" id="closeIcon" onClick={() => handleClose()}/>
                </div>
                <br />
                <div className="serverObject">
                {jsonObject}
                </div>
                </div>
              </Fade>
            </Modal>
            </div>
  );
}
