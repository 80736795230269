import React, {useEffect, useState} from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import buttonStyle from "../../../assets/jss/material-dashboard-pro-react/components/buttonStyle";

const useStyles = makeStyles(buttonStyle);
export default function AppAlert(props) {
    const classes = useStyles();
    const config = useSelector(state => state.common.alert);
    const [alert, setAlert] = useState(null);

    useEffect(() => {
        if (config) {
            switch (config.type) {
                case 'success':
                    successAlert(config)
                    break;
                case 'error':
                    errorAlert(config)
                    break;
                case "confirmation":
                    warningWithConfirmMessage(config);
                    break;
                default:
                    basic(config)
                    break;
            }
        }
    }, [config])
    const onConfirm = () => {
        if (config.hasOwnProperty("onConfirm")) {
            config.onConfirm();
        }
        setAlert(null);
    }
    const successAlert = data => {
        setAlert(<SweetAlert
            success
            style={{display: "block", marginTop: "-100px"}}
            title={data.hasOwnProperty('title') ? data.title : "Success"}
            onConfirm={() => onConfirm()}
            onCancel={() => setAlert(null)}
            confirmBtnCssClass={classes.button + " " + classes.success}
        >
            {data.message}
        </SweetAlert>);
    }
    const errorAlert = data => {
        setAlert(<SweetAlert
            danger
            style={{display: "block", marginTop: "-100px"}}
            title={data.hasOwnProperty('title') ? data.title : "Oops Something went wrong"}
            onConfirm={() => onConfirm()}
            onCancel={() => setAlert(null)}
            confirmBtnCssClass={classes.button + " " + classes.danger}
        >
            {data.message}
        </SweetAlert>);
    }
    const basic = data => {
        setAlert(
            <SweetAlert
                style={{display: "block", marginTop: "-100px"}}
                title="Notice!"
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnCssClass={classes.button + " " + classes.success}
            />
        )
    }
    const warningWithConfirmMessage = data => {
        setAlert(<SweetAlert
            warning
            style={{display: "block", marginTop: "-100px"}}
            title={config.hasOwnProperty("title") ? config.title : "Are you sure?"}
            onConfirm={() => onConfirm()}
            onCancel={() => setAlert(null)}
            confirmBtnCssClass={
                classes.button + " " + classes.success
            }
            cancelBtnCssClass={
                classes.button + " " + classes.danger
            }
            confirmBtnText={data.hasOwnProperty("confirmBtnText") ? data.confirmBtnText : "Yes"}
            cancelBtnText="Cancel"
            showCancel
        >
            {data.message}
        </SweetAlert>)
    }
    return alert;
}