import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {
  withStyles,
  Grid,
  DialogTitle
} from '@material-ui/core';

const styles = theme => ({
  container: {
    textAlign: 'center',
    overflow: 'hidden',
    backgroundColor:'#d3d3d3'
  }
});

function Timer(props) {
  const { classes, fetching, loadingText, propTimer } = props;
  const [timer, setTimer] = useState(propTimer);

  const updateTimer = () =>{ 
    setInterval(() =>{
            setTimer(timer => timer-1);
    }, 1000);
  }

  useEffect(() => {

   if (propTimer){
      updateTimer();
    }

  }, []);

  return (
    <div>
      <Dialog
        open={fetching}
        aria-labelledby="spinner-dialog-title"
        aria-describedby="spinner-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <Grid className={classes.container}>
          <DialogTitle id="spinner-dialog-title" style={{color: '#8b0000'}}>
              {loadingText? loadingText + timer: null}
            </DialogTitle>
        </Grid>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  // return {
  //   fetching: state.api.fetching
  // };
};

export default connect(mapStateToProps)(withStyles(styles)(Timer));
