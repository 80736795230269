import {API_BASE_URL} from "./settings";

export const API_URL = {
  SUBSCRIPTION_PLANE_GET: API_BASE_URL +  '/subscription-plans/get',
  SUBSCRIPTION_HISTORY: API_BASE_URL +  '/subscriptions/get',
  SUBSCRIPTION_SUBMIT: API_BASE_URL +  '/subscriptions/create',
  SUBSCRIPTION_REMOVE: API_BASE_URL +  '/subscriptions/delete',
  USER_GET: API_BASE_URL +  '/user/get',
  USER_UPDATE: API_BASE_URL +  '/user/update',
  CARD_GET: API_BASE_URL +  '/subscriptions/getCards',
  CARD_ADD: API_BASE_URL +  '/subscriptions/addCard',
  CARD_REMOVE: API_BASE_URL +  '/subscriptions/removeCard',
  ADMIN_LOGIN: API_BASE_URL + '/admin/login',
  ADMIN_REFRESH: '/admin/refreshToken',
  ADMIN_PASSWORD_RESET: API_BASE_URL + '/admin/newPassword',
  APP_INTEGRATION: "integrations-service/integrations",
  CHAT_GROUP_INTEGRATIONS: "integrations/qb/group",
  PRINTER: "printer-service/printer",
  PRODUCT_CONFIG: "design4me-service/product/config",
  PRODUCT: "design4me-service/product",
  INFO: "tutorial/info",
  INFO_IMAGE: "tutorial/image"

};
