import {GET_DEMO, GET_DEMO_OK, SAVE_DEMO_OK} from "../action-types";

export default (state = { allowNavigation: false }, action) => {
  switch (action.type) {
    case SAVE_DEMO_OK:
      return {
        ...state,
        ...action.payload
      };
    case GET_DEMO_OK:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};