import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import RegularButton from "components/CustomButtons/RegularButton.js";
import {RESET_FILE_INDEX, SHOW_APP_ALERT} from "../../action-types";
import adminActions from "../../services/admin";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown.js";
import CustomInput from "components/CustomInput/CustomInput2.js";
import PropTypes from "prop-types";
import Dropdown from "components/Dropdown/dropdown.js";

var uniqid = require('uniqid');
function TemplateComponent(props) {
  const dispatch = useDispatch();
  const classes = useState([]);
  const [templateTypes, setTemplateTypes] = useState();
  const [selectedTemplateType, setSelectedTemplateType] = useState("no template selected");
  const [templateForAPI, setTemplateForAPI] = useState(
    {
      file_name: props.file_name,
      type: props.templateType,
      template_id: props.templateId
    });
  const [imageRender, setImageRender] = useState();

  const getToken = () => {
    let token = localStorage.getItem("idToken");
    return token;
  };

  const submit = async () => {
    if (templateForAPI.type && templateForAPI.name){
      if (props.action === "edit") {
        // templateForAPI.file=props.uploadedFile;
        // templateForAPI.file_name=props.file_name;
        let result = adminActions
          .putTemplates(templateForAPI)
          .then(result => {
            let config = {
              type: 'success',
              title: "Success",
              message: "You edited a template!",
              buttons: [
                {
                  text: "Ok"
                }
              ]
            };
            dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
          });
      } else {
        // templateForAPI.file=props.uploadedFile;
        // templateForAPI.file_name=props.file_name;
        let result = adminActions
          .postTemplates(templateForAPI)
          .then(result => {
            let config = {
              type: "success",
              title: "Success",
              message: "You created a template!",
              buttons: [
                {
                  text: "Ok"
                }
              ]
            };
            dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
          });
      }
      activeRoute('/admin/list-templates');
    }
    else {
      let config = {
        type: "error",
        title: "Error",
        message: "Please provide both a valid template-type and name.",
        buttons: [
          {
            text: "Ok"
          }
        ]
      };
      dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
    }
  };

  const activeRoute = routeName => {
    // window.location.href = process.env.REACT_APP_BASE_URL + routeName;
    props.history.push({
      pathname: routeName,
      params: ""
    });
  };

const updateAge = (age) =>{
  setTemplateForAPI({...templateForAPI,  age: age
  });
};

const updateForm = (form) =>{
  setTemplateForAPI({...templateForAPI,  form: form
  });
};

const updateName = (nameValue) =>{
  setTemplateForAPI({...templateForAPI,  name: nameValue
  });
}

  const getTemplateName = templateName => {
    let templateNameValue = document.getElementById("Template-Name").value;

    //set template name for call to API.
    updateName(templateNameValue);

  };

  const selectTemplate = templateType => {
    setSelectedTemplateType(templateType);
    setTemplateForAPI({...templateForAPI,  type: templateType
    });

    if (templateType == undefined) {
      return "Select a Template";
    } 
  };

  // const getDonorImage = async (id) =>{
  //   let result = await adminActions
  //   .getImageSignedForTable(getToken(),id).then(result =>{
  //       return result;
  //   }).then(result =>{
  //       if (result.data.data.result == "no image found"){
  //       setImageRender(<ImageUpload
  //         key={uniqid()}
  //        fileIndex = "Smile-Donor"
  //        p={10}
  //      />);
  //      return;
  //       }
  //       let url = result.data.data.result;
  //       return url;
  //   }).then(result =>{
  //     setImageRender(<ImageUpload
  //       key={uniqid()}
  //        fileIndex = "Smile-Donor"
  //        p={10}
  //        url = {result}
  //      />);
  //   })
  // }

  const getTemplateTypes = async () => {
    try {
      let result = await adminActions.getTemplateTypes();
      setTemplateTypes(result.data.data.result);
      createTemplateTypesDropdown(result.data.data.result);
    } catch (err) {
      console.log(err);
    }
  };

  const createTemplateTypesDropdown = (types) =>{
    let dropdownArray = [];
    for (let i=0; i<=types.length-1;i++){
      dropdownArray.push(                  
      <li
        onClick={() => {
          selectTemplate(types[i].type);
        }}
      >
        {types[i].type}
      </li>)
    };
    setTemplateTypes(dropdownArray);
  }

  useEffect(() => {
    getTemplateTypes();

   if (props.history.location.pathname=="/admin/create-template"){
    dispatch({ type: RESET_FILE_INDEX });
   }
    //add information here to render when view loads or perform functions.
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <h4 className={classes.cardTitle}>
              {props.templateType ? "Edit Template" : "Create Template"}
            </h4>
          </CardHeader>
          <CardBody>
            <GridItem xs={12} sm={12} md={16}>
              {templateTypes ? <CustomDropdown
                id="customDropdown"
                buttonText="Select a Template"
                dropdownList={templateTypes}
              />: null}
              <p>
                You have selected template:
                {props.templateType ? (
                  <strong>{props.templateType}</strong>
                ) : (
                <strong>{" "+selectedTemplateType}</strong>
                )}
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h5>&nbsp;</h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h5>Edit Template Name</h5>
            </GridItem>
            <div>
              <GridItem xs={12} sm={12} md={12} pb={5}>
                <CustomInput
                  labelText={
                    props.templateName ? props.templateName : "Enter Name Here"
                  }
                  id="Template-Name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  onChange = {() => {getTemplateName()}}
                />
              </GridItem>
            </div>
            <GridItem xs={12} sm={12} md={12}>
              <h5>Enter Age Type</h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} pb={5}>
                <Dropdown status = {props.age} action={updateAge} values={["young","mid-age","mature"]} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
              <h5>Enter Form Type</h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} pb={5}>
                <Dropdown status={props.form} action={updateForm}  values={["round", "square", "triangle"]} />
              </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h5>&nbsp;</h5>
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={12} pb={5}>
              {imageRender}
              </GridItem> */}
            <GridItem xs={12} sm={12} md={12}>
              <h5>&nbsp;</h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <RegularButton
                color="success"
                onClick={() => {
                  submit();
                }}
              >
                {props.templateType ? "Finish Edit" : "Create"}
              </RegularButton>
              <RegularButton
                onClick={() => {
                  props.history.goBack();
                }}
              >
                Cancel
              </RegularButton>
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
const mapStateToProps = state => {
  return {
    templateId: state.store.templateId,
    templateName: state.store.templateName,
    templateStatus: state.store.templateStatus,
    templateType: state.store.templateType,
    uploadedFile: state.store.uploadedFile,
    file_name: state.store.file_name,
    smileDonator: state.store.smileDonator,
    janHaito: state.store.janHaito,
    age: state.store.age,
    form: state.store.form,
    action: state.store.action,
    priority: state.store.priority
  };
};
const wrapperFunction = connect(mapStateToProps);

const MemoizedTemplateComponent = React.memo(TemplateComponent);

TemplateComponent.propTypes = {
  history: PropTypes.array,
  push: PropTypes.func,
  templateId: PropTypes.number,
  templateName: PropTypes.string,
  templateStatus: PropTypes.string,
  templateType: PropTypes.string
};

export default wrapperFunction(MemoizedTemplateComponent);
