import dsdInstance from "./dsdInstance";

//
const put = (data) => {
    return  dsdInstance.put("ws-api-service/app_info", data);
}
const getHistory = async (channel) => {
    const res = await dsdInstance.get("ws-api-service/app_info?channel=" + channel);
    try {
        return res.data.data.result.list.Parameters;
    }
    catch (e) {
        console.error(e);
    }

};

export default {
    getHistory: getHistory,
    put: put,
};