import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import EditAppUser from "components/AppUser/EditAppUser";
import ViewAppUserEvents from "components/AppUser/ViewAppUserEvents";

export default function AppUser(props) {
    const {id} = props.match.params;
    const goBack = () => {
        props.history.goBack();
    };
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {props.history.location.pathname == "/admin/app/edit/" + id ?
                        <EditAppUser disabled={false} id={id} goBack={goBack}/> :
                        <EditAppUser disabled={true} id={id}/>}
                    {props.history.location.pathname == "/admin/app/view/" + id ?
                        <ViewAppUserEvents id={id} goBack={goBack}/> : null}
                </GridItem>
            </GridContainer>
        </div>
    );
}
