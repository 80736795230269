import React, { useEffect, useState } from "react";
import { InfoService } from "services/InfoService";
import Gallery from "views/Info/Cmp/Gallery";

export default function ImageUploder({ images: img, device, id }) {
  const [images, setImages] = useState([]);
  const [files, setUploadFiles] = useState([]);

  useEffect(() => {
    setImages(img);
  }, [img]);

  useEffect(() => {
    if (files.length > 0) {
      upload(files);
    }
  }, [files]);

  const upload = async (files) => {
    const test = [];
    await Promise.all(
      files.map(async (file) => {
        let params = {
          file_name: file.name,
          device: device,
          info_id: id,
        };
        let res = await InfoService.saveImg(params);
        await s3Upload(res.data.SignedURL, file);

        test.push({
          image: res.data.file_name,
          SignedURL: res.data.ImageUrl,
        });
      })
    );

    addImage(test);
  };

  const s3Upload = async (s3Url, file) => {
    const data = new FormData();
    data.append("file", file);
    return await fetch(s3Url, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": file.type,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: file,
    });
  };

  const deleteImage = async (obj) => {
    await InfoService.deleteImg({ data: obj });
    let newimages = images.filter((item) => item.image !== obj.file_name);
    setImages(newimages);
  };

  const addImage = (obj) => {
    setImages(images.concat(obj));
  };

  return (
    <>
      <Gallery
        id={id}
        device={device}
        images={images}
        deleteImage={deleteImage}
        setUploadFiles={setUploadFiles}
      />
    </>
  );
}
