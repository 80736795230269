import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import RegularButton from "components/CustomButtons/RegularButton.js";
import PropTypes from "prop-types";
import {
    COMMIT_ALL_TUTORIAL_MODULES, SHOW_APP_ALERT
} from "../../action-types";
import adminActions from "../../services/tutorial";
import "tui-image-editor/dist/tui-image-editor.css";
import CustomInput from "components/CustomInput/CustomInput2.js";
import {makeStyles} from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import validations from '../../utils/utils.js'
import "./tutorialComponent.css";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'auto',
        overflowX: 'hidden',
        width: "100%",
        height: 400
    },
    tutorialRow: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        width: '100%',
        '&:hover': {
            cursor: 'pointer'
        }
    }
});


function TutorialInputsComponent(props) {
    const {moduleId, moduleName, inputs} = props;
    let dispatch = useDispatch();
    let classes = useStyles();
    var uniqid = require('uniqid');
    const [inputData, setInputData] = useState("Please select your tutorial from the left.");
    const [stateModuleName, setStateModuleName] = useState(moduleName);
    const [screenInputs, setScreenInputs] = useState({});
    const [languageInputs, setLanguageInputs] = useState();

    const devices = [
        'iPhone',
        'iPad',
        'Desktop'
    ];

    const activeRoute = routeName => {
        props.history.push({
            pathname: routeName,
            params: ""
        });
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const displayTabData = (data, code, name) => {
        let newData;
        if (moduleName !== 'Create') {
            newData = JSON.parse(data);
        } else {
            newData = data;
        }

        let inputArray = [];
        if (moduleName && moduleName !== "Create") {
            setScreenInputs(state => ({...state, name: name, code: code}));
        }

        inputArray.push(<GridContainer direction="row" alignItems="center" spacing={2}>
            <GridItem xs={12} sm={12} md={12}>
                <h5>Tutorial Name</h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                    key={uniqid()}
                    className="tutorial-name-input"
                    labelText={name ? name : "Enter Tutorial Name"}
                    style={{width: "100%"}}
                    formControlProps={{
                        fullWidth: true
                    }}
                    onChange={e => {
                        let value = e.target.value;
                        setScreenInputs(state => ({...state, name: value}));
                    }}
                />
            </GridItem>
        </GridContainer>);

        inputArray.push(<GridContainer direction="row" alignItems="center" spacing={2}>
            <GridItem xs={12} sm={12} md={12}>
                <h5>Screen ID</h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                    key={uniqid()}
                    formControlProps={{
                        fullWidth: true
                    }}
                    labelText={code ? code : "Enter Tutorial Code"}
                    onChange={e => {
                        let value = e.target.value;
                        setScreenInputs(state => ({...state, code: value}));
                    }}
                />
            </GridItem>
        </GridContainer>);

        for (let i = 0; i <= inputs.length - 1; i++) {
            inputArray.push(
                <GridContainer direction="row" alignItems="center" spacing={2}>
                    <Accordion className={classes.tutorialRow}>
                        <GridItem xs={12} sm={12} md={12}>
                            <AccordionSummary aria-controls={inputs[i] + "-content"} id={inputs[i] + '-header'}>
                                <h5>{capitalizeFirstLetter(inputs[i]) + " Tutorial URLs"}</h5><h5><ArrowDropDownIcon/>
                            </h5>
                            </AccordionSummary>
                        </GridItem>
                        {mapOutAccordionDetails(inputs[i], newData, i)}
                    </Accordion>
                </GridContainer>
            )
        }
        setInputData(inputArray);
        newData = null;
    }

    const mapOutAccordionDetails = (language, newData) => {
        let finalDetailsArray = [];
        let tempArray = [];
        for (let k = 0; k <= devices.length - 1; k++) {
            tempArray.push(
                <CustomInput
                    key={uniqid()}
                    labelText={newData ? matchInputData(language, devices[k], newData) : "Enter " + capitalizeFirstLetter(language) + " " + devices[k] + " URL"}
                    className={language + "-input"}
                    formControlProps={{
                        fullWidth: true
                    }}
                    onChange={e => {
                        updateStateObject(e.target.value, devices[k], language, newData);
                    }}
                />
            )
        }
        finalDetailsArray.push(<AccordionDetails><GridItem xs={12} sm={12}
                                                           md={12}>{tempArray}</GridItem></AccordionDetails>);
        return finalDetailsArray;
    }

    const matchInputData = (input, device, newData, error) => {
        if (moduleName === "Create" && !error) {
            return ("Enter " + capitalizeFirstLetter(input) + " " + device + " Tutorial URL");
        }
        let result = newData[input].filter(obj => {
            return obj.device === device;
        });
        if (result[0].URL !== undefined && result[0].URL !== '') {
            updateStateObject(result[0].URL, device, input, newData);
            return result[0].URL;
        } else {
            return ("Enter " + capitalizeFirstLetter(input) + ' ' + device + " Tutorial URL");
        }
    }

    const updateStateObject = (value, device, language, data) => {
        let newState;
        if (data) {
            newState = data;
            let result = newState[language].filter(obj => {
                return obj.device === device;
            });
            result[0].URL = value;
            setLanguageInputs(newState);
        }
    }

    const updateScreens = async (edit) => {
        try {
            let result = await adminActions.getAllTutorials()
            let newResult = result.data.data.result.data;
            let newScreen;
            for (let i = 0; i <= newResult.length - 1; i++) {
                if (newResult[i].Name === screenInputs.name) {
                    newScreen = newResult[i];
                }
            }
            if (!edit) {
                props.formatScreensData(props.tutorialModules, props.tutorialInputs, newScreen);
            } else {
                getTutorial();
            }
            dispatch({type: COMMIT_ALL_TUTORIAL_MODULES, payload: result.data.data.result.data});
        } catch (err) {
            console.error(err);
        }
    }

    const uploadTutorial = async () => {
        let objectForAPI = screenInputs;
        if (languageInputs) {
            for (const key in languageInputs) {
                for (let i = 0; i <= languageInputs[key].length - 1; i++) {
                    if (languageInputs[key][i].URL !== '') {
                        if (!validations.isURL(languageInputs[key][i].URL)) {
                            let config = {
                                type: 'error',
                                title: "Error",
                                message: capitalizeFirstLetter(key) + " " + languageInputs[key][i].device + " URL: " + languageInputs[key][i].URL + " is not valid. Please revise to include entire URL."
                            };
                            dispatch({type: SHOW_APP_ALERT, alertConfig: config});
                            return;
                        }
                    }
                }
            }
            objectForAPI.data = JSON.stringify(languageInputs);
        }
        if (moduleName === "Create") {
            if (objectForAPI.name && objectForAPI.code) {
                try {
                    await adminActions.postTutorial(objectForAPI)
                    await updateScreens();
                    let config = {
                        type: 'success',
                        title: "Success",
                        message: "You created a module!"
                    };
                    dispatch({type: SHOW_APP_ALERT, alertConfig: config});
                } catch (err) {
                    console.error(err);
                }
            } else {
                let config = {
                    type: 'error',
                    title: "Error",
                    message: 'Please submit both a name and screen ID when creating tutorial.'
                };
                dispatch({type: SHOW_APP_ALERT, alertConfig: config});
            }
        } else {
            objectForAPI.id = moduleId;
            objectForAPI.status = 1;
            try {
                let result = await adminActions.editTutorial(
                    objectForAPI
                ).then(result => {
                    updateScreens('edit');
                });
                let config = {
                    type: 'success',
                    title: "Success",
                    message: "You just edited a module!"
                };
                dispatch({type: SHOW_APP_ALERT, alertConfig: config});
            } catch (err) {
                console.error(err);
            }
        }
    }

    const getTutorial = async () => {
        try {
            let result = await adminActions.getTutorial(moduleId);
            setStateModuleName(result.data.data.result[0].Name);

            if (result.data.data.result[0].Data == undefined) {
                let data = constructInitialState();
                displayTabData(
                    data,
                    result.data.data.result[0].Code,
                    result.data.data.result[0].Name);
            } else {
                displayTabData(
                    result.data.data.result[0].Data,
                    result.data.data.result[0].Code,
                    result.data.data.result[0].Name);
            }
        } catch (err) {
            console.error(err);
        }
    }

    const constructInitialState = () => {
        let newState = {};
        for (let i = 0; i <= inputs.length - 1; i++) {
            newState[inputs[i]] = [];
            for (let k = 0; k <= devices.length - 1; k++) {
                newState[inputs[i]].push({
                    URL: '',
                    device: devices[k]
                });
            }
        }
        if (moduleName === 'Create') {
            return newState
        } else {
            return JSON.stringify(newState);
        }
    }

    const getData = async () => {
        if (moduleId && moduleName !== "Select Section") {
            getTutorial();
        } else if (moduleName === "Create") {
            let newState = constructInitialState()
            displayTabData(newState);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <Card>
            <CardHeader>
                <h4 className={classes.cardTitle}>
                    {stateModuleName}
                </h4>
            </CardHeader>
            <CardBody>
                <div className={classes.root}>
                    {inputData}
                </div>
                <GridContainer direction="row" alignItems="center" spacing={2}>
                    <GridItem xs={12} sm={12} md={12}>
                        <RegularButton
                            color="success"
                            onClick={e => {
                                uploadTutorial()
                            }}
                        >{moduleName == "Create" ? "Create Module" : "Save to Server"}
                        </RegularButton>
                    </GridItem>
                </GridContainer>
            </CardBody>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        tutorialModules: state.store.tutorialModules,
        tutorialInputs: state.store.tutorialInputs
    };
};

const wrapperFunction = connect(mapStateToProps);
const finalTutorialInputsComponent = wrapperFunction(TutorialInputsComponent);

TutorialInputsComponent.propTypes = {
    history: PropTypes.array,
    push: PropTypes.func,
    formatScreensData: PropTypes.func
};

export default finalTutorialInputsComponent;
