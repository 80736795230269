import { takeEvery, put, call, take, cancelled } from 'redux-saga/effects';
import {
  LOGIN,
  LOGIN_NOT_OK,
  LOGIN_OK,
  PASSWORD_RESET,
  PASSWORD_RESET_NOT_OK,
  PASSWORD_RESET_OK, SHOW_ALERT
} from "../common/action-types";
import {API_URL} from "../config/api";
import Http from "../utils/Http";

let passwordReset = function* ({payload}) {

  const url = API_URL.ADMIN_PASSWORD_RESET;
  const response = yield Http.postData(url, payload);
  console.log(response);
  if (response.status == 200) {
    yield put({type: PASSWORD_RESET_OK, payload:response});
  } else {
    console.log(response);
    console.log(response.err);
    yield put({type: PASSWORD_RESET_NOT_OK, payload:response.err});
  }
}
let passwordReset_ = function* ({payload}) {
  const url = API_URL.ADMIN_PASSWORD_RESET;
  const response = yield Http.post(url, payload);
  console.log(response);
  if (response.status === 200) {
    yield put({type: PASSWORD_RESET_OK, payload: response});
  } else {
    const config = {
      open: true,
      title: "Error",
      message: response.info
    };
    yield put({type: PASSWORD_RESET_NOT_OK, alertConfig: config});
  }
};
let login = function* ({payload}) {

  const url = API_URL.ADMIN_LOGIN;
  const response = yield Http.postData(url, payload);
  if (response.status == 200) {
    yield put({type: LOGIN_OK, payload:response});
  } else {
    yield put({type: LOGIN_NOT_OK, payload:response.err});
  }
};

export function* passwordResetSaga() {
  yield takeEvery(PASSWORD_RESET, passwordReset);
}

export function* loginSaga() {
  yield takeEvery(LOGIN, login);
}
