export default class AppUserModel {
    constructor(init) {
        const {Name, FamilyName, Email, PhoneNumber, Country, Language, UserId, KeepId, Devices} = init ? init : {};
        this.Name = Name || '';
        this.FamilyName = FamilyName || '';
        this.Email = Email || '';
        this.PhoneNumber = PhoneNumber || '';
        this.Country = Country || '';
        this.Language = Language || '';
        this.UserId = UserId || '';
        this.KeepId = KeepId || '';
        this.devices = Devices || '';
    }
}