import React from "react";
import { useState } from "react";
import { MenuItem } from "@material-ui/core";
import { validate } from "./validations";

export class IProps {
  constructor(params) {
    this.value = params.hasOwnProperty("value") ? params.value : "";
    this.rules = params.hasOwnProperty("rules") ? params.rules : [];
    this.icon = params.hasOwnProperty("icon") ? params.icon : "";
    this.type = params.hasOwnProperty("type") ? params.type : "text";
    this.name = params.hasOwnProperty("name") ? params.name : "";
  }
}

/**
 *
 * @param p
 * @returns {{validity: {isValid: boolean, value: any, errors: Array}, doValidate: (function(*=): boolean), props: {onChange: handleChange, endAdornment: (*|string), type: (*|string), value: any}}}
 */
export function useInputProps(p = {}) {
  let handleChange;
  let dateChange;
  let doValidate;
  let params = new IProps(p);
  let output;

  const [value, setValue] = useState(params.value);
  const [isValid, setValidity] = useState(true);
  const [errors, setErrors] = useState([]);

  handleChange = e => {
    setValue(e.target.value);
    let flags = validate(e.target.value, params.rules);
    setValidity(flags.length <= 0);
    setErrors(flags);
  };
  dateChange = moment => {
    setValue(moment.format("YYYY-MM-DD"));
    let flags = validate(moment.format("YYYY-MM-DD"), params.rules);
    setValidity(flags.length <= 0);
    setErrors(flags);
  };
  doValidate = (bErrors = []) => {
    let flags = bErrors.length === 0 ? validate(value, params.rules) : bErrors;
    console.log(flags, value);
    const validity = flags.length <= 0;
    setValidity(validity);
    setErrors(flags);
    return validity;
  };
  output = {
    setValue,
    doValidate: doValidate,
    props: {
      value,
      onChange: handleChange
    },
    validity: {
      value,
      isValid,
      errors
    }
  };
  switch (params.type) {
    case "select":
      output = {
        setValue,
        doValidate: doValidate,
        props: {
          value,
          onChange: handleChange
        },
        validity: {
          value,
          isValid,
          errors
        }
      };
      break;
    case "date":
      output.props.onChange = dateChange;
      break;
    case "hidden":
      output = {
        setValue,
        props: {
          value
        },
        doValidate: () => true
      };
      break;
    case "radio":
      output.doValidate = () => true;
      output.props.checked = value;
        output.props.onChange = event => {
        setValue(event.target.checked);
      };
      break;
    case "multiline":
      output.props.multiline = true;
      output.props.multiline = true;
      break;
    default:
      output = {
        setValue,
        doValidate: doValidate,
        setErrors: setErrors,
        props: {
          value,
          onChange: handleChange,
          endAdornment: params.icon,
          type: params.type
        },
        validity: {
          value,
          isValid,
          errors
        }
      };
      break;
  }

  return output;
}

export function useMenuItems(options, rtnObject = false) {
  return options.map((item, i) => {
    const value = rtnObject ? item : item.id;
    return (
      <MenuItem value={value} key={i}>
        {item.name}
      </MenuItem>
    );
  });
}
