import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";

export const styles = (theme) => ({
    root: {
        // width: "100%"
        borderRadius: "10px",
        padding: "8px",
        background: "#eeeeee",
        marginBottom: "5px"
    },
    title: {
        fontWeight: "bold"
    }
});
const useStyles = makeStyles(styles);
export default function UserDevices({IPAD, MAC, IPHONE}) {
    const classes = useStyles();

    return <GridContainer className={classes.root}>
        <GridItem md={4}><p className={classes.title}>iPhone</p><p>{IPHONE}</p></GridItem>
        <GridItem md={4}><p className={classes.title}>iPad</p><p>{IPAD}</p></GridItem>
        <GridItem md={4}><p className={classes.title}>MAC</p><p>{MAC}</p></GridItem>
    </GridContainer>
}
