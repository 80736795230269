import * as React from "react";
import { useEffect } from "react";
import { ProductService } from "../../../services/ProductService";
import AppListTabFilter from "../../../components/AppListTabFilter";
import { TAB_DATA } from "../productConfig";
import useProductStore from "../useProductStore";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { ProductTableHeader } from "./ProductTableHeader";
import ProductTableBody from "./ProductTableBody";
import { AppFloatActionByn } from "../../../components/AppFloatActionBtn";
import CardHeader from "../../../components/Card/CardHeader";
import { Card } from "@material-ui/core";
import CardBody from "../../../components/Card/CardBody";

export function ProductList() {
  const { tab, changeTab, loadProducts, changeFormOpen } = useProductStore();

  useEffect(() => {
    // getProductConf();
  }, []);

  useEffect(() => {
    console.log("tab effect", tab);
    loadProducts();
  }, [tab]);

  return (
    <Card>
      <CardHeader>
        <h4>Products List</h4>
      </CardHeader>
      <CardBody>
        <div
          style={{ float: "right", position: "relative", marginTop: "-38px" }}
        >
          <AppFloatActionByn
            color={"cyan"}
            onClick={() => changeFormOpen(true)}
          />
        </div>
        <AppListTabFilter
          tabData={TAB_DATA}
          changeCurrentTab={changeTab}
          currentTab={tab}
        />
        <TableContainer>
          <Table>
            <ProductTableHeader />
            <ProductTableBody />
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
}
