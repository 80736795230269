import * as React from "react";
import { useEffect } from "react";
// import {ProductService} from "../../../services/ProductService";
// import AppListTabFilter from "../../../components/AppListTabFilter";
import useInfoStore from "../useInfoStore";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableHeader } from "./TableHeader";
import TableBody from "./TableBody";
import { AppFloatActionByn } from "../../../components/AppFloatActionBtn";
import CardHeader from "../../../components/Card/CardHeader";
import { Card } from "@material-ui/core";
import CardBody from "../../../components/Card/CardBody";

export function List() {
  
 const { loadInfo, changeFormOpen } = useInfoStore();

  useEffect(() => {
    loadInfo();
  }, []);

  return (
    <Card>
      <CardHeader>
        <h4>Info List</h4>
      </CardHeader>
      <CardBody>
        <div
          style={{ float: "right", position: "relative", marginTop: "-38px" }}
        >
          <AppFloatActionByn
            color={"cyan"}
            onClick={() => changeFormOpen(true)}
          />
        </div>
        {/*<AppListTabFilter tabData={TAB_DATA} changeCurrentTab={changeTab} currentTab={tab}/>*/}
        <TableContainer>
          <Table>
            <TableHeader />
            <TableBody />
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
}
