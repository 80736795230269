import React, { createContext, useCallback, useState } from "react";
import { TAB_DATA } from "./productConfig";
import { ProductService } from "../../services/ProductService";

export const ProductContext = createContext({
  editable: null,
  changeEditable: () => {},
  formOpen: false,
  changeFormOpen: () => {},
  tab: TAB_DATA[0],
  changeTab: () => {},
  products: null,
  loadProducts: () => {},
  config: null,
  loadConfig: () => {},
});

export default function ProductProvider({ children }) {
  const [editable, setEditable] = useState(null);
  const [formOpen, setFormOpen] = useState(false);
  const [tab, setTab] = useState(TAB_DATA[0]);
  const [products, setProducts] = useState(null);
  const [config, setConfig] = useState(null);

  const changeEditable = (row) => {
    setEditable(row);
  };
  const changeFormOpen = (isOpenForm) => {
    console.log(isOpenForm);
    setFormOpen(isOpenForm);
  };
  const changeTab = (selected) => {
    setTab(selected);
  };
  const loadProducts = () => {
    let cp = { ...products };
    ProductService.list(tab.key).then((data) => {
      if (data.length > 0) {
        cp[tab.key] = data;
      }
      setProducts(cp);
    });
  };
  const loadConfig = () => {
    ProductService.getConfig().then((data) => {
      setConfig(data);
    });
  };
  const contextValue = {
    editable: editable,
    changeEditable: useCallback((row) => {
      changeEditable(row);
    }, []),
    formOpen: formOpen,
    changeFormOpen: useCallback((isOpenForm) => {
      console.log(isOpenForm);
      changeFormOpen(isOpenForm);
    }, []),
    tab: tab,
    changeTab: useCallback((selected) => {
      console.log(selected);
      changeTab(selected);
    }, []),
    products: products,
    loadProducts: useCallback(() => {
      loadProducts();
    }, [tab]),
    config: config,
    loadConfig: useCallback(() => {
      loadConfig();
    }, []),
  };

  return (
    <ProductContext.Provider value={contextValue}>
      {children}
    </ProductContext.Provider>
  );
}
