import React, { useEffect} from "react";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import './serverPagination.css';

export default function ServerPagination(props) {
  const{getPage, leftArrow, rightArrow} = props;
  
  useEffect(() => {
  }, []);

  return (
    <GridContainer>
        <GridItem xs={12} style={{display: "flex", flexWrap: 'wrap', justifyContent: "space-between"}}>
            <ChevronLeftIcon onClick={e=> getPage("previous")} className="icon" id="leftArrow" style={{fontSize: "30px", visibility: leftArrow}} />
            <ChevronRightIcon onClick={e=> getPage("next")} className="icon" id="rightArrow" style={{fontSize: "30px", visibility: rightArrow }} />
        </GridItem>
    </GridContainer>
  );
}
