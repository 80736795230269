import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { connect } from "react-redux";
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js"; 
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import RegularButton from "../../components/CustomButtons/RegularButton.js";
import {useInputProps} from "../../components/CustomInput/InputPropsHook";
import {LOGIN} from "../../common/action-types";
import Timer from "../../components/Timer/timer.jsx";
import InputErrors from "../../components/CustomInput/InputErrors";
import {RESET_APP, SHOW_APP_ALERT} from "../../action-types"


const useStyles = makeStyles(styles);

function LoginPage(props) {
  const [lockout, setLockout] = useState();
  const [invalidLogins, setInvalidLogins] = useState(1);

  localStorage.removeItem('idToken');
  localStorage.removeItem('session');
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.auth);

  const formInputs = {
    userName: useInputProps({type: "text", rules: ["required"]}),
    password: useInputProps({type: "password", rules: ["required"]})
  };


  useEffect(() => {
    localStorage.removeItem('idToken');
    localStorage.removeItem('state');
    localStorage.setItem('currentTime', new Date().getTime());

    if (localStorage.getItem("invalidLogins") > invalidLogins){
      setInvalidLogins(localStorage.getItem("invalidLogins"));
    }


    if (localStorage.getItem('currentTime')-localStorage.getItem('lastErrorTime') < localStorage.getItem("progressiveDelayTime"))
    {
      let remainingTime = localStorage.getItem("progressiveDelayTime") - (localStorage.getItem('currentTime')-localStorage.getItem('lastErrorTime'));
      setLockout(
        <Timer 
        fetching={true} 
        loadingText={"You are locked out for "}
        propTimer = {Math.floor(remainingTime/1000)}
        />);
      setTimeout(updateLockout,remainingTime);
    }


    if (authReducer.data) {
      if (authReducer.data.ChallengeName == 'NEW_PASSWORD_REQUIRED') {
        const session = authReducer.data.session;
        localStorage.setItem('auth-session', session);
        localStorage.setItem('username', formInputs.userName.props.value);
        localStorage.removeItem('invalidLogins');
        localStorage.removeItem('currentTime');
        localStorage.removeItem('progressiveDelayTime');
        localStorage.removeItem('lastErrorTime');
        props.history.push({
          pathname: '/auth/passwordReset',
          params: ''
        });

      } 
      
      else if (authReducer.data.ChallengeName == "OTP_REQUIRED" ) {
        const session = authReducer.data.session;
        localStorage.setItem('auth-session', session);
        localStorage.setItem('username',formInputs.userName.props.value );
        localStorage.setItem('idToken', authReducer.data.token.idToken.jwtToken);
        localStorage.setItem('refreshToken', authReducer.data.token.refreshToken.token);
        localStorage.removeItem('invalidLogins');
        props.history.push({
          pathname: '/admin/dashboard',
          params: ''
        });

        dispatch({type: RESET_APP});
      }
    }

    if (authReducer.failed && !authReducer.signedUp) {
      dispatch({
        type: SHOW_APP_ALERT, alertConfig: {
          type: "error",
          title: "Error",
          message: "Invalid credentials!",
          onConfirm: () => { errorHandling([authReducer.loginError]);}
        }
      })
    }

  }, [authReducer]);

  const updateLockout = () =>{
    setLockout('');
  }

  const errorHandling = (errorArr) => {
    formInputs.password.doValidate(errorArr);
    formInputs.userName.doValidate([" "]);
  }

  const classes = useStyles();

  const login = async () => {
    formInputs.password.doValidate([" "]);
    const username = formInputs.userName.props.value;
    const password = formInputs.password.props.value;
    const user = {username, password};
    dispatch({type: LOGIN, payload: user});
  };

  return (
    <div className={classes.container}>
      {lockout}
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <Card className={classes.cardSignup}>
            <div className={classes.center}>
              <img src="./assets/img/logo2.png" width={"200px"} style={{textAlign: "center"}}/>
            </div>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                </GridItem>
                <GridItem className={classes.center} xs={12} sm={8} md={8}>
                  <CustomInput
                    labelText="Email *"
                    id="username"
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={!formInputs.userName.validity.isValid}
                    inputProps={{...formInputs.userName.props}}
                  />
                  <InputErrors errors={formInputs.userName.validity.errors}/>

                </GridItem>
                <GridItem className={classes.center} xs={12} sm={8} md={8}>
                  <CustomInput
                    labelText="Password *"
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={!formInputs.password.validity.isValid}
                    inputProps={{...formInputs.password.props}}
                  />
                  <InputErrors errors={formInputs.password.validity.errors}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div className={classes.center} style={{paddingTop: "30px"}}>
                    <RegularButton color="rose" onClick={login}>
                      Login
                    </RegularButton>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    signedUp: state.store.signedUp
  };
};

const wrapperFunction = connect(mapStateToProps);
export default wrapperFunction(LoginPage);
