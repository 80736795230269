import React, {useState} from 'react';
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import stylesSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import RegularButton from "../../../components/CustomButtons/RegularButton";
import SweetAlert from "react-bootstrap-sweetalert";
import subscriptionService from "../../../services/subscriptionService";
const useStyles = makeStyles({
    ...styles,
    ...stylesSweet,
    PlanForm: {display: "block"},
    PlanSelect: {width: "200px", float:"left"},
    PlanAdd: {width: "200px", float:"left", margin: "13px 2px 2px 10px"}
});

export default function AssignNewPlan(props) {
    const classes = useStyles();
    const {PlanList, user, onetime} = props;
    const [selectedPlan, setSelectedPlan] = useState('');
    const [alert, setAlert] = useState(null);
    const PlanSelect = (e) => {
       setSelectedPlan(e.target.value);
    }

    const doAssign = async () => {
        const r = await subscriptionService.assign(user.Username, selectedPlan.Id);
        if(r) {
            props.loadHistory();
            successAlert();
        }
    }
    const AssignConfirmation = () => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => doAssign()}
                onCancel={() => setAlert(null)}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText="Yes, Assign it!"
                cancelBtnText="Cancel"
                showCancel
            >
                Do you want to assign <b>{selectedPlan.Title}</b> for this user?
            </SweetAlert>
        );
    };

    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success"
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Plan assigned successfully!
            </SweetAlert>
        );
    };
    return (
        <div className={classes.PlanForm}>
            {alert}

            <div className={classes.PlanSelect}>
                <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                >
                    <InputLabel
                        htmlFor="formTypeSelect"
                        className={classes.selectLabel}
                    >
                        Select a { onetime? "One time " : ""}plan
                    </InputLabel>
                    <Select
                        value={selectedPlan}
                        onChange={PlanSelect}
                        MenuProps={{className: classes.selectMenu}}
                        classes={{select: classes.select}}
                        inputProps={{
                            name: "formTypeSelect",
                            id: "formTypeSelect"
                        }}
                    >
                        {
                            (PlanList) ?
                                PlanList.map((pl, i) => {
                                    return <MenuItem
                                        key = {i}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelectedMultiple
                                        }}
                                        value={pl}
                                    >
                                        {pl.Title}
                                    </MenuItem>
                                })
                                :
                                ''
                        }
                    </Select>
                </FormControl>
            </div>
            <div className={classes.PlanAdd}>
                <RegularButton
                    disabled={!selectedPlan}
                    size="sm"
                    color="success"
                    onClick={() => {
                        AssignConfirmation();
                    }}
                >
                    Assign
                </RegularButton>
            </div>
        </div>
    )
}