export const API_CALL = "API_CALL";
export const FETCH_START = "FETCH_START";
export const FETCH_END = "FETCH_END";
export const FETCHING = "FETCHING";
export const FETCH_START_OK = "FETCH_START_OK";
export const FETCH_END_OK = "FETCH_END_OK";

export const ALLOW_NAVIGATION = "ALLOW_NAVIGATION";
export const ALLOW_NAVIGATION_OK = "ALLOW_NAVIGATION_OK";
export const ALLOW_NAVIGATION_NOT_OK = "ALLOW_NAVIGATION_NOT_OK";

export const LOGIN = "LOGIN";
export const LOGIN_OK = "LOGIN_OK";
export const LOGIN_NOT_OK = "LOGIN_NOT_OK";

export const PASSWORD_RESET = "PASSWORD_RESET";
export const PASSWORD_RESET_OK = "PASSWORD_RESET_OK";
export const PASSWORD_RESET_NOT_OK = "PASSWORD_RESET_NOT_OK";
